<template>
  <shipment-dialog
    v-model="visible"
    modal-class="shipment-rate-dialog"
  >
    <div
      :class="{
        'has-illustration': data.rate >= 4
      }"
      class="shipment-rate-dialog__content p-5"
    >
      <h1 class="tw-flex shipment-rate-dialog__content__title mb-3 tw-font-normal">
        {{ $t('shipment.titles.rate_carrier') }}
      </h1>

      <ValidationObserver
        ref="observer"
        slim
      >
        <form
          class="shipment-rate-dialog__content__form tw-flex tw-flex-col"
          @submit.prevent="submitted"
        >
          <div
            class="tw-flex shipment-rate-dialog__content__form__header mb-4 tw-items-center"
          >
            <ctk-input-rate
              id="rate"
              v-model="data.rate"
              description
            />

            <!-- Warning paragraph -->
            <i18n
              v-if="data.rate === 1"
              :path="'shipment.paragraphs.bad_rate_warning'"
              tag="p"
              class="shipment-rate-dialog__content__form__warning tw-text-red-500 tw-text-center tw-rounded"
            >
              <template #important>
                <span
                  v-text="$t('shipment.paragraphs.bad_rate_warning_important')"
                  class="tw-font-medium"
                  data-test="warning-label"
                />
              </template>
            </i18n>
          </div>

          <!-- Reasons -->
          <fieldset
            v-if="data.rate !== null && data.rate <= 3"
            class="shipment-rate-dialog__content__form__reasons mb-3"
          >
            <legend class="fs-14 tw-font-medium">
              {{ $t('shipment.titles.rate_reasons') }} *
            </legend>

            <ValidationObserver>
              <template
                slot-scope="{ invalid: reasonsInvalid, validated }"
              >
                <div
                  v-for="(reason, k) in reasons"
                  :key="k"
                  :data-test="`reason-${reason}`"
                  class="tw-flex"
                >
                  <ValidationProvider
                    vid="reason"
                    :name="$t('shipment.fields.reason')"
                    :rules="'required:true'"
                    slim
                  >
                    <b-form-checkbox
                      :id="reason"
                      :value="reason"
                      v-model="data.reasons"
                      name="reason[]"
                      button-variant="info"
                      class="mr-2 flex-fixed mt-1"
                      data-test="checkbox"
                    >
                      {{ $t('shipment.labels.rate_reasons.' + reason) }}
                    </b-form-checkbox>
                  </ValidationProvider>
                </div>

                <div
                  v-if="reasonsInvalid && validated"
                  class="tw-text-red-500"
                >
                  {{ $t('shipment.paragraphs.reason_required') }}
                </div>
              </template>
            </ValidationObserver>
          </fieldset>

          <!-- Reason detail -->
          <ValidationProvider
            :name="$t('shipment.fields.detail')"
            :rules="isReasonDetailRequired ? 'required' : ''"
            slim
          >
            <template
              slot-scope="{ errors: fieldErrors, invalid: fieldInvalid, validated }"
            >
              <ctk-input-textarea
                v-if="data.rate !== null && data.rate <= 3"
                v-model="data.details"
                :label="$t('shipment.labels.rate_details')"
                :error="fieldInvalid && validated"
                :hint="fieldErrors[0]"
                :required="isReasonDetailRequired"
                id="rate-detail"
                name="rate-detail"
              />
            </template>
          </ValidationProvider>
        </form>
      </ValidationObserver>
    </div>

    <template #footer>
      <div
        class="px-3 tw-flex tw-flex-1 tw-justify-between shipment-rate-dialog__footer"
      >
        <ui-button
          type="button"
          variant="link"
          class="shipment-rate-dialog__footer__cancel"
          @click.prevent="visible = false"
        >
          {{ $t('close') | capitalize }}
        </ui-button>

        <ui-button
          :disabled="$wait.is('rating shipment')"
          :loading="$wait.is('rating shipment')"
          variant="primary"
          class="shipment-rate-dialog__footer__confirm"
          data-test="rate-button"
          @click="submitted"
        >
          {{ $t('validate') | capitalize }}
        </ui-button>
      </div>
    </template>
  </shipment-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapActions } from 'vuex'

  import { Shipment } from '@/resources'
  import { showToaster } from '@/services/Toaster'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  import CtkInputTextarea from '@/components/CtkInputs/CtkInputTextarea/index.vue'
  import CtkInputRate from '@/components/CtkInputs/CtkInputRate/index.vue'
  import ShipmentDialog from '@/views/Shippers/components/ShipmentDialog/index.vue'

  /**
   * @module component - ShipmentRateDialog
   * @param {any} rate
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentRateDialog',
    components: {
      ShipmentDialog,
      CtkInputTextarea,
      CtkInputRate
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      rate: {
        type: null,
        required: true
      },
      shipment: {
        type: null,
        required: true
      }
    },
    watch: {
      value: function (v) {
        if (!v) return
        this.$nextTick(() => {
          this.data = {
            rate: this.rate,
            reasons: [],
            details: null
          }
        })
      }
    },
    data () {
      return {
        reasons: ['driver_behavior', 'price', 'step_tracking', 'article_handling', 'punctuality', 'other'],
        data: {
          rate: null,
          reasons: [],
          details: null
        }
      }
    },
    setup (props) {
      const { state: visible } = useModelGetterSetter(props, 'value')

      return {
        visible
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      /**
       * Returns true if the reason detail textarea should be required,
       * once the "other" reason option is ticked.
       * @function isReasonDetailRequired
       * @returns {boolean}
       */
      isReasonDetailRequired () {
        return this.data.reasons.includes('other')
      }
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentData'
      ]),
      submitted () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Confirmed Rating', this.shipment.uuid)
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid || this.$wait.is('rating shipment')) return false

            this.$wait.start('rating shipment')

            const { uuid, mission } = this.shipment
            const { rate, reasons, details } = this.data

            Shipment.rate({
              cid: this.getCid,
              sid: uuid,
              bid: mission.uuid
            }, {
              score: rate,
              bad_score_reasons: reasons,
              comment: details
            })
              .then(() => {
                this.setShipmentData({
                  uuid: this.shipment.uuid,
                  data: {
                    mission: {
                      ...this.shipment.mission,
                      rating: {
                        score: rate
                      },
                      actions: {
                        can_be_rated: false
                      }
                    }
                  }
                })

                showToaster(this, this.$t('shipment.paragraphs.rate_success'), { type: 'success' })
                this.$emit('input', false)
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  const errorMessage = data.error.detail || data.error.title
                  showToaster(this, errorMessage, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => this.$wait.end('rating shipment'))
          })
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-rate-dialog {
    &__content {
      position: relative;

      &__title {
        font-size: 22px;
      }

      &__form {
        &__header {
          width: 100%;
        }

        &__header .ctk-input-rate,
        &__warning {
          width: 50%;
        }

        &__header .ctk-input-rate {
          padding-right: 120px;
        }

        &__warning {
          padding: 16px 32px;
          background-color: #F9F9F9;
        }

        &__rate {
          &-container {
            flex: 0.5;
          }

          .ctk-input-rate {
            margin: auto;
          }
        }

        @media only screen and (max-width: $breakpoint-laptop-s) {
          &__rate-container,
          &__header > div:nth-child(2) {
            flex: 1;
          }
        }

        @media only screen and (max-width: $breakpoint-tablet) {
          &__header,
          &__header > div:nth-child(2) {
            flex-direction: column;
          }

          &__header .ctk-input-rate,
          &__warning {
            width: 100%;
          }
        }
      }

      &.has-illustration {
        &::after {
          content: '';
          position: absolute;
          background-image: url('~@/assets/img/illustration_reference_dialog.svg');
          background-size: contain;
          opacity: 0.8;
          width: 300px;
          height: 200px;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;

          @media only screen and (max-width: $breakpoint-tablet) {
            display: none;
          }
        }
      }
    }

    &__footer {
      position: relative;
    }
  }

</style>
