<template>
  <div class="shipment-item-footer tw-flex tw-justify-between tw-items-center">
    <shipment-item-rate
      v-if="hasRating"
      :shipment="shipment"
      class="tw-flex-1"
    />
    <shipment-item-carrier
      v-else-if="hasCarrier"
      :shipment="shipment"
      class="tw-flex-1"
    />
    <shipment-item-tracking
      v-if="hasTracking"
      :shipment="shipment"
      class="tw-flex-1"
    />
    <shipment-item-cancellation-dates
      v-if="hasCancellationDates"
      :shipment="shipment"
    />
  </div>
</template>

<script>
  import ShipmentItemCarrier from './../components/ShipmentItemCarrier'
  import ShipmentItemTracking from './../components/ShipmentItemTracking'
  import ShipmentItemRate from './../components/ShipmentItemRate'
  import ShipmentItemCancellationDates from '@/views/Shippers/Shipments/components/ShipmentsList/_subs/ShipmentItem/_subs/ShipmentItemContent/_subs/ShipmentItemCancellationDates'

  /**
   * @module component - shipmentItemFooter
   * @param {boolean} [hasCarrier=false]
   * @param {boolean} [hasCancellationDates=false]
   */
  export default {
    name: 'ShipmentItemFooter',
    components: {
      ShipmentItemCarrier,
      ShipmentItemTracking,
      ShipmentItemCancellationDates,
      ShipmentItemRate
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      hasCarrier: {
        type: Boolean,
        default: false
      },
      hasTracking: {
        type: Boolean,
        default: false
      },
      hasCancellationDates: {
        type: Boolean,
        default: false
      },
      hasRating: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<style lang="scss">

  .shipment-item-footer {
    background-color: $light-gray;
    padding: 6px 16px;

    .shipment-item-rate,
    .shipment-item-carrier,
    .shipment-item-tracking {
      flex: 0.5;
    }

    .shipment-item-tracking {
      margin-left: auto;

      @media only screen and (max-width: $breakpoint-tablet) {
        .ctk-item-tracking__label,
        .ctk-item-tracking-divider:not(.active),
        .ctk-item-tracking-dot:not(.active):not(.completed) {
          background-color: white;
        }

        .ctk-item-tracking__arrow {
          border-color: white transparent transparent transparent;
        }
      }
    }

    .shipment-item-cancellation-dates {
      margin-left: auto;
    }
  }

</style>
