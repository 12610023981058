<template>
  <div class="shipment-detail-reference tw-flex tw-justify-end">
    <div
      data-test="phone"
      class="shipment-detail-reference__phone tw-flex tw-flex-col"
    >
      <div class="tw-text-right">
        <span
          v-text="$t('shipment.labels.contact')"
          data-test="label"
        />
        <span
          v-text="$options.filters.telephone(getCurrentShipment.shipper_phone_number)"
          class="tw-font-medium"
          data-test="value"
        />
      </div>
      <shipment-update-phone-link
        v-if="getCurrentShipment.state === 'available' && !$wait.is('fetching shipment')"
        :shipment="getCurrentShipment"
        class="tw-text-right"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShipmentUpdatePhoneLink from '@/views/Shippers/Shipment/components/ShipmentUpdatePhoneLink'

  /**
   * @module component - ShipmentDetailReference
   */
  export default {
    name: 'ShipmentDetailReference',
    components: {
      ShipmentUpdatePhoneLink
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-detail-reference {
    background-color: white;
    padding: 8px 50px;

    &__phone {
      .shipment-update-phone-link {
        margin-left: auto;
      }
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 8px 32px;
    }
  }

</style>
