<template>
  <section
    id="transport-section"
    class="shipment-transport-detail p-5 tw-bg-white border-gray tw-rounded"
  >
    <h4 class="mb-4">
      {{ $t('shipment.titles.transport_detail') }}
    </h4>
    <div class="tw-flex tw-flex-col">
      <shipment-direction-detail
        v-for="direction in ['pickup', 'delivery']"
        :key="direction"
        :shipment="getCurrentShipment"
        :direction="direction"
        :data-test="direction"
      />
    </div>
  </section>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import ShipmentDirectionDetail from './_subs/ShipmentDirectionDetail/index.vue'

  export default defineComponent({
    name: 'ShipmentTransportDetail',
    components: {
      ShipmentDirectionDetail
    },
    computed: {
      ...mapGetters('shipments', ['getCurrentShipment'])
    }
  })
</script>

<style lang="scss">

  .shipment-transport-detail {
    border: 1px solid $divider;

    .shipment-direction-detail {
      &:first-child {
        border-bottom: 2px solid $light-gray;
        padding-bottom: 20px;
      }

      &:last-child {
        padding-top: 20px;
      }
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 32px !important;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px !important;
    }
  }

</style>
