<template>
  <div class="shipment-item-proposals">
    <ctk-item
      v-b-toggle="`collapse-${shipment.uuid}`"
      class="tw-flex tw-w-full tw-justify-between shipment-item-proposals__button"
      touchable-type="button"
    >
      <div class="tw-flex">
        <UiBadge
          color="cyan"
          class="shipment-item-proposals__button__count tw-mr-2"
        >
          <template #left-icon>
            <ui-ctk-icon
              name="bid-2"
              data-test="bid-icon"
            />
          </template>

          {{ $n(shipment.pending_proposal_count) }}
        </UiBadge>
        <div class="shipment-item-proposals__button__content fs-13">
          {{ $tc('shipments.titles.proposals', shipment.pending_proposal_count) }}
          <ui-material-icon
            :name="isOpen ? 'arrow_drop_up' : 'arrow_drop_down'"
            data-test="arrow-icon"
          />
        </div>
      </div>
    </ctk-item>

    <b-collapse
      :id="`collapse-${shipment.uuid}`"
      v-model="isOpen"
      class="shipment-item-proposals-item__list"
      @show="$wait.start(loadingKey)"
      @shown="fetchProposals"
      @hide="proposals = []"
    >
      <div class="py-3 shipment-item-proposals-item__list__wrapper">
        <div v-if="!$wait.is(loadingKey)">
          <shipment-item-proposals-item
            v-for="proposal in proposals"
            :key="proposal.uuid"
            :shipment="shipment"
            :proposal="proposal"
            class="mb-3"
          />
        </div>
        <div
          v-else
          class="shipment-iem-proposals-item__list__loader tw-text-gray-700 tw-text-center"
        >
          {{ $t('shipments.titles.loading_proposals') }}
        </div>
      </div>
    </b-collapse>

    <div class="tw-flex shipment-item-proposals__card tw-rounded">
      <span class="tw-font-medium fs-14">
        {{ $n(shipment.pending_proposal_count) }}
      </span>
      <ui-ctk-icon
        name="bid-2"
        data-test="bid-icon"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex'

  import CtkItem from '@/components/CtkItem'
  import ShipmentItemProposalsItem from '@/views/Shippers/components/ShipmentItemProposalsItem'

  /**
   * @module component - shipmentItemProposals
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentItemProposals',
    components: {
      CtkItem,
      ShipmentItemProposalsItem
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isOpen: false,
        pendingRequest: false,
        proposals: []
      }
    },
    computed: {
      loadingKey () {
        return `fetching proposals for ${this.shipment.uuid}`
      }
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentData',
        'retrieveShipmentProposals'
      ]),
      /**
       * Fetch the list of proposals for the shipment
       * @function fetchProposals
       */
      fetchProposals () {
        if (this.pendingRequest) return

        this.proposals = []
        this.$wait.start(this.loadingKey)
        this.pendingRequest = true

        this.retrieveShipmentProposals({
          uuid: this.shipment.uuid,
          options: {
            limit: 30,
            status: 'pending'
          }
        })
          .then((res) => {
            this.proposals = res.data.items
            this.setShipmentData({
              uuid: this.shipment.uuid,
              data: {
                pending_proposal_count: res.data.meta.item_count
              }
            })
          })
          .finally(() => {
            this.pendingRequest = false
            this.$wait.end(this.loadingKey)
          })
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-proposals {
    border-top: 1px solid #E9E9EA;

    &__button {
      border-radius: 0 0 4px 4px;
      padding: 6px 30px;
      transition: background-color 200ms ease;

      &__content {
        margin: auto 0;

        .material-icons {
          vertical-align: middle;
          font-size: 16px;
        }
      }

      @media only screen and (max-width: $breakpoint-laptop-s) {
        padding: 6px 16px;
      }
    }

    &-item__list {
      padding: 0 30px;

      &__wrapper {
        min-height: 50px;
      }

      @media only screen and (max-width: $breakpoint-laptop-s) {
        padding: 0 16px;
      }
    }

    &__card {
      position: absolute;
      display: none;
      background-color: $proposal-pending;
      color: white;
      bottom: 5px;
      left: 16px;
      min-height: 30px;
      padding: 0 8px;

      span,
      .ctk-font {
        margin: auto 0;
      }

      .ctk-font {
        font-size: 24px;
      }

      @media only screen and (max-width: $breakpoint-mobile-l) {
        display: flex;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      &__button,
      &-item__list {
        display: none;
      }
    }
  }

</style>
