<template>
  <shipment-dialog
    :value="value"
    modal-class="shipment-contact-dialog"
    id="shipment-contact-dialog"
    @input="$emit('input', !value)"
  >
    <div class="shipment-contact__content tw-flex tw-flex-1">
      <div class="tw-w-full tw-flex-1 tw-flex tw-items-center tw-justify-center">
        <ValidationObserver
          ref="observer"
          slim
        >
          <form
            class="tw-w-full tw-p-4 md:tw-p-8"
            @submit.prevent="submitted"
          >
            <div
              class="tw-flex tw-items-end tw-mb-4"
              data-test="title"
            >
              <ui-ctk-icon
                :class="{
                  'tw-text-blue-500': direction === 'pickup',
                  'tw-text-green-500': direction === 'delivery'
                }"
                name="contact"
                class="icon-ctk-contact tw-text-5xl"
                data-test="icon"
              />
              <h1
                class="tw-font-normal tw-text-secondary fs-22 tw-mb-2"
                data-test="dialog-title"
                v-text="$t(isEdition ? 'shipment.titles.edit_contact' : 'shipment.titles.add_contact')"
              />
            </div>
            <p
              data-test="paragraph"
              class="tw-mb-5"
              v-text="$t(direction === 'pickup'
                ? 'shipment.paragraphs.contact.pickup'
                : 'shipment.paragraphs.contact.delivery')"
            />

            <ValidationProvider
              ref="name-provider"
              :name="$t('shipment.fields.contact.name')"
              rules="max:128"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-input-text
                  id="contact_name"
                  v-model="formData.name"
                  :label="$t('shipment.labels.contact.name')"
                  :error="invalid && validated"
                  :hint="errors[0]"
                  :disabled="$wait.is('saving contact informations')"
                  type="text"
                  name="contact_name"
                  class="tw-mb-3"
                  data-test="contact-name"
                />
              </template>
            </ValidationProvider>

            <template
              v-if="phoneCountry"
            >
              <ValidationProvider
                ref="phone-provider"
                :name="$t('shipment.fields.contact.phone')"
                :rules="`telephone:${phoneCountry}`"
                slim
              >
                <template slot-scope="{ invalid, validated, errors }">
                  <ctk-phone-number
                    id="contact_phone"
                    ref="contact-phone"
                    v-model="phoneNumber"
                    :country.sync="phoneCountry"
                    :preferred-countries="['FR', 'BE', 'DE', 'NL', 'ES', 'IT', 'PL', 'LU', 'MC', 'AD']"
                    :error="invalid && validated"
                    :hint="errors[0]"
                    :label="$t('shipment.labels.contact.phone')"
                    :disabled="$wait.is('saving contact informations')"
                    name="contact_phone"
                    autocomplete="tel-national"
                    no-validator-state
                    class="tw-mb-3"
                    data-test="contact-phone"
                  />
                </template>
              </ValidationProvider>
            </template>
          </form>
        </ValidationObserver>
      </div>
      <div class="tw-hidden tw-justify-end md:tw-flex tw-pl-12 tw-pt-12 tw-pr-0 tw-flex-1">
        <picture
          class="tw-flex"
        >
          <source
            srcset="~@/assets/img/illustration_reference_dialog.webp"
            type="image/webp"
          >
          <source
            srcset="~@/assets/img/illustration_reference_dialog.svg 2x"
            type="image/svg"
          >
          <img
            src="~@/assets/img/illustration_reference_dialog.png"
            alt=""
            class="tw-select-none tw-opacity-75 tw-ml-auto tw-my-auto tw-max-w-full"
            width="300"
            height="174"
          >
        </picture>
      </div>
    </div>

    <template #footer>
      <div
        class="tw-flex tw-flex-col-reverse 2sm:tw-flex-row tw-justify-between tw-items-center tw-w-full md:tw-px-4"
      >
        <ui-button
          type="button"
          variant="link"
          class="shipment-contact-dialog__footer__cancel tw-w-full 2sm:tw-w-auto tw-mt-3 2sm:tw-mt-0"
          data-test="close"
          @click="$emit('input', false)"
        >
          {{ $t('shipments.buttons.close') }}
        </ui-button>

        <ui-button
          :loading="$wait.is('saving contact informations')"
          :disabled="$wait.is('saving contact informations')"
          variant="primary"
          class="tw-w-full 2sm:tw-w-auto tw-px-12 shipment-contact-dialog__footer__valid"
          data-test="validate"
          @click="submitted"
        >
          {{ $t('validate') | capitalize }}
        </ui-button>
      </div>
    </template>
  </shipment-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'
  import { mapGetters, mapActions } from 'vuex'

  import { Shipment } from '@/resources'
  import { showToaster } from '@/services/Toaster'

  import CtkInputText from '@/components/CtkInputs/CtkInputText/index.vue'
  import CtkPhoneNumber from '@/components/CtkPhoneNumber/index.vue'
  import ShipmentDialog from '@/views/Shippers/components/ShipmentDialog/index.vue'

  const defaultFormData = {
    name: null,
    phone: {
      number: null,
      country: 'FR'
    }
  }

  /**
   * @module component - ShipmentContactDialog
   * @param {string} direction
   * @param {boolean} value
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentContactDialog',
    components: {
      ShipmentDialog,
      CtkPhoneNumber,
      CtkInputText
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      direction: {
        type: String,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        formData: JSON.parse(JSON.stringify(defaultFormData)),
        isEdition: false
      }
    },
    watch: {
      value (v) {
        if (v) {
          this.formData = JSON.parse(JSON.stringify(defaultFormData))

          const { contact } = this.shipment[this.direction]
          const hasContact = contact && (contact.name || contact.phone)

          if (hasContact) {
            if (contact.phone) {
              const parsedPhone = parsePhoneNumberFromString(contact.phone)
              if (parsedPhone) {
                this.formData.phone.country = parsedPhone.country
                this.formData.phone.number = parsedPhone.nationalNumber
              }
            }

            this.formData.name = contact.name
          }

          if (!hasContact || (hasContact && !contact.phone)) {
            /**
             * Force the default phone country to the address country code
             * if the phone is not defined yet.
             */
            const { country } = this.shipment[this.direction].address
            this.formData.phone.country = country
          }

          this.isEdition = JSON.stringify(defaultFormData) !== JSON.stringify(this.formData)
        } else {
          this.isEdition = false
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      phoneNumber: {
        get () {
          return this.formData.phone && this.formData.phone.number
        },
        set (v) {
          if (!this.formData.phone) {
            this.formData.phone = {
              number: null,
              country: this.phoneCountry
            }
          }
          this.formData.phone.number = v
        }
      },
      phoneCountry: {
        get () {
          return (this.formData && this.formData.phone && this.formData.phone.country) || 'FR'
        },
        set (v) {
          this.formData.phone.country = v
        }
      }
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentData'
      ]),
      submitted () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', this.direction === 'pickup'
            ? 'Confirmed Pickup Contact Edition'
            : 'Confirmed Delivery Contact Edition')
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid || this.$wait.is('saving contact informations')) return
            this.$wait.start('saving contact informations')

            const { name, phone } = this.formData

            let formattedPhone
            if (phone && phone.number) {
              const parsedPhone = parsePhoneNumberFromString(phone.number, phone.country)
              formattedPhone = parsedPhone
                ? parsedPhone.number
                : phone.number
            } else if (typeof phone !== 'string') {
              formattedPhone = null
            }

            const payload = {
              name: name || null,
              phone: formattedPhone || null
            }

            const params = {
              cid: this.getCid,
              sid: this.shipment.uuid
            }

            const request = this.direction === 'pickup'
              ? Shipment.contactPickup(params, payload)
              : Shipment.contactDelivery(params, payload)

            request
              .then(() => {
                this.$emit('input', false)

                this.setShipmentData({
                  uuid: this.shipment.uuid,
                  data: {
                    [this.direction]: {
                      ...this.shipment[this.direction],
                      contact: {
                        name: payload.name,
                        phone: payload.phone
                      }
                    }
                  }
                })

                showToaster(
                  this,
                  this.$t('shipment.paragraphs.contact_updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  /**
                   * Show the violation message in the input
                   */
                  if (data.error.violations) {
                    data.error.violations.forEach(violation => {
                      this.$refs[`${violation.property_path}-provider`].setErrors([
                        violation.message
                      ])
                    })
                  }

                  const errorMessage = data.error.detail || data.error.title
                  showToaster(this, errorMessage, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => this.$wait.end('saving contact informations'))
          })
      }
    }
  })
</script>

<style lang="scss">

  .shipment-contact {
    .illustration-container {
      position: relative;

      img {
        opacity: 0.8;
      }
    }

    @media screen and (max-width: $breakpoint-mobile-l) {
      .illustration-container {
        display: none;
      }
    }
  }

</style>
