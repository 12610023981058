<template>
  <ui-button
    :title="$t('close') | capitalize"
    class="shipment-payment-confirmation-view-close-button"
    variant="link"
    type="button"
    data-test="close"
    @click="$emit('close')"
  >
    <template #left-icon>
      <ui-ctk-icon
        name="close"
        data-test="icon"
      />
    </template>
  </ui-button>
</template>

<script>
  /**
   * TODO: This component should be replaced with CtkDialogCloseButton
   * @module component - ShipmentPaymentConfirmationViewCloseButton
   * @emits close
   */
  export default {
    name: 'ShipmentPaymentConfirmationViewCloseButton'
  }
</script>

<style lang="scss" scoped>
.shipment-payment-confirmation-view-close-button {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 1;
}
</style>
