<template>
  <div class="shipment-proposals-list">
    <shipment-proposals-empty
      v-if="pendingProposals.length === 0"
      class="mb-3"
    />

    <div
      v-if="pendingProposals.length > 0"
      class="shipment-proposals-list--pending"
    >
      <div class="shipment-proposals-list__header mb-3">
        <div class="tw-flex">
          <UiBadge
            color="cyan"
            class="shipment-proposals-list__header__count tw-mr-2"
          >
            <template #left-icon>
              <ui-ctk-icon
                name="bid-2"
                data-test="icon"
              />
            </template>

            {{ $n(pendingProposals.length) }}
          </UiBadge>
          <div class="shipment-proposals-list__header__content fs-13">
            {{ $tc('shipments.titles.proposals', pendingProposals.length) }}
          </div>
        </div>
      </div>

      <div class="shipment-proposals-list__content">
        <shipment-item-proposals-item
          v-for="proposal in pendingProposals"
          :key="proposal.uuid"
          :shipment="getCurrentShipment"
          :proposal="proposal"
          compact
          class="mb-3"
        />
      </div>
    </div>

    <shipment-proposals-list-archived
      v-if="archivedProposals.length > 0"
      :proposals="archivedProposals"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import ShipmentItemProposalsItem from '@/views/Shippers/components/ShipmentItemProposalsItem/index.vue'
  import ShipmentProposalsListArchived from './_subs/ShipmentProposalsListArchived/index.vue'
  import ShipmentProposalsEmpty from './_subs/ShipmentProposalsEmpty/index.vue'

  /**
   * @module component - shipmentProposalsList
   */
  export default defineComponent({
    name: 'ShipmentProposalsList',
    components: {
      ShipmentItemProposalsItem,
      ShipmentProposalsEmpty,
      ShipmentProposalsListArchived
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns all the proposals with a pending status
       * @function pendingProposals
       * @returns {Array}
       */
      pendingProposals () {
        const { proposals } = this.getCurrentShipment
        return proposals
          ? proposals.filter(proposal => proposal.status === 'pending')
          : []
      },
      /**
       * Returns all the proposals that are not pending
       * @function archivedProposals
       * @returns {Array}
       */
      archivedProposals () {
        const archivedStatuses = ['cancelled', 'expired', 'refused']
        const { proposals } = this.getCurrentShipment
        return proposals
          ? proposals.filter(proposal => archivedStatuses.includes(proposal.status))
          : []
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-proposals-list {
    &__header {
      &__content {
        margin: auto 0;

        .material-icons {
          vertical-align: middle;
          font-size: 16px;
        }
      }
    }
  }

</style>
