<template>
  <div class="ctk-comment tw-flex tw-flex-col">
    <div
      class="group-label"
      :class="[labelClass]"
    >
      {{ $t('app.labels.comment') }}
    </div>
    <div
      class="group-value"
      :class="[textClass]"
    >
      <p class="mb-0">
        {{ comment ? commentToShow : $t('app.values.no_comment') }}
      </p>
      <a
        v-if="isCommentLong"
        href="#"
        aria-role="button"
        class="tw-text-blue-500 tw-font-medium"
        data-test="read-more"
        @click.prevent="commentVisible = !commentVisible"
      >
        {{ $t(commentVisible
          ? 'app.buttons.read_less'
          : 'app.buttons.read_more') }}
      </a>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'CtkComment',
    props: {
      comment: {
        type: String,
        default: null
      },
      textClass: {
        type: String,
        default: 'tw-text-blue-500'
      },
      labelClass: {
        type: String,
        default: null
      },
      truncateLength: {
        type: Number,
        default: 120
      }
    },
    data () {
      return {
        commentVisible: false
      }
    },
    computed: {
      /**
       * Returns true if we consider that the comment is too long
       * @function isCommentLong
       * @returns {boolean}
       */
      isCommentLong () {
        return this.comment && this.comment.length > this.truncateLength
      },
      /**
       * Returns the proposal comment, if too long, returns a cropped version
       * @function comment
       * @returns {string} comment
       */
      commentToShow () {
        return this.isCommentLong && !this.commentVisible
          ? `${this.comment.slice(0, this.truncateLength)}...`
          : this.comment
      }
    }
  }
</script>

<style lang="scss" scoped>

  .ctk-comment {
    .group-label {
      color: $secondary-text;
    }

    a {
      font-style: italic;
    }
  }

</style>
