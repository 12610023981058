<template>
  <div class="shipment-cancelled-content fs-16 tw-font-medium">
    <div class="shipment-cancelled-content__wrapper">
      <p
        v-text="$t(status)"
        data-test="status"
      />
      <p
        v-if="hasRefund"
        v-text="$t(getCurrentShipment.billing && getCurrentShipment.billing.refunded
          ? 'shipment.paragraphs.cancellation_refunded'
          : 'shipment.paragraphs.cancellation_awaiting_refund')"
        data-test="refund"
      />
      <i18n
        :path="isExpired
          ? 'shipment.paragraphs.expired_shipment_tos'
          : 'shipment.paragraphs.cancelled_shipment_tos'"
        data-test="cgu"
        tag="p"
        class="mb-0"
      >
        <template #tos>
          <a
            :href="tos"
            v-text="$t('shipment.labels.tos')"
            data-test="tos"
            class="tw-text-blue-500"
          />
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import Config from '@/services/Config'

  /**
   * Textual status content when the shipment is "cancelled"
   * @module component - shipmentCancelledContent
   */
  export default {
    name: 'ShipmentCancelledContent',
    data () {
      return {
        tos: `${Config.get('wwwBase')}goto/tos`
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if the refund paragraph should be shown
       * @function hasRefund
       * @returns {boolean}
       */
      hasRefund () {
        const { state, billing, bookings } = this.getCurrentShipment
        const isRefunded = billing && billing.refunded
        const hasBookings = bookings && bookings.length > 0

        const isImmediateCC = billing && billing.payment_plan === 'immediate_cc'
        const isImmediateCCAuth = billing && billing.payment_plan === 'immediate_cc_authorization' && hasBookings

        return state === 'cancelled' && (isImmediateCC || isImmediateCCAuth || isRefunded)
      },
      /**
       * Returns true if the shipment is expired
       * @function isExpired
       * @returns {boolean}
       */
      isExpired () {
        return this.getCurrentShipment.state === 'expired'
      },
      /**
       * Returns the translated key accoridng to the cancellation & the state
       * @function status
       * @returns {string}
       */
      status () {
        if (this.isExpired) {
          return 'shipment.paragraphs.expired_shipment'
        } else {
          switch (this.getCurrentShipment.cancellation.type) {
          case 'cancelled_by_shipper':
            return 'shipment.paragraphs.cancelled_shipment_by_shipper'
          case 'cancelled_by_chronotruck':
            return 'shipment.paragraphs.cancelled_shipment_by_chronotruck'
          case 'payment_expired':
            return 'shipment.paragraphs.cancelled_shipment_payment_expired'
          default:
            return null
          }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-cancelled-content {
    &::after {
      position: absolute;
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;
      background-image: url('~@/assets/img/illustrations/tracking-cancelled.svg');
      bottom: 0;
      right: 32px;
      width: 30%;
      min-height: 150px;
    }

    &__wrapper {
      width: 50%;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      &__wrapper {
        width: 100%;
      }

      &::after {
        display: none;
      }
    }
  }

</style>
