<template>
  <shipment-payment-confirmation-view
    class="shipment-proposals-accept-dialog-payment-confirmation-view"
    :shipment="shipment"
    @close="$emit('close')"
  />
</template>

<script>
  import ShipmentPaymentConfirmationView from '@/views/Shippers/components/ShipmentPaymentConfirmationView/index.vue'

  /**
   * @module component - ShipmentProposalsAcceptDialogPaymentConfirmationView
   * @param {object} shipment - The shipment object
   */
  export default {
    name: 'ShipmentProposalsAcceptDialogPaymentConfirmationView',
    components: {
      ShipmentPaymentConfirmationView
    },
    props: {
      shipment: {
        type: Object,
        default: null
      }
    }
  }
</script>
