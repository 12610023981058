var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('shipment-dialog',{attrs:{"modal-class":"shipment-rate-dialog"},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"px-3 tw-flex tw-flex-1 tw-justify-between shipment-rate-dialog__footer"},[_c('ui-button',{staticClass:"shipment-rate-dialog__footer__cancel",attrs:{"type":"button","variant":"link"},on:{"click":function($event){$event.preventDefault();_vm.visible = false}}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('close')))+" ")]),_c('ui-button',{staticClass:"shipment-rate-dialog__footer__confirm",attrs:{"disabled":_vm.$wait.is('rating shipment'),"loading":_vm.$wait.is('rating shipment'),"variant":"primary"},on:{"click":_vm.submitted}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('validate')))+" ")])],1)]},proxy:true}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"shipment-rate-dialog__content p-5",class:{
      'has-illustration': _vm.data.rate >= 4
    }},[_c('h1',{staticClass:"tw-flex shipment-rate-dialog__content__title mb-3 tw-font-normal"},[_vm._v(" "+_vm._s(_vm.$t('shipment.titles.rate_carrier'))+" ")]),_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{staticClass:"shipment-rate-dialog__content__form tw-flex tw-flex-col",on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('div',{staticClass:"tw-flex shipment-rate-dialog__content__form__header mb-4 tw-items-center"},[_c('ctk-input-rate',{attrs:{"id":"rate","description":""},model:{value:(_vm.data.rate),callback:function ($$v) {_vm.$set(_vm.data, "rate", $$v)},expression:"data.rate"}}),(_vm.data.rate === 1)?_c('i18n',{staticClass:"shipment-rate-dialog__content__form__warning tw-text-red-500 tw-text-center tw-rounded",attrs:{"path":'shipment.paragraphs.bad_rate_warning',"tag":"p"},scopedSlots:_vm._u([{key:"important",fn:function(){return [_c('span',{staticClass:"tw-font-medium",domProps:{"textContent":_vm._s(_vm.$t('shipment.paragraphs.bad_rate_warning_important'))}})]},proxy:true}],null,false,3197027672)}):_vm._e()],1),(_vm.data.rate !== null && _vm.data.rate <= 3)?_c('fieldset',{staticClass:"shipment-rate-dialog__content__form__reasons mb-3"},[_c('legend',{staticClass:"fs-14 tw-font-medium"},[_vm._v(" "+_vm._s(_vm.$t('shipment.titles.rate_reasons'))+" * ")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var reasonsInvalid = ref.invalid;
    var validated = ref.validated;
return [_vm._l((_vm.reasons),function(reason,k){return _c('div',{key:k,staticClass:"tw-flex"},[_c('ValidationProvider',{attrs:{"vid":"reason","name":_vm.$t('shipment.fields.reason'),"rules":'required:true',"slim":""}},[_c('b-form-checkbox',{staticClass:"mr-2 flex-fixed mt-1",attrs:{"id":reason,"value":reason,"name":"reason[]","button-variant":"info"},model:{value:(_vm.data.reasons),callback:function ($$v) {_vm.$set(_vm.data, "reasons", $$v)},expression:"data.reasons"}},[_vm._v(" "+_vm._s(_vm.$t('shipment.labels.rate_reasons.' + reason))+" ")])],1)],1)}),(reasonsInvalid && validated)?_c('div',{staticClass:"tw-text-red-500"},[_vm._v(" "+_vm._s(_vm.$t('shipment.paragraphs.reason_required'))+" ")]):_vm._e()]}}],null,false,987567752)})],1):_vm._e(),_c('ValidationProvider',{attrs:{"name":_vm.$t('shipment.fields.detail'),"rules":_vm.isReasonDetailRequired ? 'required' : '',"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var fieldErrors = ref.errors;
    var fieldInvalid = ref.invalid;
    var validated = ref.validated;
return [(_vm.data.rate !== null && _vm.data.rate <= 3)?_c('ctk-input-textarea',{attrs:{"label":_vm.$t('shipment.labels.rate_details'),"error":fieldInvalid && validated,"hint":fieldErrors[0],"required":_vm.isReasonDetailRequired,"id":"rate-detail","name":"rate-detail"},model:{value:(_vm.data.details),callback:function ($$v) {_vm.$set(_vm.data, "details", $$v)},expression:"data.details"}}):_vm._e()]}}])})],1)])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }