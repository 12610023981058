<template>
  <ctk-list-header
    :title="$t(`shipments.titles.shipments.state.${$route.params.state}`)"
    :subtitle="$tc(`shipments.titles.shipments.subtitle.${$route.params.state}`, getShipmentsMeta.item_count, {
      count: $n(getShipmentsMeta.item_count)
    })"
    class="flex-fixed shipments-list-header"
  >
    <shipments-list-header-filters
      v-if="hasFilters"
    />
  </ctk-list-header>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkListHeader from '@/components/CtkList/_subs/CtkListHeader'
  import ShipmentsListHeaderFilters from './_subs/ShipmentsListHeaderFilters'

  /**
   * @module component - shipmentsListHeader
   */
  export default {
    name: 'ShipmentsListHeader',
    components: {
      CtkListHeader,
      ShipmentsListHeaderFilters
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMeta'
      ]),
      /**
       * Returns true if the view should have filters
       * @function hasFilters
       * @returns {boolean}
       */
      hasFilters () {
        const states = ['available', 'in_progress']
        return states.includes(this.$route.params.state)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipments-list-header {
    width: 100%;
    padding-left: 53px;
    padding-right: 30px;

    .shipments-list-header-filters {
      margin-top: 28px;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding-right: 16px;
      padding-left: 39px;
    }

    &::after {
      left: 53px;
      width: calc(100% - 30px - 53px);

      @media only screen and (max-width: $breakpoint-mobile-l) {
        left: 39px;
        width: calc(100% - 16px - 39px);
      }
    }
  }

</style>
