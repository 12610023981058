<template>
  <address
    class="shipment-carrier-item tw-flex tw-flex-col tw-mb-0"
  >
    <div
      class="tw-font-bold tw-mb-1"
      v-text="title"
      data-test="title"
    />

    <div
      class="tw-font-medium tw--mb-1"
      v-text="name"
      data-test="name"
    />

    <div
      v-if="phone"
      data-test="phone"
      class="tw-text-gray-700"
      v-text="$options.filters.telephone(phone)"
    />
  </address>
</template>

<script>
  /**
   * @module component - ShipmentCarrierItem
   * @param {string} title
   * @param {string} name
   * @param {string} [phone=null]
   */
  export default {
    name: 'ShipmentCarrierItem',
    props: {
      title: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      phone: {
        type: String,
        default: null
      }
    }
  }
</script>
