<template>
  <router-link
    :to="to"
    class="ctk-view-back-button hover:tw-no-underline tw-text-blue-500 hover:tw-text-blue-600 tw-flex tw-items-center"
  >
    <ui-material-icon
      class="tw-mr-2 tw-text-lg"
      name="keyboard_arrow_left"
      data-test="icon"
    />
    <span
      v-text="text"
      data-test="text"
    />
  </router-link>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - CtkViewBackButton
   * @param {object} to
   * @param {string} text
   */
  export default defineComponent({
    props: {
      to: {
        type: Object,
        required: true
      },
      text: {
        type: String,
        required: true
      }
    }
  })
</script>
