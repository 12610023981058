<template>
  <ctk-collapse-nav-item
    :to="{
      name: 'Shipments',
      params: {
        state
      }
    }"
    :title="$tc('shipments.titles.shipments.menu.state.' + state, 2) | capitalize"
    :count="count"
    :has-dot="hasDot"
    class="shipments-sidebar-item"
  />
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters } from 'vuex'

  import CtkCollapseNavItem from '@/components/CtkCollapseSimple/_subs/CtkCollapseNavItem/index.vue'

  /**
   * @module component shipmentsSidebarItem
   * @param {string} state
   * @param {boolean} [hasDot=false]
   */
  export default defineComponent({
    name: 'ShipmentsSidebarItem',
    components: {
      CtkCollapseNavItem
    },
    props: {
      state: {
        type: String,
        required: true
      },
      hasDot: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMetrics'
      ]),
      /**
       * TODO: Move this to the upper component to centralize the logic.
       * Returns either undefined or the total count for the current state
       * @function count
       * @returns {number|undefined}
       */
      count () {
        return this.getShipmentsMetrics && this.getShipmentsMetrics.counts && this.getShipmentsMetrics.counts[this.state]
      }
    }
  })
</script>
