<template>
  <div class="shipments tw-flex tw-flex-1">
    <ctk-side-bar
      :width="350"
      :open="isSidebarOpen"
      class="shipments-menu tw-flex tw-flex-col"
      @toggle-menu="toggleSidebar"
    >
      <shipments-sidebar
        @close="setSidebarOpen(false)"
      />
    </ctk-side-bar>

    <div
      :class="{
        'has-illustration': getShipmentsItems.length > 0 && getShipmentsItems.length <= 5 && !$wait.is('fetching shipments')
      }"
      class="tw-flex tw-flex-col shipments__content tw-w-full"
    >
      <shipments-list />

      <div
        v-if="getShipmentsItems.length === 0 && !$wait.is('fetching shipments')"
        class="tw-flex tw-flex-1 shipments__content__empty"
      >
        <img
          src="@/assets/img/illustrations/shipments.svg"
          class="tw-w-full"
          alt=""
        >
      </div>

      <transition name="fade">
        <div
          v-if="$wait.is('fetching shipments')"
          class="load-layer tw-flex-col"
        >
          <ui-loader
            :size="50"
            background-back="#000"
            type="pallet"
            class="mb-4"
          />
          <p class="fs-16 tw-text-gray-700">
            {{ $t($route.params.state === 'available'
              ? 'shipments.titles.loading_available'
              : 'shipments.titles.loading') | capitalize }}
          </p>
        </div>
      </transition>

      <!-- Dialogs -->
      <shipment-rate-dialog
        v-model="dialogs.rate.visible"
        :rate="dialogs.rate && dialogs.rate.data && dialogs.rate.data.rate"
        :shipment="dialogs.rate && dialogs.rate.data && dialogs.rate.data.shipment"
      />

      <shipment-proposals-accept-dialog
        v-if="dialogs.proposal_accept.data"
        v-model="dialogs.proposal_accept.visible"
        :proposal="dialogs.proposal_accept.data && dialogs.proposal_accept.data.proposal"
        :shipment="dialogs.proposal_accept.data && dialogs.proposal_accept.data.shipment"
      />

      <shipment-proposals-decline-dialog
        v-if="dialogs.proposal_decline.data"
        v-model="dialogs.proposal_decline.visible"
        :proposal="dialogs.proposal_decline.data && dialogs.proposal_decline.data.proposal"
        :shipment="dialogs.proposal_decline.data && dialogs.proposal_decline.data.shipment"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  import CtkSideBar from '@/components/CtkSideBar'
  import ShipmentsSidebar from './components/ShipmentsSidebar'
  import ShipmentsList from './components/ShipmentsList'
  import ShipmentRateDialog from '@/views/Shippers/components/ShipmentDialog/_subs/ShipmentRateDialog'
  import ShipmentProposalsAcceptDialog from '@/views/Shippers/Shipments/components/ShipmentProposalsActions/_subs/ShipmentProposalsAcceptDialog'
  import ShipmentProposalsDeclineDialog from '@/views/Shippers/Shipments/components/ShipmentProposalsActions/_subs/ShipmentProposalsDeclineDialog'

  import { EventBus } from '@/services/EventBus'
  import store from '@/store'

  import UserIdleTracker from 'user-idle-tracker'

  /**
   * @module views - shipments
   */
  export default {
    name: 'Shipments',
    components: {
      CtkSideBar,
      ShipmentsSidebar,
      ShipmentsList,
      ShipmentRateDialog,
      ShipmentProposalsAcceptDialog,
      ShipmentProposalsDeclineDialog
    },
    metaInfo () {
      return {
        title: this.$t('shipments.title')
      }
    },
    data () {
      return {
        userTracker: null,
        dialogs: {
          rate: {
            visible: false,
            data: null
          },
          proposal_accept: {
            visible: false,
            data: {
              proposal: {},
              shipment: {}
            }
          },
          proposal_decline: {
            visible: false,
            data: {
              proposal: {},
              shipment: {}
            }
          }
        }
      }
    },
    beforeRouteUpdate (to, from, next) {
      if (!to.params.state) {
        next({
          name: 'Shipments',
          params: {
            state: 'available'
          }
        })
        return
      }

      /**
       * Update the shipments list if the state has changed
       */
      if (to.params.state !== from.params.state) {
        this.resetShipmentsFilters()
        this.fetchMetrics()
        this.fetchShipments(to.params.state)
      }
      next()
    },
    beforeRouteEnter (to, from, next) {
      if (!to.params.state) {
        next({
          name: 'Shipments',
          params: {
            state: 'available'
          }
        })
        return
      }

      if (!store.getters.isUserShipper) {
        next({
          name: 'Offers'
        })
        return
      }

      store.dispatch('setAppReady', true)
      store.dispatch('shipments/setShipmentsFilter', {
        filter: 'has_pending_proposals',
        value: to.query.has_pending_proposals ? 1 : 0
      })
      store.dispatch('shipments/setShipmentsFilter', {
        filter: 'expires_in',
        value: to.query.expires_in ? 3600 : null
      })
      next()
    },
    mounted () {
      this.userTracking()
      this.fetchMetrics()
      this.fetchShipments(this.$route.params.state)

      // Responsive toggle
      this.toggleOnResize()
      window.addEventListener('resize', this.toggleOnResize)

      EventBus.$on('shipments:refresh-list', () => {
        this.fetchMetrics()
        this.fetchShipments(this.$route.params.state)
      })

      EventBus.$on('shipments:rate-shipment', ({ value: rate, shipment }) => {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Rating', shipment.uuid)
        }

        this.dialogs.rate = {
          visible: true,
          data: {
            shipment,
            rate
          }
        }
      })

      EventBus.$on('shipments:accept-proposal', ({ proposal, shipment }) => {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Proposal Acceptation', shipment.uuid)
        }

        this.dialogs.proposal_accept = {
          visible: true,
          data: {
            proposal,
            shipment
          }
        }
      })

      EventBus.$on('shipments:decline-proposal', ({ proposal, shipment }) => {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Proposal Refusal', shipment.uuid)
        }

        this.dialogs.proposal_decline = {
          visible: true,
          data: {
            proposal,
            shipment
          }
        }
      })
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.toggleOnResize)
      const events = ['shipments:refresh-list', 'shipments:rate-shipment', 'shipments:accept-proposal', 'shipments:decline-proposal']
      events.forEach(event => EventBus.$off(event))
      if (this.userTracker) this.userTracker.destroy()
    },
    computed: {
      ...mapGetters('ui', [
        'isSidebarOpen'
      ]),
      ...mapGetters('shipments', [
        'getShipmentsItems'
      ])
    },
    methods: {
      ...mapActions('ui', [
        'setSidebarOpen',
        'toggleSidebar'
      ]),
      ...mapActions('shipments', [
        'setShipmentsFilter',
        'resetShipmentsFilters',
        'retrieveShipments',
        'retrieveShipmentsMetrics'
      ]),
      /**
       * @function toggleOnResize
       */
      toggleOnResize () {
        const width = document.documentElement.clientWidth
        if (width <= 1350) {
          this.setSidebarOpen(false)
        }
      },
      /**
       * @function fetchMetrics
       */
      fetchMetrics () {
        this.$wait.start('fetching shipments metrics')
        this.retrieveShipmentsMetrics()
          .catch(() => {})
          .finally(() => this.$wait.end('fetching shipments metrics'))
      },
      /**
       * @function fetchShipments
       * @param {string} state
       */
      fetchShipments (state) {
        this.$wait.start('fetching shipments')
        this.retrieveShipments({
          state
        })
          .catch(() => {})
          .finally(() => this.$wait.end('fetching shipments'))
      },
      userTracking () {
        this.userTracker = new UserIdleTracker(() => {
          if (!document.hidden) {
            EventBus.$emit('shipments:refresh-list')
          }
          this.userTracking()
        }, 60000)
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipments {
    &__content {
      position: relative;

      &__empty {
        img {
          margin: auto 0;
          height: 300px;
          max-width: 100%;
          user-select: none;
          opacity: 0.8;
        }
      }

      &.has-illustration {
        &::after {
          position: absolute;
          content: '';
          background-image: url('~@/assets/img/illustrations/city.svg');
          background-repeat: no-repeat;
          background-size: contain;
          background-position: right bottom;
          bottom: 0;
          right: 0;
          width: 70%;
          min-height: 300px;
          z-index: 0;
        }
      }

      .shipments-list {
        z-index: 1;
      }
    }
  }

</style>
