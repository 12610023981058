<template>
  <ctk-dialog
    v-model="modalState"
    :modal-class="`shipment-dialog ${$attrs['modal-class']}`"
    max-width="875px"
    hide-header
    persistent
  >
    <div class="shipment-dialog__header">
      <button
        :title="$t('shipments.buttons.close')"
        class="shipment-dialog__header__close"
        @click="close"
      >
        <ui-ctk-icon
          name="close"
          data-test="icon"
        />
      </button>
    </div>
    <div class="shipment-dialog__content">
      <slot />
    </div>

    <template #footer>
      <slot
        name="footer"
      />
    </template>
  </ctk-dialog>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkDialog from '@/components/CtkDialog/index.vue'
  import useModelGetterSetter from '@/composables/useModelGetterSetter'

  /**
   * @module component - shipmentDialog
   */
  export default defineComponent({
    name: 'ShipmentDialog',
    components: {
      CtkDialog
    },
    props: {
      value: {
        type: Boolean,
        required: true
      }
    },
    model: {
      prop: 'value',
      event: 'input'
    },
    emits: [
      'input'
    ],
    setup (props) {
      const { state: modalState } = useModelGetterSetter(props, 'value')

      /**
       * Close the current dialog
       * @function close
       */
      function close () {
        modalState.value = false
      }

      return {
        modalState,
        close
      }
    }
  })
</script>

<style lang="scss">
.shipment-dialog__header {
  position: relative;
  background-image: url('~@/assets/img/backgrounds/proposal.jpg');
  background-size: cover;
  background-position: center;
  height: 140px;
  border-radius: 0.3rem 0.3rem 0 0;
}
.shipment-dialog__header__close {
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  border: none;
  padding: 0;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: rgba(#303144, 0.7);
  color: white;
  transition: background-color 200ms, box-shadow 200ms;
  outline: none;
}
.shipment-dialog__header__close:hover {
  background-color: rgba(#303144, 1);
}
.shipment-dialog__header__close:focus {
  @include focusShadow($info);
}
.shipment-dialog .modal-container {
  margin-bottom: 1rem;
  width: 100%;
  max-height: initial;
}
@media (min-width: 455px) {
  .shipment-dialog .modal-container {
    margin-bottom: 0px;
    max-height: 100%;
  }
}
.shipment-dialog .modal-wrapper {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
@media (min-width: 455px) {
  .shipment-dialog .modal-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
</style>
