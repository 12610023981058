<template>
  <div
    class="tw-flex shipment-summary-tracking-item tw-relative"
    :class="{
      active,
      'dash-active': dashActive,
      'dash-checked': dashChecked,
      'dash-cancelled': dashCancelled,
      'dash-muted': dashMuted,
      cancelled,
      muted,
      checked,
      shortned: !value
    }"
  >
    <div class="tw-flex flex-fixed shipment-summary-tracking-item__dot__container">
      <div
        :class="{
          'has-check': checked
        }"
        class="tw-flex shipment-summary-tracking-item__dot tw-text-white tw-rounded-full tw-m-auto"
      >
        <ui-material-icon
          v-if="checked"
          :name="cancelled ? 'close': 'check'"
          class="tw-relative tw-m-auto"
          data-test="icon"
        />
      </div>
    </div>
    <div class="tw-flex-1 tw-flex tw-flex-col shipment-summary-tracking-item__content tw-truncate">
      <div class="shipment-summary-tracking-item__content__title tw-truncate tw-flex tw-items-center tw-text-gray-800">
        {{ title }}
        <ui-material-icon
          v-if="hasTooltip"
          v-b-tooltip.right.hover="tooltipOptions"
          name="info"
          class="tw-text-sm tw-ml-2"
          data-test="icon"
          @click.native="click"
        />
      </div>
      <div
        v-if="value"
        class="shipment-summary-tracking-item__content__value tw-text-gray-800 tw-text-sm tw-truncate"
      >
        {{ value }}
      </div>
    </div>
  </div>
</template>

<script>
  import useTooltipDialog from '@/composables/useTooltipDialog'
  import { defineComponent, toRefs } from '@vue/composition-api'

  /**
  * @module component - shipmentSummaryTrackingItem
  * @param {string} title
  * @param {string} [value=null]
  * @param {boolean} [checked=false]
  * @param {boolean} [dashChecked=false]
  * @param {boolean} [cancelled=false]
  * @param {boolean} [muted=false]
  * @param {boolean} [active=false]
  * @param {boolean} [dashActive=false]
  * @param {boolean} [hasTooltip=false]
  * @param {string} [tooltip=null]
  */
  export default defineComponent({
    name: 'ShipmentSummaryTrackingItem',
    props: {
      title: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: null
      },
      checked: {
        type: Boolean,
        default: false
      },
      cancelled: {
        type: Boolean,
        default: false
      },
      muted: {
        type: Boolean,
        default: false
      },
      active: {
        type: Boolean,
        default: false
      },
      dashActive: {
        type: Boolean,
        default: false
      },
      dashChecked: {
        type: Boolean,
        default: false
      },
      dashCancelled: {
        type: Boolean,
        default: false
      },
      dashMuted: {
        type: Boolean,
        default: false
      },
      hasTooltip: {
        type: Boolean,
        default: false
      },
      tooltip: {
        type: String,
        default: null
      }
    },
    setup (props) {
      const { tooltip } = toRefs(props)
      const { click, haveDialog } = useTooltipDialog(null, tooltip.value)

      return {
        click,
        haveDialog
      }
    },
    computed: {
      /**
       * @function tooltipOptions
       * @returns {object}
       */
      tooltipOptions () {
        return {
          title: this.tooltip,
          boundary: 'window',
          disabled: this.haveDialog
        }
      }
    }
  })
</script>

<style lang="scss" scoped>

  $item-height: 50px;

  .shipment-summary-tracking-item {
    min-height: $item-height;

    &::before {
      content: '';
      position: absolute;
      height: $item-height;
      width: 2px;
      left: 9px;
      top: 12px;
      background: $gray;
      z-index: 0;
    }

    &__dot__container {
      width: 20px;
      height: 22px;
    }

    &__dot {
      background: $gray;
      top: 10px;
      width: 8px;
      height: 8px;
      border: 1px solid white;
      z-index: 1;

      &.has-check {
        width: 13px;
        height: 13px;
        background: $primary;

        .material-icons {
          font-size: 9px;
          left: 0.5px;
        }
      }
    }

    &__content {
      &__title {
        font-size: 15px;
      }

      &__value {
        /* stylelint-disable */
        .material-icons {
          color: $secondary;
        }
        /* stylelint-enable */
      }
    }

    &.checked .shipment-summary-tracking-item__content__title,
    &.active .shipment-summary-tracking-item__content__title {
      color: $primary-text;
    }

    &.dash-checked::before {
      background: $primary;
    }

    &.active .shipment-summary-tracking-item__dot,
    &.dash-active::before {
      background: $secondary;
    }

    &.dash-cancelled::before,
    &.cancelled .shipment-summary-tracking-item__dot {
      background: $danger;
    }

    &.cancelled {
      .shipment-summary-tracking-item__content__title {
        color: $danger;
      }
    }

    &.dash-muted::before,
    &.muted .shipment-summary-tracking-item__dot {
      background: $muted;
    }

    &.muted {
      .shipment-summary-tracking-item__content__title,
      .shipment-summary-tracking-item__content__value {
        color: $muted;
      }
    }

    &:last-child::before {
      display: none;
    }

    &.shortned {
      min-height: 35px;

      &::before {
        height: 35px;
      }
    }
  }

</style>
