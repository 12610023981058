<template>
  <div class="shipment-proposals-actions">
    <ui-button
      type="button"
      variant="info"
      size="sm"
      class="tw-mr-3"
      data-test="accept"
      @click="accept"
    >
      {{ $t('shipments.buttons.proposals.accept') }}
    </ui-button>
    <ui-button
      type="button"
      variant="danger"
      size="sm"
      outline
      data-test="decline"
      @click="decline"
    >
      {{ $t('shipments.buttons.proposals.decline') }}
    </ui-button>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - shipmentProposalsActions
   * @param {object} proposal
   * @param {object} shipment
   */
  export default defineComponent({
    name: 'ShipmentProposalsActions',
    props: {
      shipment: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      }
    },
    methods: {
      accept () {
        EventBus.$emit('shipments:accept-proposal', {
          proposal: this.proposal,
          shipment: this.shipment
        })
      },
      decline () {
        EventBus.$emit('shipments:decline-proposal', {
          proposal: this.proposal,
          shipment: this.shipment
        })
      }
    }
  })
</script>
