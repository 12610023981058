<template>
  <div
    class="shipment-summary tw-flex tw-flex-col tw-rounded tw-bg-white tw-p-4 md:tw-p-8"
  >
    <h1
      class="shipment-summary__title tw-text-xl tw-mb-3"
      :class="{
        'tw-text-red-500': associatedState === 'cancelled'
      }"
      v-text="$tc(`shipments.titles.shipment.state.${associatedState}`, 1)"
    />
    <shipment-summary-card
      class="tw-mb-4"
      :shipment="getCurrentShipment"
      :loading="$wait.is('fetching shipment')"
    />
    <shipment-summary-tracking />
    <div class="shipment-summary__infos md:tw-hidden tw-pb-4">
      <shipment-summary-contact
        class="tw-pt-4"
      />
    </div>
    <shipment-summary-carbon-footprint
      v-if="(typeof getCurrentShipment.co2_emissions) !== 'undefined' && !$wait.is('fetching shipment')"
      :weight="getCurrentShipment.co2_emissions"
      class="tw-mt-4 tw-mb-4"
      data-test="carbon-footprint"
    />
    <shipment-summary-actions
      v-if="!$wait.is('fetching shipment')"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import ShipmentSummaryCard from './_subs/ShipmentSummaryCard/index.vue'
  import ShipmentSummaryTracking from './_subs/ShipmentSummaryTracking/index.vue'
  import ShipmentSummaryActions from './_subs/ShipmentSummaryActions/index.vue'
  import ShipmentSummaryContact from './_subs/ShipmentSummaryContact/index.vue'
  import ShipmentSummaryCarbonFootprint from './_subs/ShipmentSummaryCarbonFootprint/index.vue'

  /**
   * @module component - shipmentSummary
   */
  export default defineComponent({
    name: 'ShipmentSummary',
    components: {
      ShipmentSummaryCard,
      ShipmentSummaryTracking,
      ShipmentSummaryActions,
      ShipmentSummaryContact,
      ShipmentSummaryCarbonFootprint
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns a simplified state according to the shipment state,
       * that can be exhaustive.
       * @function associatedState
       * @returns {string}
       */
      associatedState () {
        const { state } = this.getCurrentShipment
        const states = {
          available: 'available',
          planned: 'planned',
          near_pickup: 'planned',
          started: 'in_progress',
          transit: 'in_progress',
          near_delivery: 'in_progress',
          delivered: 'in_progress',
          finished: 'completed',
          proof_of_delivery_available: 'completed',
          expired: 'cancelled',
          cancelled: 'cancelled'
        }

        // @ts-ignore
        return states[state]
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-summary {
    border: 1px solid $divider;

    &__infos {
      border-top: 1px solid $divider;
    }
  }

</style>
