<template>
  <div
    :class="{
      'shipment-billing-invoice--avoir': invoice.type.key === 'AFC'
    }"
    class="shipment-billing-invoice tw-flex tw-items-center tw-p-4"
  >
    <img
      src="@/assets/img/icons/checklist.svg"
      alt=""
      width="20"
      height="25"
      data-test="icon"
    >
    <div class="tw-flex tw-flex-col lg:tw-flex-row tw-flex-1 tw-ml-3">
      <div class="tw-flex tw-items-center lg:tw-w-4/6">
        <UiBadge
          :color="invoice.type.key === 'AFC' ? 'purple' : 'blue'"
          class="tw-mr-auto"
          data-test="content"
        >
          {{ $t(invoice.type.key === 'AFC'
            ? 'shipments.labels.avoir'
            : invoice.type.key === 'PFI'
              ? 'shipments.labels.proforma_invoices'
              : 'shipments.labels.invoice') }}
        </UiBadge>
        <div
          v-text="invoice.reference"
          class="lg:tw-w-1/2 tw-whitespace-nowrap"
          data-test="reference"
        />
      </div>
      <div class="tw-flex tw-justify-between tw-items-center lg:tw-w-2/6">
        <div
          v-text="$t('price_incl_tax', {
            price: $options.filters.currency(invoice.vat_included_amount, 'EUR', $i18n.locale)
          })"
          class="tw-text-right"
          data-test="amount"
        />
        <div>
          <ctk-btn-file
            :id="`download-button-${invoice.reference}`"
            :url="fileUrl"
            :popover-title="$t('app.labels.invoice')"
            :file-name="`invoice-${invoice.reference}.pdf`"
            class="tw-w-full"
            data-test="invoice-button"
          >
            <button
              :title="$options.filters.capitalize($t('app.buttons.download'))"
              type="button"
              class="shipment-billing-invoice__download btn tw-rounded-full tw-w-10 tw-h-10 tw-p-0 tw-bg-white"
              data-test="pod-btn"
            >
              <ui-ctk-icon
                name="save1"
                data-test="icon"
              />
            </button>
          </ctk-btn-file>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import CtkBtnFile from '@/components/CtkBtnFile/index.vue'

  /**
   * @module component - ShipmentBillingInvoice
   * @param {object} invoice
   */
  export default defineComponent({
    components: {
      CtkBtnFile
    },
    props: {
      invoice: {
        type: Object,
        required: true
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * @function fileUrl
       * @returns {string}
       */
      fileUrl () {
        const { billing } = this.getCurrentShipment

        return billing && billing.billing_mode === 'single'
          ? `v2/companies/${this.getCid}/invoices/${this.invoice.uuid}/download`
          : `v2/companies/${this.getCid}/proforma-invoices/${this.invoice.uuid}/download`
      }
    }
  })
</script>

<style lang="scss" scoped>
.shipment-billing-invoice {
  border-radius: 0.25rem;
  position: relative;
  border: 1px solid $divider;
}
.shipment-billing-invoice::before {
  --tw-bg-opacity: 1;
  background-color: rgba(40, 118, 150, var(--tw-bg-opacity));
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  content: '';
  width: 5px;
}
.shipment-billing-invoice--avoir::before {
  --tw-bg-opacity: 1;
  background-color: rgba(132, 67, 185, var(--tw-bg-opacity));
}
.shipment-billing-invoice__download:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 242, 242, var(--tw-bg-opacity));
}
.shipment-billing-invoice__download:focus:not(:focus-visible) {
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  -webkit-box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
  box-shadow: var(--tw-ring-offset-shadow,0 0 #0000),var(--tw-ring-shadow,0 0 #0000),var(--tw-shadow);
}
</style>
