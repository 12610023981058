var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"tw-flex tw-flex-col shipments-list",class:{
    'tw-flex-1': _vm.getShipmentsItems.length > 0,
    'tw-h-full': _vm.getShipmentsItems.length > 0
  }},[_c('shipments-list-header',{staticClass:"flex-fixed",class:{
      '--empty-state': _vm.getShipmentsItems.length === 0
    }}),_c('div',{directives:[{name:"infinite-scroll",rawName:"v-infinite-scroll",value:(_vm.loadMore),expression:"loadMore"}],staticClass:"tw-flex-1 pt-1 pb-3 shipment-list__container",attrs:{"infinite-scroll-distance":"120"}},[(_vm.getShipmentsItems.length > 0)?_c('div',[_vm._l((_vm.getShipmentsItems),function(shipment){return _c('shipment-item',{key:shipment.uuid,staticClass:"mb-2",attrs:{"shipment":shipment}})}),_c('ctk-infinite-loader',{attrs:{"loaded-text":_vm.$route.params.state === 'available'
          ? _vm.$t('shipments.titles.loaded_available')
          : _vm.$t('shipments.titles.loaded'),"load-more-text":_vm.$t('shipments.buttons.load_more'),"is-loading":_vm.$wait.is('loading more shipments'),"can-load-more":_vm.canLoadMore,"items-count":_vm.getShipmentsItems.length},on:{"load-more":_vm.loadMore}})],2):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }