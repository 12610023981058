var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ctk-view-back-button',{staticClass:"shipments-back-button",attrs:{"to":{
    name: 'Shipments',
    params: {
      state: _vm.state
    }
  },"text":_vm.$t('shipment.buttons.all_shipments.state.' + _vm.state, {
    count: _vm.getShipmentsMetrics.counts
      ? _vm.$n(_vm.getShipmentsMetrics.counts[_vm.state])
      : '-'
  })}})}
var staticRenderFns = []

export { render, staticRenderFns }