<template>
  <div class="tw-flex shipment-bookings-item py-2">
    <div class="tw-flex-1">
      <div class="shipment-bookings-item__name tw-font-medium">
        {{ driver.carrier.name | uppercase }}
      </div>
      <div class="shipment-bookings-item__driver-name">
        {{ driver.first_name }} {{ driver.last_name }}
      </div>
    </div>
    <div class="tw-flex-1 text-right">
      <div class="shipment-bookings-item__reference tw-font-medium">
        {{ booking.reference }}
      </div>
      <div
        v-if="booking.cancelled_at"
        class="shipment-bookings-item__date"
      >
        {{ $t('shipment.values.booking_cancelled_at', {
          date: $moment(booking.cancelled_at).format('L')
        }) }}
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * @module component - shipment-bookings-item
   * @param {object} booking
   */
  export default {
    name: 'ShipmentBookingsItem',
    props: {
      booking: {
        type: Object,
        default: null
      }
    },
    computed: {
      driver () {
        return this.booking.driver
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-bookings-item {
    color: $secondary-text;

    &__name {
      color: $primary-text;
    }
  }

</style>
