<template>
  <div class="tw-flex shipment-proposals-empty p-4 tw-rounded">
    <div class="shipment-proposals-empty__illustration mr-3">
      <img
        src="@/assets/img/illustrations/proposal-empty.svg"
        alt=""
        width="200"
      >
    </div>
    <p class="tw-flex-1 shipment-proposals-empty__content">
      {{ $t('shipment.paragraphs.no_proposal') }}
    </p>
  </div>
</template>

<script>
  /**
   * @module component - shipmentProposalsEmpty
   */
  export default {
    name: 'ShipmentProposalsEmpty'
  }
</script>

<style lang="scss" scoped>

  .shipment-proposals-empty {
    background-color: #F5F5F5;

    &__illustration img {
      opacity: 0.8;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) and (min-width: $breakpoint-tablet), screen and (max-width: $breakpoint-mobile-l) {
      &__illustration {
        display: none;
      }
    }
  }

</style>
