<template>
  <a
    @click.stop="openDialog"
    class="shipment-update-phone-link tw-text-blue-500 tw-font-normal fs-13 dots-text"
    href="javascript:void(0)"
  >
    {{ $t('edit') | capitalize }}
  </a>
</template>

<script>
  import { EventBus } from '@/services/EventBus'

  /**
   * Component to show a the edit link of a shipment phone number
   * It calls an EventBus event on click.
   * @module component - ShipmentUpdatePhoneLink
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentUpdatePhoneLink',
    props: {
      shipment: {
        type: Object,
        required: true
      }
    },
    methods: {
      openDialog () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Phone Number Change', this.shipment.uuid)
        }

        EventBus.$emit('shipment:open-dialog', 'phone')
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-update-phone-link {
    font-style: italic;
  }

</style>
