var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-summary-tracking"},[_c('shipment-summary-tracking-item',{attrs:{"title":_vm.$t(_vm.plannedTitle),"value":_vm._f("moment")(_vm.getCurrentShipment.created_at,'LLL'),"muted":_vm.isCancelled,"dash-checked":_vm.hasCarrier,"dash-active":!_vm.hasCarrier,"dash-muted":_vm.isCancelled,"checked":""}}),((!_vm.isCancelled && !_vm.hasCarrier) || _vm.hasCarrier)?_c('shipment-summary-tracking-item',{attrs:{"title":_vm.$t(_vm.hasCarrier
      ? _vm.isPalletNetwork
        ? 'shipment.titles.tracking.carrier_selected_pallet-network'
        : 'shipment.titles.tracking.carrier_selected'
      : 'shipment.titles.tracking.carrier_selection'),"value":_vm.hasCarrier
      ? _vm.$moment(_vm.getCurrentShipment.mission.created_at).format('LLL')
      : null,"checked":_vm.hasCarrier,"dash-checked":_vm.isPickupDone,"active":!_vm.hasCarrier,"dash-active":_vm.hasCarrier && !_vm.isPickupDone,"muted":_vm.isCancelled,"dash-muted":_vm.isCancelled}}):_vm._e(),(!_vm.isCancelled)?_c('shipment-summary-tracking-item',{attrs:{"title":_vm.$t(_vm.isPickupDone
      ? 'shipment.titles.tracking.pickup_done'
      : 'shipment.titles.tracking.pickup'),"value":null,"checked":_vm.isPickupDone,"dash-checked":_vm.isDeliveryDone,"active":_vm.hasCarrier && !_vm.isPickupDone,"dash-active":_vm.isPickupDone && !_vm.isDeliveryDone,"has-tooltip":true,"tooltip":_vm.$t('shipment.paragraphs.pickup-delivery-dates')}}):_vm._e(),(!_vm.isCancelled)?_c('shipment-summary-tracking-item',{attrs:{"title":_vm.$t(_vm.isDeliveryDone
      ? 'shipment.titles.tracking.delivery_done'
      : 'shipment.titles.tracking.delivery'),"value":null,"checked":_vm.isDeliveryDone,"dash-checked":_vm.isPODDone,"active":_vm.isPickupDone && !_vm.isDeliveryDone,"dash-active":(_vm.isPickupDone && _vm.isDeliveryDone) && !_vm.isPODDone,"has-tooltip":true,"tooltip":_vm.$t('shipment.paragraphs.pickup-delivery-dates')}}):_vm._e(),(!_vm.isCancelled)?_c('shipment-summary-tracking-item',{attrs:{"title":_vm.$t(_vm.isPODDone
      ? 'shipment.titles.tracking.cmr_available'
      : 'shipment.titles.tracking.waiting_cmr'),"value":_vm.hasPOD
      ? _vm.$moment(_vm.podDate).format('LLL')
      : null,"checked":_vm.isPODDone,"active":(_vm.isPickupDone && _vm.isDeliveryDone) && !_vm.isPODDone}}):_vm._e(),(_vm.isCancelled)?_c('shipment-summary-tracking-item',{attrs:{"title":_vm.$t(_vm.cancelledTitle),"value":(_vm.isExpired
      ? _vm.$moment(_vm.getCurrentShipment.expired_at)
      : _vm.$moment(_vm.getCurrentShipment.cancellation.date)).format('LLL'),"dash-active":!_vm.isRefunded,"dash-checked":_vm.isRefunded,"muted":_vm.isRefunded,"cancelled":"","checked":""}}):_vm._e(),(_vm.hasRefund)?_c('shipment-summary-tracking-item',{attrs:{"title":_vm.$t(_vm.refundTitle),"value":_vm.isRefunded
      ? _vm.$moment(_vm.getCurrentShipment.billing.refunded_at).format('LLL')
      : null,"checked":_vm.isRefunded,"active":!_vm.isRefunded,"has-tooltip":!_vm.isRefunded,"tooltip":!_vm.isRefunded ? _vm.$t('shipment.paragraphs.refund_delay') : null}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }