<template>
  <div class="shipment-payment-error-view">
    <shipment-payment-confirmation-view-close-button
      data-test="close-button"
      @close="$emit('close')"
    />

    <invoices-pay-dialog-payment-status
      :title="$t('app.titles.pay.declined')"
      :button-title="$t('app.buttons.retry_payment')"
      data-test="error"
      @click="$emit('retry')"
    >
      <p
        v-text="$t('app.paragraphs.pay.declined')"
        data-test="content"
      />
    </invoices-pay-dialog-payment-status>
  </div>
</template>

<script>
  import ShipmentPaymentConfirmationViewCloseButton from '@/views/Shippers/components/ShipmentPaymentConfirmationView/_subs/ShipmentPaymentConfirmationViewCloseButton/index.vue'
  import InvoicesPayDialogPaymentStatus from '@/views/Shippers/Billing/components/InvoicesPayDialog/_subs/InvoicesPayDialogPaymentStatus/index.vue'

  /**
   * @module component - ShipmentPaymentErrorView
   * @emits close
   * @emits retry
   */
  export default {
    name: 'ShipmentPaymentErrorView',
    components: {
      InvoicesPayDialogPaymentStatus,
      ShipmentPaymentConfirmationViewCloseButton
    }
  }
</script>
