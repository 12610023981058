<template>
  <section class="shipment-detail-status">
    <shipment-detail-reference />
    <shipment-detail-status-map />
    <shipment-summary />
    <shipment-detail-status-content />
    <shipment-rate
      v-if="hasRate"
    />
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import ShipmentSummary from '@/views/Shippers/Shipment/_subs/ShipmentSummary/index.vue'
  import ShipmentDetailReference from './_subs/ShipmentDetailReference/index.vue'
  import ShipmentDetailStatusMap from './_subs/ShipmentDetailStatusMap/index.vue'
  import ShipmentDetailStatusContent from './_subs/ShipmentDetailStatusContent/index.vue'
  import ShipmentRate from './_subs/ShipmentRate/index.vue'

  /**
   * @module component - shipmentDetailStatus
   */
  export default defineComponent({
    name: 'ShipmentDetailStatus',
    components: {
      ShipmentSummary,
      ShipmentDetailReference,
      ShipmentDetailStatusMap,
      ShipmentDetailStatusContent,
      ShipmentRate
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if the user is allowed to rate the current shipment
       * @function hasRate
       * @returns {boolean}
       */
      hasRate () {
        const { mission } = this.getCurrentShipment
        return mission && ((mission.actions && mission.actions.can_be_rated) || !!mission.rating)
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-detail-status {
    border-radius: 3px;
    border: 1px solid $divider;

    .shipment-summary {
      display: none;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      .shipment-summary {
        display: flex;
      }

      .shipment-detail-reference {
        display: none;
      }
    }
  }

</style>
