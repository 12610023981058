<template>
  <div class="shipment-summary-contact">
    <div class="tw-flex-1">
      <span class="shipment-summary-contact__label tw-text-gray-800">
        {{ $t('shipment.labels.contact') }}
      </span>
      <span
        class="tw-font-medium"
        data-test="value"
      >
        {{ getCurrentShipment.shipper_phone_number | telephone }}
      </span>
    </div>
    <div
      class="tw-flex-1"
    >
      <shipment-update-phone-link
        v-if="getCurrentShipment.state === 'available' && !$wait.is('fetching shipment')"
        :shipment="getCurrentShipment"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShipmentUpdatePhoneLink from '@/views/Shippers/Shipment/components/ShipmentUpdatePhoneLink'

  /**
   * @module component - shipmentSummaryContact
   */
  export default {
    name: 'ShipmentSummaryContact',
    components: {
      ShipmentUpdatePhoneLink
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    }
  }
</script>
