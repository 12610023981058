<template>
  <div class="shipment-download-cmr-area">
    <div class="shipment-download-cmr-area__wrapper">
      <p
        v-text="$t('shipment.paragraphs.POD_is_available')"
        data-test="pod-available"
        class="tw-font-medium fs-16"
      />
      <p
        v-if="getCurrentShipment.billing && getCurrentShipment.billing.invoice_counts && getCurrentShipment.billing.invoice_counts.total === 0"
        v-text="$t('shipment.paragraphs.invoice_will_be_issued_automatically')"
        data-test="invoice"
        class="tw-font-medium fs-16"
      />
      <ctk-btn-file
        id="btn-download-proof-of-delivery"
        :url="downloadFileUrl"
        :popover-title="$t('shipment.titles.proof_of_delivery')"
        :file-name="`shipment-proof-of-delivery-${getCurrentShipment.reference}.pdf`"
        class="tw-w-full"
        @view="viewFile"
        @download="downloadFile"
      >
        <ui-button
          type="button"
          variant="info"
          class="tw-w-full"
          data-test="pod-btn"
          @click="preview"
        >
          {{ $t('shipment.buttons.download_pod') }}
        </ui-button>
      </ctk-btn-file>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkBtnFile from '@/components/CtkBtnFile'

  export default {
    name: 'ShipmentDownloadCmrArea',
    components: {
      CtkBtnFile
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      ...mapGetters('auth', [
        'getCid'
      ]),
      downloadFileUrl () {
        return `v2/companies/${this.getCid}/shipments/${this.getCurrentShipment.uuid}/proof-of-delivery`
      }
    },
    methods: {
      preview () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated CMR Download', this.getCurrentShipment.uuid)
        }
      },
      viewFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Confirmed CMR Preview', this.getCurrentShipment.uuid)
        }
      },
      downloadFile () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Confirmed CMR Download', this.getCurrentShipment.uuid)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-download-cmr-area {
    &::after {
      position: absolute;
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;
      background-image: url('~@/assets/img/illustrations/tracking-completed.svg');
      bottom: 0;
      right: 0;
      width: 40%;
      min-height: 190px;
    }

    &__wrapper {
      width: 50%;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      &__wrapper {
        width: 100%;
      }

      &::after {
        display: none;
      }
    }
  }

</style>
