<template>
  <ctk-list-filters
    v-model="selectedFilters"
    :label="$t('app.labels.filter')"
    :dialog-title="$t('app.titles.filters')"
    :filters="filters"
    :has-dot="selectedFilters.length > 0"
  />
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { EventBus } from '@/services/EventBus'

  import CtkListFilters from '@/components/CtkListFilters'

  /**
   * @module component - shipmentsListHeaderFilters
   */
  export default {
    name: 'ShipmentsListHeaderFilters',
    components: {
      CtkListFilters
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsFilters'
      ]),
      selectedFilters: {
        get () {
          const filters = []
          const {
            has_pending_proposals: hasPendingProposals,
            expires_in: expiresIn,
            tracking_to_pickup: toPickup,
            tracking_to_deliver: toDeliver,
            tracking_to_upload_pod: toUploadPod
          } = this.getShipmentsFilters

          if (hasPendingProposals) filters.push('has_pending_proposals')
          if (expiresIn) filters.push('expires_in')
          if (toPickup) filters.push('tracking_to_pickup')
          if (toDeliver) filters.push('tracking_to_deliver')
          if (toUploadPod) filters.push('tracking_to_upload_pod')

          return filters.map(filter => ({
            value: filter
          }))
        },
        set (filters) {
          // if (this.$matomo) {
          //   const matchedFilters = {
          //     has_pending_proposals: 'Proposal',
          //     expires_in: 'Expiration',
          //     tracking_to_pickup: 'Pickup',
          //     tracking_to_deliver: 'Deliver',
          //     tracking_to_upload_pod: 'Upload Pod'
          //   }

          //   /**
          //    * TODO: Handle how the Changed Filter event is made.
          //    */
          //   // this.$matomo.trackEvent('Shipments', 'Changed Filter', matchedFilters[filter], v ? 0 : 1)
          // }

          const availableFilters = Object.keys(this.filters)
          const filtersToDisable = availableFilters
            .filter(filter => !filters.map(f => f.value).includes(filter))

          filtersToDisable.forEach(filter => this.setShipmentsFilter({
            filter,
            value: false
          }))

          filters.forEach(filter => this.setShipmentsFilter({
            filter: filter.value,
            value: true
          }))

          EventBus.$emit('shipments:refresh-list')
        }
      },
      /**
       * Returns a key based object with the available filters
       * @function filters
       * @returns {object}
       */
      filters () {
        const availableFilters = {
          has_pending_proposals: this.$t('shipments.labels.filters.has_pending_proposals'), expires_in: this.$t('shipments.labels.filters.expires_in')
        }

        const inProgressFilters = {
          tracking_to_pickup: this.$t('shipments.labels.filters.tracking_to_pickup'),
          tracking_to_deliver: this.$t('shipments.labels.filters.tracking_to_deliver'),
          tracking_to_upload_pod: this.$t('shipments.labels.filters.tracking_to_upload_pod')
        }

        return this.$route.params.state === 'in_progress'
          ? inProgressFilters
          : availableFilters
      }
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentsFilter'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .shipments-list-header-filters {
    &,
    & .filters__label {
      margin: auto 0;
    }

    .filters__items__item {
      &:not(:last-child) {
        margin-right: 16px;
      }

      .material-icons {
        vertical-align: middle;
        font-size: 19px;
        margin: auto 0;
        opacity: 0.8;
      }

      &.btn-white {
        border: 1px solid $gray;
        color: $secondary-text;
      }

      &.btn-info {
        .material-icons {
          opacity: 1;
        }
      }
    }

    .filters__mobile {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: white;
      z-index: 100;
      border: none;
      padding: 0;

      &__btn {
        display: none;
        white-space: nowrap;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        &,
        &__btn {
          display: block;
        }
      }

      &__header {
        min-height: 80px;

        &__title,
        &__close {
          margin: auto 0;
        }

        &__title {
          font-size: 18px;
        }

        &__close {
          background-color: #303144;
          border-color: #303144;
        }
      }

      &__content__item {
        min-height: 45px;
        border-radius: 0;
        margin-right: 0 !important;

        &:not(:first-child) {
          border-top: none;
        }
      }
    }

    .filters__label {
      white-space: nowrap;
    }

    .filters__items,
    .filters__label {
      @media only screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }
  }

</style>
