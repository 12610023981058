<template>
  <div class="shipment-item-proposals-item-status pl-3 pb-3">
    {{ $t('shipment.values.proposal_statuses.' + proposal.status) }}
  </div>
</template>

<script>
  /**
   * @module component - shipmentItemProposalsItemStatus
   * @param {object} proposal
   */
  export default {
    name: 'ShipmentItemProposalsItemStatus',
    props: {
      proposal: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-item-proposals-item-status {
    color: $proposal-danger;
  }

</style>
