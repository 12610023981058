<template>
  <div class="shipment-payment-dialog-payment-view">
    <shipment-payment-confirmation-view-close-button
      :disabled="$wait.is(`paying shipment ${shipment.uuid}`)"
      data-test="close-button"
      @close="close"
    />

    <div class="tw-flex tw-flex-col md:tw-flex-row shipment-payment-dialog-payment-view__body">
      <div
        class="shipment-payment-dialog-payment-view__aside tw-relative tw-w-full tw-flex tw-flex-col tw-p-4 md:tw-p-8"
      >
        <h2
          class="shipment-payment-dialog-payment-view__aside__title tw-relative tw-font-medium tw-text-2xl tw-leading-none tw-mb-10 tw-pr-6 md:tw-pr-0"
          data-test="title"
          v-text="$t('app.titles.pay')"
        />

        <div>
          <shipment-summary-card
            :shipment="shipment"
            :has-load="true"
            :has-price="false"
            data-test="summary"
          />

          <div
            class="tw-mt-4"
            data-test="reassurances"
          >
            <new-shipment-acceptation-dialog-reassurance
              v-for="(reassurance, k) in reassurances"
              :key="k"
              :icon="reassurance.icon"
              :title="$t(reassurance.title)"
              :content="$t(reassurance.content)"
              :highlight="reassurance.highlight"
              :data-test="`reassurance-${k}`"
              class="tw-mb-6"
            />
          </div>
        </div>
      </div>
      <div class="shipment-payment-dialog-payment-view__main">
        <shipment-payment-view
          :stripe="stripe"
          :has-expiration="false"
          :loader="`paying shipment ${shipment.uuid}`"
          :amount-label="$t('app.labels.pay_amount')"
          :amount="shipment.mission.booking_price.vat_included_price"
          :button-label="'app.buttons.pay_amount'"
          :currency="shipment.mission.booking_price.currency"
          class="md:tw-pt-16"
          data-test="payment-view"
          @submitted="submitted"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ShipmentSummaryCard from '@/views/Shippers/Shipment/_subs/ShipmentSummary/_subs/ShipmentSummaryCard/index.vue'
  import ShipmentPaymentView from '@/views/Shippers/components/ShipmentPaymentView/index.vue'
  import NewShipmentAcceptationDialogReassurance from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationDialog/_subs/NewShipmentAcceptationDialogReassurance/index.vue'
  import ShipmentPaymentConfirmationViewCloseButton from '@/views/Shippers/components/ShipmentPaymentConfirmationView/_subs/ShipmentPaymentConfirmationViewCloseButton/index.vue'

  /**
   * @module component - ShipmentPaymentDialogPaymentView
   * @param {object} [shipment=null] - The new shipment
   * @emits close
   */
  export default {
    name: 'ShipmentPaymentDialogPaymentView',
    props: {
      stripe: {
        type: Object,
        default: null
      },
      shipment: {
        type: Object,
        default: null
      }
    },
    components: {
      ShipmentSummaryCard,
      ShipmentPaymentView,
      ShipmentPaymentConfirmationViewCloseButton,
      NewShipmentAcceptationDialogReassurance
    },
    computed: {
      /**
       * @function reassurances
       * @returns {object}
       */
      reassurances () {
        return {
          'cancel-without-fee': {
            icon: require('@/assets/img/icons/quotation/cancel.svg'),
            title: 'app.titles.cancel-without-fee',
            content: 'app.paragraphs.cancel-without-fee'
          }
        }
      }
    },
    methods: {
      /**
       * @function close
       */
      close () {
        this.$emit('close')
      },
      /**
       * Called whenever the form is submitted
       * @function submitted
       */
      submitted (v) {
        this.$emit('submitted', v)
      }
    }
  }
</script>

<style lang="scss">

  .shipment-payment-dialog-payment-view {
    @media only screen and (min-width: $breakpoint-tablet) {
      &__aside {
        width: 45%;
      }

      &__main {
        width: 55%;
      }
    }

    &__aside {
      background-color: $light-gray;
      border-radius: 0.3rem;

      &__title {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -0.75rem;
          width: 190px;
          height: 1px;
          background-color: $secondary-text;
        }
      }
    }

    &__main__checkbox {
      color: $secondary-text;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__aside {
        &::after {
          display: none;
        }
      }
    }
  }

  .shipment-payment-dialog-payment-view .modal-wrapper {
    justify-content: unset;
    align-items: unset;
  }

  .shipment-payment-dialog-payment-view .modal-container {
    max-height: initial;
    margin: auto !important;
    width: 100%;
  }

</style>
