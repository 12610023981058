<template>
  <div class="shipment-proposals-accept-dialog-details">
    <p class="shipment-proposals-accept-dialog-details__explanation mb-4">
      {{ $t('shipments.paragraphs.proposals.accept') }}
    </p>
    <div class="tw-flex shipment-proposals-accept-dialog-details__blocs">
      <div class="tw-flex-1 mr-3 shipment-proposals-accept-dialog-details__blocs__shipment">
        <h2 class="content__subtitle tw-font-normal">
          {{ $t('shipments.titles.proposals.shipment') }}
        </h2>
        <shipment-card
          :shipment="shipment"
        />
      </div>
      <div class="tw-flex-1 shipment-proposals-accept-dialog-details__blocs__proposal">
        <h2 class="content__subtitle tw-font-normal">
          {{ $t('shipments.titles.proposals.proposal') }}
        </h2>
        <shipment-proposal-card
          :shipment="shipment"
          :proposal="proposal"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ShipmentCard from '@/views/Shippers/Shipments/components/ShipmentCard'
  import ShipmentProposalCard from '@/views/Shippers/Shipments/components/ShipmentProposalCard'

  /**
   * @module component - shipmentProposalsAcceptDialogDetails
   * @param {object} shipment
   * @param {object} proposal
   */
  export default {
    name: 'ShipmentProposalsAcceptDialogDetails',
    components: {
      ShipmentCard,
      ShipmentProposalCard
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-proposals-accept-dialog-details {
    .content {
      &__subtitle {
        font-size: 16px;
      }
    }

    &__explanation {
      width: 60%;
    }

    &__blocs {
      .shipment-card,
      .shipment-proposal-card {
        min-height: 150px;
      }

      &__shipment,
      &__proposal {
        width: 50%;

        @media only screen and (max-width: $breakpoint-tablet) {
          width: 100%;
        }
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        flex-direction: column;

        &__shipment {
          margin-bottom: 16px;
          margin-right: 0 !important;
        }
      }
    }
  }

</style>
