<template>
  <div class="shipment-proposal-card">
    <shipment-item-proposals-item-dates-price
      :currency="shipment.offer_price.currency"
      :proposal="proposal"
      class="mb-2"
      highlighted
    />
    <ctk-comment
      label-class="fs-13"
      class="shipment-proposal-card__comment"
      :comment="proposal.comment"
    />
  </div>
</template>

<script>
  import ShipmentItemProposalsItemDatesPrice from '@/views/Shippers/components/ShipmentItemProposalsItem/_subs/ShipmentItemProposalsItemDatesPrice'
  import CtkComment from '@/components/CtkComment'

  /**
   * @module component - shipmentProposalCard
   * @param {object} proposal
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentProposalCard',
    props: {
      proposal: {
        type: Object,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    components: {
      CtkComment,
      ShipmentItemProposalsItemDatesPrice
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-proposal-card {
    background-color: #F9F9F9;
    padding: 16px 16px 16px 32px;

    &__comment {
      position: relative;

      &::after {
        position: absolute;
        content: '';
        width: 7px;
        height: 20px;
        top: 10px;
        left: -16px;
        border: 1px solid $third-text;
        border-right: none;
      }
    }
  }

</style>
