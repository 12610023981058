<template>
  <div class="shipment-card px-3 py-3">
    <shipment-item-header-references
      :shipment="shipment"
      class="mb-2"
    />
    <shipment-item-address-dates
      :shipment="shipment"
      class="mb-2"
    />
    <div class="tw-flex">
      <shipment-item-options
        :shipment="shipment"
        class="tw-flex-1"
      />
      <shipment-item-price
        :shipment="shipment"
        class="flex-fixed"
        white
      />
    </div>
  </div>
</template>

<script>
  import ShipmentItemHeaderReferences from '@/views/Shippers/Shipments/components/ShipmentsList/_subs/ShipmentItem/_subs/ShipmentItemHeader/_subs/ShipmentItemHeaderReferences'
  import ShipmentItemAddressDates from '@/views/Shippers/Shipments/components/ShipmentsList/_subs/ShipmentItem/_subs/ShipmentItemContent/_subs/ShipmentItemAddressDates'
  import ShipmentItemOptions from '@/views/Shippers/Shipments/components/ShipmentsList/_subs/ShipmentItem/_subs/ShipmentItemContent/_subs/ShipmentItemOptions'
  import ShipmentItemPrice from '@/views/Shippers/components/ShipmentItemPrice'

  /**
   * @module component - shipmentCard
   * @param {object} shipment
   */
  export default {
    name: 'ShipmentCard',
    components: {
      ShipmentItemHeaderReferences,
      ShipmentItemAddressDates,
      ShipmentItemOptions,
      ShipmentItemPrice
    },
    props: {
      shipment: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-card {
    background-color: #F9F9F9;

    .shipment-item-options {
      position: relative;
      left: -6px;
    }

    .shipment-item-address-dates::after {
      right: 0;
    }
  }

</style>
