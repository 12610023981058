<template>
  <ctk-summary-card
    :title="offers[shipment.price_type]"
    class="shipment-summary-card tw-border-blue-500 tw-border-solid tw-border"
  >
    <template #pickup>
      <shipment-summary-card-direction
        :timeslot="timeslot('pickup')"
        :name="name('pickup')"
        direction="pickup"
        class="tw-mb-3"
        data-test="pickup"
      />
    </template>

    <template #delivery>
      <shipment-summary-card-direction
        :class="{
          'tw-mb-3': hasLoad
        }"
        :timeslot="timeslot('delivery')"
        :name="name('delivery')"
        direction="delivery"
        data-test="delivery"
      />
    </template>

    <template #load>
      <shipment-summary-card-load
        v-if="hasLoad"
        :load="shipment.load"
        :pallets="pallets"
        data-test="load"
      />
    </template>

    <template #price>
      <ctk-skeleton-block
        v-if="hasPrice"
        :loading="loading"
        :background="'#287696'"
        width="120px"
        height="30px"
        level="2"
        class="shipment-summary-card__price"
        data-test="skeleton"
      >
        <shipment-item-price
          :shipment="shipment"
        />
      </ctk-skeleton-block>
    </template>
  </ctk-summary-card>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  // @ts-ignore
  import { offers } from '@/../config/chronoinfos'

  import CtkSummaryCard from '@/components/CtkSummaryCard/index.vue'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock/index.vue'
  import ShipmentItemPrice from '@/views/Shippers/components/ShipmentItemPrice/index.vue'
  import ShipmentSummaryCardDirection from './_subs/ShipmentSummaryCardDirection/index.vue'
  import ShipmentSummaryCardLoad from './_subs/ShipmentSummaryCardLoad/index.vue'

  /**
   * @module component - ShipmentSummaryCard
   * @param {object} shipment
   * @param {Array<string>} [pallets=null] - A list of pallets. Since it's not rattached to a shipment
   * we must put it separately.
   * @param {boolean} loading
   * @param {boolean} [hasLoad=false] - Show the load infos with the pallets
   * @param {boolean} [hasPrice=true] - Show the price
   */
  export default defineComponent({
    name: 'ShipmentSummaryCard',
    components: {
      CtkSummaryCard,
      CtkSkeletonBlock,
      ShipmentSummaryCardDirection,
      ShipmentSummaryCardLoad,
      ShipmentItemPrice
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      pallets: {
        type: Array,
        default: null
      },
      loading: {
        type: Boolean,
        default: false
      },
      hasLoad: {
        type: Boolean,
        default: false
      },
      hasPrice: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        offers
      }
    },
    computed: {
      /**
       * Returns true if there is a mission
       * @function hasMission
       * @returns {boolean}
       */
      hasMission () {
        return !!this.shipment.mission
      },
      /**
       * @function name
       * @param {string} direction
       */
      name () {
        return /** @param {string} direction */ direction => {
          const directionStep = this.hasMission
            ? this.shipment.mission[direction]
            : this.shipment[direction]

          return directionStep.address.name
        }
      },
      /**
       * @function timeslot
       * @param {string} direction
       */
      timeslot () {
        return /** @param {string} direction */ direction => {
          const directionStep = this.hasMission
            ? this.shipment.mission[direction]
            : this.shipment[direction]

          const { time_slot: timeSlot } = directionStep
          return timeSlot
        }
      }
    }
  })
</script>
