<template>
  <div class="shipment-planned-content fs-16 tw-font-medium">
    <div class="shipment-planned-content__wrapper">
      <p
        v-text="$t('shipment.paragraphs.planned_shipment')"
        data-test="status"
      />
      <i18n
        :path="isFlexible
          ? 'shipment.paragraphs.planned_pickup_date.flexible'
          : 'shipment.paragraphs.planned_pickup_date.fixe'"
        data-test="pickup-date"
        tag="p"
        class="mb-0"
      >
        <template #date>
          <span>{{ date | moment('L LT') }}</span>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * Textual status content when the shipment is "planned"
   * @module component - shipmentPlannedContent
   */
  export default {
    name: 'ShipmentPlannedContent',
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if there is a mission
       * @function hasMission
       */
      hasMission () {
        return !!this.getCurrentShipment.mission
      },
      date () {
        const { time_slot: timeSlot } = this.hasMission
          ? this.getCurrentShipment.mission.pickup
          : this.getCurrentShipment.pickup

        return this.$moment(`${timeSlot.date} ${timeSlot.start_time}`, 'YYYY-MM-DD HH:mm')
      },
      /**
       * Returns true if the timeslot is flexible
       * @function isFlexible
       * @returns {boolean}
       */
      isFlexible () {
        const { time_slot: timeSlot } = this.hasMission
          ? this.getCurrentShipment.mission.pickup
          : this.getCurrentShipment.pickup

        return timeSlot.flexible
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-planned-content {
    &::after {
      position: absolute;
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;
      background-image: url('~@/assets/img/illustrations/tracking-planned.svg');
      bottom: 0;
      right: 32px;
      width: 30%;
      min-height: 150px;
    }

    &__wrapper {
      width: 50%;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      &__wrapper {
        width: 100%;
      }

      &::after {
        display: none;
      }
    }
  }

</style>
