<template>
  <nav
    :class="{
      'has-back': hasBack
    }"
    class="shipment-navigation"
  >
    <div class="tw-flex ctk-container">
      <div
        v-if="hasBack"
        class="tw-flex shipment-navigation__back mr-3"
      >
        <shipments-back-button />
      </div>
      <scrollactive
        :offset="100"
        :click-to-scroll="true"
        :class="{
          'has-back': hasBack
        }"
        class="tw-flex-1 shipment-navigation__scrollable"
      >
        <ul class="tw-flex">
          <li
            v-for="(link, k) in links"
            :key="k"
            data-test="nav-item"
          >
            <a
              :href="`#${link.anchor}`"
              :data-test="`nav-link-${link.anchor}`"
              class="shipment-navigation__item mr-2 scrollactive-item"
            >
              {{ link.title }}
            </a>
          </li>
        </ul>
      </scrollactive>
    </div>
  </nav>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShipmentsBackButton from './../ShipmentsBackButton'

  /**
   * @module component - shipmentNavigation
   * @param {boolean} [hasBack=false]
   * @param {boolean} [hasBilling=false]
   */
  export default {
    name: 'ShipmentNavigation',
    components: {
      ShipmentsBackButton
    },
    props: {
      hasBack: {
        type: Boolean,
        default: false
      },
      hasBilling: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * @function isCorridor
       * @returns {boolean}
       */
      isCorridor () {
        return this.getCurrentShipment.price_type === 'corridor'
      },
      /**
       * @function links
       * @returns {Array}
       */
      links () {
        const { mission, state } = this.getCurrentShipment

        return [
          ...state === 'available' && !this.isCorridor ? [
            {
              anchor: 'proposals-section',
              title: this.$t('shipment.titles.proposals')
            }
          ] : [],
          ...mission ? [
            {
              anchor: 'carrier-section',
              title: this.$t('shipment.titles.carrier_detail')
            }
          ] : [],
          {
            anchor: 'references-section',
            title: this.$t('shipment.titles.references_detail')
          },
          {
            anchor: 'price-section',
            title: this.$t('shipment.titles.price_detail')
          },
          ...this.hasBilling ? [
            {
              anchor: 'billing-section',
              title: this.$t('shipment.titles.billing')
            }
          ] : [],
          {
            anchor: 'transport-section',
            title: this.$t('shipment.titles.transport_detail')
          },
          {
            anchor: 'load-section',
            title: this.$t('shipment.titles.load_detail')
          }
        ]
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-navigation {
    width: 100%;
    background: white;
    border: 1px solid $divider;

    ul,
    li {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    ul,
    &__scrollable {
      overflow-x: auto;

      &.has-back {
        padding: 0 16px;
      }

      @media only screen and (max-width: $breakpoint-tablet) {
        padding: 0 16px;
      }
    }

    &__item {
      display: inline-block;
      height: 53px;
      line-height: 53px;
      text-decoration: none;
      color: $primary-text;
      padding: 0 24px;
      transition: border-bottom 200ms ease;
      border-bottom: 0 solid $divider;
      white-space: nowrap;

      &.is-active {
        border-bottom: 4px solid $info;
      }

      &:hover:not(.is-active) {
        border-bottom: 2px solid $divider;
      }
    }

    &__back {
      flex: 0 1 350px;
      width: 350px;

      @media only screen and (max-width: $breakpoint-tablet) {
        display: none;
      }
    }

    &.has-back {
      border: none;
      box-shadow: 0 1px 8px rgba(black, 0.15);
    }

    &,
    &__back,
    &.has-back .shipment-navigation__item {
      height: 55px;
    }
  }

</style>
