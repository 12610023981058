<template>
  <div
    class="tw-flex tw-flex-col shipments-list"
    :class="{
      'tw-flex-1': getShipmentsItems.length > 0,
      'tw-h-full': getShipmentsItems.length > 0
    }"
  >
    <shipments-list-header
      class="flex-fixed"
      :class="{
        '--empty-state': getShipmentsItems.length === 0
      }"
    />
    <div
      v-infinite-scroll="loadMore"
      class="tw-flex-1 pt-1 pb-3 shipment-list__container"
      infinite-scroll-distance="120"
    >
      <div
        v-if="getShipmentsItems.length > 0"
        data-test="container"
      >
        <shipment-item
          v-for="shipment in getShipmentsItems"
          :key="shipment.uuid"
          :shipment="shipment"
          class="mb-2"
        />

        <ctk-infinite-loader
          :loaded-text="$route.params.state === 'available'
            ? $t('shipments.titles.loaded_available')
            : $t('shipments.titles.loaded')"
          :load-more-text="$t('shipments.buttons.load_more')"
          :is-loading="$wait.is('loading more shipments')"
          :can-load-more="canLoadMore"
          :items-count="getShipmentsItems.length"
          @load-more="loadMore"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import CtkInfiniteLoader from '@/components/CtkInfiniteLoader'
  import ShipmentsListHeader from './_subs/ShipmentsListHeader'
  import ShipmentItem from './_subs/ShipmentItem'

  /**
   * @module component - shipmentsList
   */
  export default {
    name: 'ShipmentsList',
    components: {
      CtkInfiniteLoader,
      ShipmentsListHeader,
      ShipmentItem
    },
    computed: {
      ...mapGetters('shipments', [
        'getShipmentsMeta',
        'getShipmentsItems'
      ]),
      /**
       * Returns true if we are not at the end of the list yet
       * @function canLoadMore
       * @returns {boolean}
       */
      canLoadMore () {
        const meta = this.getShipmentsMeta
        if (!meta || (meta && !meta.pagination)) return
        return meta.pagination.current_page + 1 <= meta.pagination.page_count
      }
    },
    methods: {
      ...mapActions('shipments', [
        'retrieveMoreShipments'
      ]),
      /**
       * Called when the user scrolls in the view to load
       * more shipments; or when clicking on the load more button.
       * @function loadMore
       */
      loadMore () {
        if (!this.canLoadMore) return
        if (this.$wait.is('loading more shipments')) return

        this.$wait.start('loading more shipments')
        this.retrieveMoreShipments({
          state: this.$route.params.state
        })
          .finally(() => this.$wait.end('loading more shipments'))
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipments-list {
    .shipment-list__container {
      padding: 0 30px 0 53px;
      overflow-y: auto;

      @media only screen and (max-width: $breakpoint-mobile-l) {
        padding: 0 16px;
      }
    }
  }

</style>
