<template>
  <div class="shipment-available-content">
    <div class="shipment-available-content__wrapper">
      <h2
        class="shipment-available-content__title tw-text-blue-500 pb-2"
        v-text="$t('shipment.titles.seeking_carrier')"
      />
      <i18n
        path="shipment.paragraphs.expiring"
        data-test="expiring"
        tag="p"
      >
        <template #date>
          <span
            class="tw-font-medium dots-text"
          >
            {{ getCurrentShipment.expires_at | moment('L LT') }}
          </span>
        </template>
      </i18n>
      <i18n
        path="shipment.paragraphs.welcoming"
        data-test="welcome"
        tag="p"
      />
      <shipment-expiration-date-area />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShipmentExpirationDateArea from './_subs/ShipmentExpirationDateArea'

  /**
   * Textual status content when the shipment is "available"
   * @module component - shipmentAvailableContent
   */
  export default {
    name: 'ShipmentAvailableContent',
    components: {
      ShipmentExpirationDateArea
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-available-content {
    &__wrapper {
      width: 50%;
    }

    &::after {
      position: absolute;
      content: '';
      background-image: url('~@/assets/img/illustrations/shipment-planned.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;
      bottom: 0;
      right: 0;
      width: 40%;
      min-height: 200px;
      opacity: 0.8;
    }

    &__title {
      border-bottom: 1px solid $divider;
      font-size: 20px;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      &__wrapper {
        width: 100%;
      }

      &::after {
        display: none;
      }
    }
  }

</style>
