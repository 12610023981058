<template>
  <div class="shipment-rate tw-bg-white p-5">
    <div class="shipment-rate__content tw-flex p-4 tw-rounded">
      <div
        v-if="getCurrentShipment.mission && getCurrentShipment.mission.actions && getCurrentShipment.mission.actions.can_be_rated"
        class="tw-flex-1 shipment-rate__content__rate"
      >
        <h3
          v-text="$t('shipment.titles.rate_carrier')"
          class="fs-18 tw-font-medium mb-3"
          data-test="title"
        />
        <div class="tw-flex shipment-rate__content__rate__wrapper">
          <p
            v-text="$t('shipment.paragraphs.rate_explanation')"
            class="mt-2 tw-font-normal"
            data-test="rate-explanation"
          />
          <div class="tw-flex tw-flex-1">
            <div class="mr-3 tw-flex mx-auto p-2 tw-bg-white tw-rounded">
              <ctk-input-rate
                id="rate"
                v-model="rate"
                :read-only="hasRate"
                description
              />
            </div>
          </div>
        </div>
      </div>
      <shipment-rate-success
        v-if="!!getCurrentShipment.mission.rating"
        class="tw-flex-1"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import ShipmentRateSuccess from './_subs/ShipmentRateSuccess'
  import CtkInputRate from '@/components/CtkInputs/CtkInputRate'

  /**
   * @module component - shipmentRate
   */
  export default {
    name: 'ShipmentRate',
    components: {
      ShipmentRateSuccess,
      CtkInputRate
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if the shipment has been rated
       * @function hasRate
       * @returns {boolean}
       */
      hasRate () {
        const { mission } = this.getCurrentShipment
        return !!(mission && mission.rating && mission.rating.score)
      },
      rate: {
        get () {
          const { mission } = this.getCurrentShipment
          return mission && mission.rating && mission.rating.score
        },
        set (value) {
          EventBus.$emit('shipment:rate-shipment', {
            value,
            shipment: this.getCurrentShipment
          })
        }
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-rate {
    &__content {
      align-items: flex-start;
      background-color: #F5F5F5;

      &__rate__wrapper {
        @media only screen and (max-width: $breakpoint-laptop-s) {
          flex-direction: column;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 0 32px 32px 32px !important;
    }
  }

</style>
