<template>
  <div
    class="shipment-summary-carbon-footprint tw-flex tw-items-center tw-p-3 tw-rounded"
  >
    <img
      src="@/assets/img/icons/co2.svg"
      width="34"
      height="34"
      alt=""
      class="tw-mr-3"
      data-test="icon"
    >
    <div>
      <div
        v-text="$t('shipment.labels.co2')"
        data-test="label"
      />
      <div class="tw-flex tw-items-center">
        <div
          v-text="$t('shipment.values.co2', {
            weight: $n(weight / 1000)
          })"
          class="tw-font-bold"
          data-test="value"
        />
        <ui-material-icon
          v-b-tooltip.right.hover="tooltipOptions"
          class="tw-text-sm tw-ml-2 tw-mr-1 tw--mb-px"
          name="info"
          data-test="info"
          @click.native="click"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent, computed } from '@vue/composition-api'

  import useTooltipDialog from '@/composables/useTooltipDialog'
  import { i18n } from '@/locales'

  /**
   * @module component - ShipmentSummaryCarbonFootprint
   * @param {number} weight - Weight in grams.
   */
  export default defineComponent({
    name: 'ShipmentSummaryCarbonFootprint',
    props: {
      weight: {
        type: Number,
        required: true
      }
    },
    setup () {
      const content = i18n.t('app.paragraphs.carbon_footprint')
      const { click, haveDialog } = useTooltipDialog(null, content)

      const tooltipOptions = computed(() => ({
        title: content,
        disabled: haveDialog.value
      }))

      return {
        click,
        tooltipOptions
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-summary-carbon-footprint {
    background-color: $light-gray;
  }

</style>
