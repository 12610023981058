<template>
  <div class="shipment-detail-status-content">
    <div
      v-if="$wait.is('fetching shipment')"
      data-test="skeleton"
    >
      <ctk-skeleton-block
        width="320px"
        height="35px"
        level="1"
        class="mb-3"
        loading
      />
      <ctk-skeleton-view depth="1">
        <ctk-skeleton-block
          width="210px"
          height="20px"
          level="2"
          class="mb-2"
          loading
        />
      </ctk-skeleton-view>
      <ctk-skeleton-view depth="2">
        <ctk-skeleton-block
          width="180px"
          height="20px"
          level="2"
          class="mb-2"
          loading
        />
      </ctk-skeleton-view>
      <ctk-skeleton-view depth="3">
        <ctk-skeleton-block
          width="210px"
          height="20px"
          level="2"
          class="mb-2"
          loading
        />
      </ctk-skeleton-view>
    </div>

    <template
      v-else
    >
      <shipment-payment-content
        v-if="needPayment"
        data-test="payment"
      />
      <template
        v-else
      >
        <shipment-available-content
          v-if="isAvailable"
        />
        <shipment-planned-content
          v-if="isPlanned"
        />
        <shipment-in-progress-content
          v-if="isInProgress"
        />
        <shipment-cancelled-content
          v-if="isCancelled"
        />

        <shipment-download-cmr-area
          v-if="isFinished && hasPODAvailable"
        />
      </template>
    </template>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock'
  import CtkSkeletonView from '@/components/CtkSkeletonView'
  import ShipmentDownloadCmrArea from './_subs/ShipmentDownloadCmrArea'
  import ShipmentAvailableContent from './_subs/ShipmentAvailableContent'
  import ShipmentPlannedContent from './_subs/ShipmentPlannedContent'
  import ShipmentInProgressContent from './_subs/ShipmentInProgressContent'
  import ShipmentCancelledContent from './_subs/ShipmentCancelledContent'
  import ShipmentPaymentContent from './_subs/ShipmentPaymentContent'

  /**
   * @module component - ShipmentDetailStatusContent
   */
  export default {
    name: 'ShipmentDetailStatusContent',
    components: {
      CtkSkeletonView,
      CtkSkeletonBlock,
      ShipmentDownloadCmrArea,
      ShipmentAvailableContent,
      ShipmentPlannedContent,
      ShipmentInProgressContent,
      ShipmentCancelledContent,
      ShipmentPaymentContent
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      state () {
        return this.getCurrentShipment.state
      },
      /**
       * Returns true if the shipment requires payment
       * @function needPayment
       * @returns {boolean}
       */
      needPayment () {
        return this.getCurrentShipment.billing && this.getCurrentShipment.billing.need_payment
      },
      /**
       * Returns true if the shipment is planned
       * @function isPlanned
       * @returns {boolean}
       */
      isPlanned () {
        return this.state === 'planned'
      },
      /**
       * Returns true if the shipment is finished
       * @function isFinished
       * @returns {boolean}
       */
      isFinished () {
        const states = ['finished', 'proof_of_delivery_available']
        return states.includes(this.state)
      },
      /**
       * Returns true if the shipment is in progress
       * @function isInProgress
       * @returns {boolean}
       */
      isInProgress () {
        const states = ['started', 'transit', 'near_delivery', 'delivered']
        return states.includes(this.state)
      },
      /**
       * Returns true if the shipment is "cancelled"
       * @function isCancelled
       * @returns {boolean}
       */
      isCancelled () {
        const states = ['cancelled', 'expired']
        return states.includes(this.state)
      },
      /**
       * Returns true if the shipment is available
       * @function isAvailable
       * @returns {boolean}
       */
      isAvailable () {
        return this.state === 'available'
      },
      hasPODAvailable () {
        return this.getCurrentShipment.proof_of_delivery.state === 'available'
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-detail-status-content {
    position: relative;
    background: white;
    padding: 32px 50px;
    overflow: hidden;

    &__wrapper {
      width: 50%;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 32px;

      &__wrapper {
        width: 100%;
      }

      &::after {
        display: none;
      }
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px !important;
    }
  }

</style>
