<template>
  <div class="shipment-proposals-list-archived">
    <ui-button
      variant="light"
      class="shipment-proposals-list-archived__button tw-w-full mb-2"
      @click="listOpened = !listOpened"
    >
      <template #left-icon>
        <ui-material-icon
          :name="listOpened ? 'arrow_drop_up' : 'arrow_drop_down'"
          data-test="icon"
        />
      </template>
      <span>
        {{ $tc(listOpened
          ? 'shipment.buttons.hide_archived_proposals'
          : 'shipment.buttons.show_archived_proposals', proposals.length) }}
      </span>
    </ui-button>

    <b-collapse
      id="archived-proposals"
      v-model="listOpened"
    >
      <shipment-item-proposals-item
        v-for="proposal in proposals"
        :key="proposal.uuid"
        :shipment="getCurrentShipment"
        :proposal="proposal"
        compact
        class="mb-3"
      />
    </b-collapse>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import ShipmentItemProposalsItem from '@/views/Shippers/components/ShipmentItemProposalsItem/index.vue'

  /**
   * @module component - ShipmentProposalsListArchived
   * @param {Array<any>} proposals - List of archived proposals
   */
  export default {
    name: 'ShipmentProposalsListArchived',
    components: {
      ShipmentItemProposalsItem
    },
    props: {
      proposals: {
        type: Array,
        required: true
      }
    },
    data () {
      return {
        listOpened: false
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    }
  }
</script>
