<template>
  <div class="shipment-detail-address">
    <div class="tw-flex tw-items-center shipment-detail-address-title mb-3">
      <img
        v-if="direction === 'pickup'"
        src="@/assets/img/icons/map/in_progress_pickup.svg"
        alt=""
        width="14"
        height="20"
        class="mr-2"
      >
      <img
        v-else
        src="@/assets/img/icons/map/in_progress_delivery.svg"
        alt=""
        width="14"
        height="20"
        class="mr-2"
      >
      <h6 class="fs-16 m-0">
        {{ $t(direction === 'pickup' ? 'app.labels.pickup' : 'app.labels.delivery') }}
      </h6>
    </div>
    <div class="shipment-detail-address-content">
      <p
        data-test="name"
        class="tw-font-medium mb-0 dots-text"
      >
        {{ address.name }}
      </p>
      <ctk-address
        :address="address"
      />
    </div>
  </div>
</template>

<script>
  import CtkAddress from '@/components/CtkAddress'

  export default {
    name: 'ShipmentDetailAddress',
    components: {
      CtkAddress
    },
    props: {
      address: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-detail-address {
    &-content {
      padding-left: 22px;

      @media only screen and (max-width: $breakpoint-mobile-l) {
        padding-left: 0;
      }
    }
  }

</style>
