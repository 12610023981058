var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('shipment-dialog',{attrs:{"value":_vm.value,"modal-class":"shipment-contact-dialog","id":"shipment-contact-dialog"},on:{"input":function($event){return _vm.$emit('input', !_vm.value)}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"tw-flex tw-flex-col-reverse 2sm:tw-flex-row tw-justify-between tw-items-center tw-w-full md:tw-px-4"},[_c('ui-button',{staticClass:"shipment-contact-dialog__footer__cancel tw-w-full 2sm:tw-w-auto tw-mt-3 2sm:tw-mt-0",attrs:{"type":"button","variant":"link"},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" "+_vm._s(_vm.$t('shipments.buttons.close'))+" ")]),_c('ui-button',{staticClass:"tw-w-full 2sm:tw-w-auto tw-px-12 shipment-contact-dialog__footer__valid",attrs:{"loading":_vm.$wait.is('saving contact informations'),"disabled":_vm.$wait.is('saving contact informations'),"variant":"primary"},on:{"click":_vm.submitted}},[_vm._v(" "+_vm._s(_vm._f("capitalize")(_vm.$t('validate')))+" ")])],1)]},proxy:true}])},[_c('div',{staticClass:"shipment-contact__content tw-flex tw-flex-1"},[_c('div',{staticClass:"tw-w-full tw-flex-1 tw-flex tw-items-center tw-justify-center"},[_c('ValidationObserver',{ref:"observer",attrs:{"slim":""}},[_c('form',{staticClass:"tw-w-full tw-p-4 md:tw-p-8",on:{"submit":function($event){$event.preventDefault();return _vm.submitted.apply(null, arguments)}}},[_c('div',{staticClass:"tw-flex tw-items-end tw-mb-4"},[_c('ui-ctk-icon',{staticClass:"icon-ctk-contact tw-text-5xl",class:{
                'tw-text-blue-500': _vm.direction === 'pickup',
                'tw-text-green-500': _vm.direction === 'delivery'
              },attrs:{"name":"contact"}}),_c('h1',{staticClass:"tw-font-normal tw-text-secondary fs-22 tw-mb-2",domProps:{"textContent":_vm._s(_vm.$t(_vm.isEdition ? 'shipment.titles.edit_contact' : 'shipment.titles.add_contact'))}})],1),_c('p',{staticClass:"tw-mb-5",domProps:{"textContent":_vm._s(_vm.$t(_vm.direction === 'pickup'
              ? 'shipment.paragraphs.contact.pickup'
              : 'shipment.paragraphs.contact.delivery'))}}),_c('ValidationProvider',{ref:"name-provider",attrs:{"name":_vm.$t('shipment.fields.contact.name'),"rules":"max:128","slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
              var validated = ref.validated;
              var errors = ref.errors;
return [_c('ctk-input-text',{staticClass:"tw-mb-3",attrs:{"id":"contact_name","label":_vm.$t('shipment.labels.contact.name'),"error":invalid && validated,"hint":errors[0],"disabled":_vm.$wait.is('saving contact informations'),"type":"text","name":"contact_name"},model:{value:(_vm.formData.name),callback:function ($$v) {_vm.$set(_vm.formData, "name", $$v)},expression:"formData.name"}})]}}])}),(_vm.phoneCountry)?[_c('ValidationProvider',{ref:"phone-provider",attrs:{"name":_vm.$t('shipment.fields.contact.phone'),"rules":("telephone:" + _vm.phoneCountry),"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var invalid = ref.invalid;
              var validated = ref.validated;
              var errors = ref.errors;
return [_c('ctk-phone-number',{ref:"contact-phone",staticClass:"tw-mb-3",attrs:{"id":"contact_phone","country":_vm.phoneCountry,"preferred-countries":['FR', 'BE', 'DE', 'NL', 'ES', 'IT', 'PL', 'LU', 'MC', 'AD'],"error":invalid && validated,"hint":errors[0],"label":_vm.$t('shipment.labels.contact.phone'),"disabled":_vm.$wait.is('saving contact informations'),"name":"contact_phone","autocomplete":"tel-national","no-validator-state":""},on:{"update:country":function($event){_vm.phoneCountry=$event}},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}})]}}],null,false,4136763269)})]:_vm._e()],2)])],1),_c('div',{staticClass:"tw-hidden tw-justify-end md:tw-flex tw-pl-12 tw-pt-12 tw-pr-0 tw-flex-1"},[_c('picture',{staticClass:"tw-flex"},[_c('source',{attrs:{"srcset":require("@/assets/img/illustration_reference_dialog.webp"),"type":"image/webp"}}),_c('source',{attrs:{"srcset":require("@/assets/img/illustration_reference_dialog.svg") + " 2x","type":"image/svg"}}),_c('img',{staticClass:"tw-select-none tw-opacity-75 tw-ml-auto tw-my-auto tw-max-w-full",attrs:{"src":require("@/assets/img/illustration_reference_dialog.png"),"alt":"","width":"300","height":"174"}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }