<template>
  <section
    id="references-section"
    class="shipment-references-detail tw-p-12 tw-bg-white tw-border tw-border-solid tw-border-gray-300 tw-rounded"
  >
    <h4
      class="tw-mb-6 tw-text-2xl"
      data-test="title"
      v-text="$t('shipment.titles.references_detail')"
    />

    <div class="shipment-references-detail__content tw-flex tw-flex-col md:tw-flex-row">
      <div class="shipment-references-detail__content__side tw-relative tw-flex tw-flex-col md:tw-w-1/2 md:tw-mr-16">
        <shipment-references-detail-item
          :title="$t('shipment.labels.references.chronotruck')"
          :value="getCurrentShipment.reference"
          class="tw-mb-5"
          data-test="reference"
        />
        <shipment-references-detail-item
          :title="$t('shipment.labels.references.order')"
          :value="getCurrentShipment.shipper_reference"
          class="tw-mb-5 md:tw-mb-0"
          data-test="shipper-reference"
        />
      </div>
      <div class="shipment-references-detail__content__side tw-flex tw-flex-col md:tw-w-1/2">
        <shipment-references-detail-item
          :title="$t('shipment.labels.references.pickup')"
          :value="getCurrentShipment.pickup.reference"
          class="tw-mb-5"
          data-test="pickup-reference"
        >
          <ui-ctk-icon
            class="tw-text-blue-500"
            name="marker"
            data-test="icon"
          />
        </shipment-references-detail-item>
        <shipment-references-detail-item
          :title="$t('shipment.labels.references.delivery')"
          :value="getCurrentShipment.delivery.reference"
          data-test="delivery-reference"
        >
          <ui-ctk-icon
            class="tw-text-green-500"
            name="marker"
            data-test="icon"
          />
        </shipment-references-detail-item>
      </div>
    </div>
    <div
      v-if="hasEdition"
      class="tw-flex tw-items-center tw-justify-center tw-mt-10"
    >
      <ui-button
        :rounded="false"
        variant="info"
        outline
        type="button"
        data-test="edit"
        @click="edit"
      >
        {{ $t('shipment.buttons.edit_references') }}
      </ui-button>
    </div>
  </section>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { defineComponent } from '@vue/composition-api'

  import ShipmentReferencesDetailItem from './_subs/ShipmentReferencesDetailItem/index.vue'

  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - ShipmentReferencesDetail
   */
  export default defineComponent({
    name: 'ShipmentReferencesDetail',
    components: {
      ShipmentReferencesDetailItem
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if the user is allowed to edit this shipment
       * @function hasEdition
       */
      hasEdition () {
        const { actions } = this.getCurrentShipment

        return actions && actions.can_update_reference
      }
    },
    methods: {
      edit () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Reference Change', this.getCurrentShipment.uuid)
        }

        EventBus.$emit('shipment:open-dialog', 'reference')
      }
    }
  })
</script>

<style lang="scss">

  .shipment-references-detail {
    @media only screen and (min-width: $breakpoint-tablet) {
      &__content__side:first-child {
        &::after {
          position: absolute;
          content: '';
          right: -2rem;
          bottom: 0;
          height: 100%;
          height: calc(100% - 35px);
          width: 1px;
          background-color: #F5F5F5;
        }
      }
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 32px !important;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px !important;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &__content {
        flex-direction: column;
      }
    }
  }

</style>
