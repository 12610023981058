<template>
  <div class="shipment-price-detail-booking">
    <div
      v-text="$t('shipment.titles.billing_carrier_booking', {
        carrier: booking && booking.driver && booking.driver.carrier ? booking.driver.carrier.name : '-'
      })"
      class="shipment-price-detail-booking__header tw-font-medium"
      data-test="header"
    />
    <div>
      <ui-button
        class="shipment-price-detail-booking__toggle"
        variant="link"
        data-test="toggle"
        @click="isOpen = !isOpen"
      >
        <template #left-icon>
          <ui-material-icon
            :name="isOpen ? 'arrow_drop_up' : 'arrow_drop_down'"
            data-test="icon"
          />
        </template>

        {{ !isOpen
          ? $t('shipment.buttons.bookings.show_lines')
          : $t('shipment.buttons.bookings.hide_lines') }}
      </ui-button>

      <b-collapse
        id="collapse"
        v-model="isOpen"
        class="mt-2"
      >
        <pricing-layout
          v-if="booking.billing.lines"
          class="mb-2"
        >
          <pricing-lines
            v-for="line in booking.billing.lines"
            :key="line.key"
            :label="line.label"
            :cancelled="line.is_cancelled"
            :comment="line.comment"
          >
            <span class="tw-text-blue-500">
              {{ line.price | currency(booking.billing.currency, $i18n.locale) }}
            </span>
          </pricing-lines>
        </pricing-layout>
      </b-collapse>
    </div>
    <div
      class="shipment-price-detail-booking__footer"
      data-test="footer"
    >
      <price-total
        :price-excluded="$t('price_excl_tax', {
          price: $options.filters.currency(booking.billing.price, booking.billing.currency, $i18n.locale)
        })"
        :price-included="$t('price_incl_tax', {
          price: $options.filters.currency(booking.billing.vat_included_price, booking.billing.currency, $i18n.locale)
        })"
        data-test="total"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import PricingLayout from '@/components/CtkPricing/_subs/PricingLayout.vue'
  import PricingLines from '@/components/CtkPricing/_subs/PricingLines/index.vue'
  import PriceTotal from '@/components/CtkPricing/_subs/PriceTotal/index.vue'

  /**
   * @module component - ShipmentPriceDetailBooking
   * @param {object} booking
   */
  export default defineComponent({
    name: 'ShipmentPriceDetailBooking',
    components: {
      PricingLayout,
      PriceTotal,
      PricingLines
    },
    props: {
      booking: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isOpen: false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-price-detail-booking {
    &__header {
      border-bottom: 1px solid $divider;
      padding: 8px 0;
    }

    .pricing-layout {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
  }

</style>
