<template>
  <div
    class="shipment-proposals-accept-dialog-bid-view"
  >
    <div class="shipment-proposals-accept-dialog-bid-view__header">
      <button
        :title="$t('shipments.buttons.close')"
        class="shipment-proposals-accept-dialog-bid-view__header__close"
        @click="close"
      >
        <ui-ctk-icon
          name="close"
          data-test="icon"
        />
      </button>
    </div>
    <!-- Content -->
    <div class="shipment-proposals-accept-dialog-bid-view__content">
      <div class="shipment-proposals-accept-dialog__content">
        <h1 class="tw-flex shipment-proposals-accept-dialog__content__title mb-3 tw-font-normal">
          <ui-ctk-icon
            name="bid-2"
            data-test="icon"
          />
          <span>
            {{ $t('shipments.titles.proposals.accept') }}
          </span>
        </h1>

        <!-- Details -->
        <shipment-proposals-accept-dialog-details
          :shipment="shipment"
          :proposal="proposal"
        />
      </div>
    </div>

    <!-- Footer -->
    <div class="modal-footer">
      <div
        class="md:tw-px-4 tw-flex tw-flex-col-reverse md:tw-flex-row tw-flex-1 tw-justify-between shipment-proposals-accept-dialog__footer"
      >
        <ui-button
          variant="link"
          type="button"
          class="shipment-proposals-accept-dialog__footer__cancel tw-mt-4 md:tw-mt-0"
          @click="close"
        >
          {{ $t('shipments.buttons.proposals.cancel') }}
        </ui-button>

        <ui-button
          :loading="$wait.is(`accepting proposal ${proposal.uuid}`)"
          type="button"
          variant="primary"
          data-test="accept-proposal"
          @click="$emit('accept')"
        >
          <div class="tw-flex">
            <i18n
              :path="proposal.shipper_price
                ? 'shipments.buttons.proposals.accept_proposal_price'
                : 'shipments.buttons.proposals.accept_proposal'"
            >
              <template
                v-if="proposal.shipper_price"
                #price
              >
                <span
                  class="tw-font-medium"
                >
                  {{ proposal.shipper_price | currency(shipment.offer_price.currency, $i18n.locale) }}
                </span>
              </template>
            </i18n>
          </div>
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import ShipmentProposalsAcceptDialogDetails from './../ShipmentProposalsAcceptDialogDetails/index.vue'

  /**
   * @module component - ShipmentProposalsAcceptDialogBidView
   * @param {object} shipment
   * @param {object} proposal
   * @emits accept
   * @emits close
   */
  export default defineComponent({
    name: 'ShipmentProposalsAcceptDialogBidView',
    components: {
      ShipmentProposalsAcceptDialogDetails
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      }
    },
    methods: {
      /**
       * Close the current dialog
       * @function close
       */
      close () {
        this.$emit('close')
      }
    }
  })
</script>

<style lang="scss">
.shipment-proposals-accept-dialog-bid-view__header {
  position: relative;
  background-image: url('~@/assets/img/backgrounds/proposal.jpg');
  background-size: cover;
  background-position: center;
  height: 140px;
  border-radius: 0.3rem 0.3rem 0 0;
}
.shipment-proposals-accept-dialog-bid-view__header__close {
  position: absolute;
  top: 16px;
  right: 16px;
  appearance: none;
  border: none;
  padding: 0;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  background-color: rgba(#303144, 0.7);
  color: white;
  transition: background-color 200ms, box-shadow 200ms;
  outline: none;
}
.shipment-proposals-accept-dialog-bid-view__header__close:hover {
  background-color: rgba(#303144, 1);
}
.shipment-proposals-accept-dialog-bid-view__header__close:focus {
  @include focusShadow($info);
}
.shipment-proposals-accept-dialog-bid-view .modal-container {
  margin-bottom: 1rem;
  width: 100%;
  max-height: initial;
}
@media (min-width: 455px) {
  .shipment-proposals-accept-dialog-bid-view .modal-container {
    margin-bottom: 0px;
    max-height: 100%;
  }
}
.shipment-proposals-accept-dialog-bid-view .modal-wrapper {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  -webkit-justify-content: flex-start;
  justify-content: flex-start;
}
@media (min-width: 455px) {
  .shipment-proposals-accept-dialog-bid-view .modal-wrapper {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
}
</style>
