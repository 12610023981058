<template>
  <div class="shipment-references-detail-item">
    <div
      class="shipment-references-detail-item__title tw-font-medium tw-text-base tw-flex tw-items-center tw-truncate"
      data-test="title"
    >
      <slot />
      <div
        class="tw-truncate"
        v-text="title"
      />
    </div>
    <div
      class="shipment-references-detail-item__value tw-font-normal tw-text-sm tw-px-5 tw-py-1 tw-rounded tw-truncate tw-select-all"
      data-test="value"
      v-text="value || '-'"
    />
  </div>
</template>

<script>
  /**
   * @module component - ShipmentReferencesDetailItem
   */
  export default {
    name: 'ShipmentReferencesDetailItem',
    props: {
      title: {
        type: String,
        required: true
      },
      value: {
        type: String,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-references-detail-item {
    .ctk-font {
      width: 30px;
      margin-left: -11px;
    }

    &__title {
      height: 35px;
    }

    &__value {
      background-color: #F5F5F5;
    }
  }

</style>
