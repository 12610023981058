<template>
  <div
    id="shipment"
    class="shipment"
    :class="{
      'ctk-container': getCurrentShipment
    }"
  >
    <div
      v-if="isAppReady && getCurrentShipment"
      class="shipment__content tw-flex"
    >
      <div class="shipment-summary-wrapper mr-3">
        <div
          class="shipment-header tw-flex tw-items-center"
        >
          <shipments-back-button />
        </div>
        <shipment-summary />
      </div>
      <div
        class="shipment-detail-wrapper tw-min-w-0"
      >
        <shipment-detail
          :has-back="affixed"
          @affix="affix"
        />
      </div>
    </div>
    <ctk-loading-layer
      v-if="(!getCurrentShipment || (getCurrentShipment && !getCurrentShipment.uuid)) && $wait.is('fetching shipment')"
    >
      {{ $t('shipment.paragraphs.loading_shipment') }}
    </ctk-loading-layer>
    <shipment-not-found
      v-if="!getCurrentShipment && !$wait.is('fetching shipment')"
    />

    <!-- Global dialogs -->
    <shipment-payment-dialog
      v-if="getCurrentShipment"
      v-model="dialogs.payment"
      :shipment="getCurrentShipment"
    />

    <shipment-cancel-dialog
      v-if="getCurrentShipment"
      v-model="dialogs.cancellation"
      :shipment="getCurrentShipment"
    />

    <shipment-update-phone-dialog
      v-if="getCurrentShipment"
      v-model="dialogs.phone"
      :shipment="getCurrentShipment"
    />

    <shipment-update-reference-dialog
      v-if="getCurrentShipment"
      v-model="dialogs.reference"
      :shipment="getCurrentShipment"
    />

    <shipment-update-expiration-date-dialog
      v-if="getCurrentShipment && getCurrentShipment.state === 'available'"
      v-model="dialogs.expiresAt"
      :shipment="getCurrentShipment"
    />

    <shipment-rate-dialog
      v-model="dialogs.rate.visible"
      :rate="dialogs.rate && dialogs.rate.data && dialogs.rate.data.rate"
      :shipment="dialogs.rate && dialogs.rate.data && dialogs.rate.data.shipment"
    />

    <shipment-contact-dialog
      v-model="dialogs.contact.visible"
      :direction="dialogs.contact.data.direction"
      :shipment="dialogs.contact && dialogs.contact.data && dialogs.contact.data.shipment"
    />

    <shipment-proposals-accept-dialog
      v-if="dialogs.proposal_accept.data"
      v-model="dialogs.proposal_accept.visible"
      :proposal="dialogs.proposal_accept.data && dialogs.proposal_accept.data.proposal"
      :shipment="dialogs.proposal_accept.data && dialogs.proposal_accept.data.shipment"
    />

    <shipment-proposals-decline-dialog
      v-if="dialogs.proposal_decline.data"
      v-model="dialogs.proposal_decline.visible"
      :proposal="dialogs.proposal_decline.data && dialogs.proposal_decline.data.proposal"
      :shipment="dialogs.proposal_decline.data && dialogs.proposal_decline.data.shipment"
    />
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapActions, mapGetters } from 'vuex'

  import { EventBus } from '@/services/EventBus'
  import store from '@/store'
  import { Shipment } from '@/resources'

  import CtkLoadingLayer from '@/components/CtkLoadingLayer/index.vue'
  import ShipmentsBackButton from './components/ShipmentsBackButton/index.vue'
  import ShipmentNotFound from './components/ShipmentNotFound/index.vue'
  import ShipmentRateDialog from '@/views/Shippers/components/ShipmentDialog/_subs/ShipmentRateDialog/index.vue'
  import ShipmentDetail from './_subs/ShipmentDetail/index.vue'
  import ShipmentSummary from './_subs/ShipmentSummary/index.vue'
  import ShipmentPaymentDialog from './components/ShipmentPaymentDialog/index.vue'
  import ShipmentContactDialog from './components/ShipmentContactDialog/index.vue'
  import ShipmentUpdateReferenceDialog from './components/ShipmentUpdateReferenceDialog/index.vue'
  import ShipmentUpdatePhoneDialog from './components/ShipmentUpdatePhoneDialog/index.vue'
  import ShipmentUpdateExpirationDateDialog from './components/ShipmentUpdateExpirationDateDialog/index.vue'
  import ShipmentCancelDialog from './components/ShipmentCancelDialog/index.vue'
  import ShipmentProposalsAcceptDialog from '@/views/Shippers/Shipments/components/ShipmentProposalsActions/_subs/ShipmentProposalsAcceptDialog/index.vue'
  import ShipmentProposalsDeclineDialog from '@/views/Shippers/Shipments/components/ShipmentProposalsActions/_subs/ShipmentProposalsDeclineDialog/index.vue'

  export default defineComponent({
    name: 'Shipment',
    metaInfo () {
      return {
        title: this.$t('shipment.title')
      }
    },
    components: {
      ShipmentsBackButton,
      CtkLoadingLayer,
      ShipmentDetail,
      ShipmentNotFound,
      ShipmentSummary,
      ShipmentRateDialog,
      ShipmentContactDialog,
      ShipmentPaymentDialog,
      ShipmentUpdateReferenceDialog,
      ShipmentProposalsAcceptDialog,
      ShipmentProposalsDeclineDialog,
      ShipmentUpdateExpirationDateDialog,
      ShipmentUpdatePhoneDialog,
      ShipmentCancelDialog
    },
    data () {
      return {
        affixed: false,
        dialogs: {
          payment: false,
          expiresAt: false,
          cancellation: false,
          reference: false,
          phone: false,
          rate: {
            visible: false,
            /** @type {{ rate: any, shipment: any}|null} */
            data: null
          },
          contact: {
            visible: false,
            data: {
              direction: 'pickup',
              shipment: {}
            }
          },
          proposal_accept: {
            visible: false,
            data: {
              proposal: {},
              shipment: {}
            }
          },
          proposal_decline: {
            visible: false,
            data: {
              proposal: {},
              shipment: {}
            }
          }
        }
      }
    },
    async mounted () {
      await this.fetchShipment(this.$route.params.uuid)
      this.setAppReady(true)

      EventBus.$on('shipment:refresh', (/** @type {Function?} */ cb) => {
        this.reloadShipment(() => {
          if (cb) cb()
        })
      })

      EventBus.$on('shipment:open-dialog', (dialog) => {
        // @ts-ignore
        this.dialogs[dialog] = true
      })

      EventBus.$on('shipment:contact', ({ direction, shipment }) => {
        this.dialogs.contact = {
          visible: true,
          data: {
            direction,
            shipment
          }
        }
      })

      EventBus.$on('shipment:rate-shipment', ({ value: rate, shipment }) => {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Rating', shipment.uuid)
        }

        this.dialogs.rate = {
          visible: true,
          data: {
            shipment,
            rate
          }
        }
      })

      EventBus.$on('shipments:accept-proposal', ({ proposal, shipment }) => {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Proposal Acceptation', shipment.uuid)
        }

        this.dialogs.proposal_accept = {
          visible: true,
          data: {
            proposal,
            shipment
          }
        }
      })

      EventBus.$on('shipments:decline-proposal', ({ proposal, shipment }) => {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Proposal Refusal', shipment.uuid)
        }

        this.dialogs.proposal_decline = {
          visible: true,
          data: {
            proposal,
            shipment
          }
        }
      })

      if (this.$route.query && this.$route.query['update-expires-at']) {
        EventBus.$emit('shipment:open-dialog', 'expiresAt')
      }
    },
    beforeDestroy () {
      const events = ['shipment:rate-shipment', 'shipment:refresh', 'shipment:open-dialog', 'shipments:accept-proposal', 'shipments:decline-proposal', 'shipment:contact']
      events.forEach(event => {
        EventBus.$off(event)
      })
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters(['isAppReady']),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    },
    methods: {
      ...mapActions(['setAppReady']),
      ...mapActions('auth', [
        'retrievePaymentSources'
      ]),
      ...mapActions('shipments', [
        'retrieveShipment',
        'retrieveShipmentsMetrics',
        'setShipmentData'
      ]),
      /**
       * @function affix
       * @param {boolean} v
       */
      affix (v) {
        this.affixed = v
      },
      /**
       * @function retrieveShipmentBookings
       * @returns {Promise<any>}
       */
      retrieveShipmentBookings () {
        /**
         * Quick patch to avoid errors on Sentry. Should make a bigger
         * change to cancel any pending requests when route changes.
         * See https://trello.com/c/2FwPsIdL/865-annuler-les-requests-http-pending-si-changement-de-route
         * TODO: Cancel pending request instead of quick fix.
         */
        if (!this.getCurrentShipment) return Promise.resolve()

        this.$wait.start('loading shipment bookings')
        const { uuid } = this.getCurrentShipment
        return Shipment.bookings({
          cid: this.getCid,
          sid: uuid
        })
          .then(res => {
            this.setShipmentData({
              uuid,
              data: {
                bookings: res.data.items
              }
            })

            return res
          })
          .finally(() => this.$wait.end('loading shipment bookings'))
      },
      /**
       * @function retrieveShipmentInvoices
       * @returns {Promise<any>}
       */
      retrieveShipmentInvoices () {
        if (!this.getCurrentShipment) return Promise.resolve()

        const { uuid } = this.getCurrentShipment
        this.$wait.start('loading shipment billing')
        return Shipment.billing({
          cid: this.getCid,
          sid: uuid
        })
          .then(({ data }) => {
            this.setShipmentData({
              uuid,
              data: {
                invoices: data.invoices
              }
            })
          })
          .finally(() => this.$wait.end('loading shipment billing'))
      },
      /**
       * @function fetchShipment
       * @param {string} uuid
       * @returns {Promise<any>}
       */
      async fetchShipment (uuid) {
        this.$wait.start('fetching shipment')
        try {
          const shipmentRes = await this.retrieveShipment({
            sid: uuid
          })

          if (shipmentRes) {
            EventBus.$emit('shipment:loaded')

            await Promise.all([
              this.retrieveShipmentsMetrics(),
              this.retrieveShipmentBookings(),
              this.retrieveShipmentInvoices(),
              this.retrievePaymentSources()
            ])
          }
        } catch (error) {
          console.error('Error occured:', error)
        }
        this.$wait.end('fetching shipment')
      },
      /**
       * @function reloadShipment
       * @param {() => void} cb
       */
      reloadShipment (cb) {
        this.fetchShipment(this.$route.params.uuid)
          .finally(cb)
      }
    },
    // @ts-ignore
    async beforeRouteUpdate (to, from, next) {
      const isQueryUpdate = to.query.toString() !== from.query.toString()

      if (to.name === 'Shipment' && !!to.params.uuid && !isQueryUpdate) {
        await this.fetchShipment(to.params.uuid)
      }

      if (to.query && to.query['update-expires-at']) {
        EventBus.$emit('shipment:open-dialog', 'expiresAt')
      }

      next()
    },
    // @ts-ignore
    beforeRouteEnter (to, from, next) {
      if (!to.params.uuid) {
        next({
          name: 'Shipments',
          params: {
            state: 'available'
          }
        })
        return
      }

      if (!store.getters.isUserShipper) {
        next({
          name: 'Offers'
        })
        return
      }

      next()
    }
  })
</script>

<style lang="scss">

  .shipment {
    overflow-y: visible !important;

    &-header {
      flex: 0 0 60px;
      min-height: 55px;
      margin-bottom: 16px;

      .shipments-back-button {
        height: 55px;
      }

      &.affixed {
        display: none;
      }
    }

    &.ctk-container {
      margin-bottom: 440px;
    }

    &.ctk-container::after {
      position: absolute;
      content: '';
      bottom: 0;
      right: 0;
      background-image: url('~@/assets/img/illustrations/city.svg');
      background-size: cover;
      background-position: center;
      width: 1300px;
      height: 440px;
      opacity: 0.8;
      z-index: -1;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        display: none;
      }
    }

    .shipment-summary-wrapper {
      flex: 0 1 350px;
      width: 350px;
    }

    .shipment-detail-wrapper {
      padding-top: 71px;
      flex: 1;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &-header,
      .shipment-summary-wrapper {
        display: none;
      }

      &__content {
        flex-direction: column;
      }

      &-detail-wrapper {
        padding-top: 0 !important;
      }
    }

    .ctk-loading-layer {
      position: fixed;
      height: 100vh;
      z-index: 99;
    }
  }

</style>
