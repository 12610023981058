<template>
  <shipment-dialog
    :value="value"
    modal-class="shipment-update-reference"
    id="shipment-update-reference-dialog"
    @input="$emit('input', !value)"
  >
    <div class="shipment-update-reference__content tw-flex tw-flex-1">
      <div class="tw-flex tw-items-center tw-justify-center md:tw-10/12">
        <template
          v-if="isConfirmation"
        >
          <div
            class="tw-w-full tw-p-4 md:tw-p-8"
            data-test="confirmation-section"
          >
            <h3
              class="tw-font-normal tw-text-secondary tw-mb-6 fs-22"
              data-test="dialog-title"
              v-text="$t('shipment.titles.inform_carrier_references')"
            />
            <p
              data-test="paragraph"
              class="tw-mb-5 md:tw-w-4/5"
              v-text="$t('shipment.paragraphs.inform_carrier_references')"
            />

            <shipment-update-reference-dialog-carrier
              :carrier="carrier"
              :driver="driver"
              data-test="carrier"
            />
          </div>
        </template>

        <template
          v-else
        >
          <ValidationObserver
            ref="observer"
            slim
          >
            <form
              class="tw-w-full tw-p-4 md:tw-p-8"
              @submit.prevent="submitted"
            >
              <h3
                class="tw-font-normal tw-text-secondary tw-mb-6 fs-22"
                data-test="dialog-title"
                v-text="$t('shipment.titles.your_references')"
              />
              <p
                data-test="paragraph"
                class="tw-mb-5 md:tw-w-4/5"
                v-text="$t('shipment.paragraphs.description_reference')"
              />

              <div class="tw-flex tw-flex-col md:tw-flex-row">
                <div class="shipment-update-reference__content__side tw-relative tw-flex tw-flex-col tw-mb-3 md:tw-mb-0 md:tw-w-1/2 md:tw-mr-8">
                  <ctk-input-text
                    ref="reference"
                    id="reference-input"
                    v-model="formData.reference"
                    :label="$t('shipment.fields.references.chronotruck') | capitalize"
                    :disabled="true"
                    :readonly="true"
                    name="reference"
                    class="tw-mb-3"
                    data-test="reference-input"
                    required
                  />

                  <ValidationProvider
                    ref="shipper_reference-provider"
                    :name="$t('shipment.fields.references.order')"
                    rules="max:50"
                    slim
                  >
                    <template slot-scope="{ invalid, validated, errors }">
                      <ctk-input-text
                        ref="reference"
                        id="shipper-reference-input"
                        v-model="formData.shipper_reference"
                        :label="$t('shipment.fields.references.order') | capitalize"
                        :error="invalid && validated"
                        :hint="errors[0]"
                        :loader="$wait.is('updating references')"
                        :disabled="$wait.is('updating references')"
                        name="shipper-reference"
                        data-test="shipper-reference-input"
                        clearable
                      />
                    </template>
                  </ValidationProvider>
                </div>

                <div class="tw-flex tw-flex-col md:tw-w-1/2">
                  <ValidationProvider
                    ref="pickup_reference-provider"
                    :name="$t('shipment.fields.references.pickup')"
                    rules="max:50"
                    slim
                  >
                    <template slot-scope="{ invalid, validated, errors }">
                      <ctk-input-text
                        ref="reference"
                        id="pickup-reference-input"
                        v-model="formData.pickup_reference"
                        :label="$t('shipment.fields.references.pickup') | capitalize"
                        :error="invalid && validated"
                        :hint="errors[0]"
                        :loader="$wait.is('updating references')"
                        :disabled="$wait.is('updating references')"
                        name="pickup-reference"
                        clearable
                        class="tw-mb-3"
                        data-test="pickup-reference-input"
                      />
                    </template>
                  </ValidationProvider>
                  <ValidationProvider
                    ref="delivery_reference-provider"
                    :name="$t('shipment.fields.references.delivery')"
                    rules="max:50"
                    slim
                  >
                    <template slot-scope="{ invalid, validated, errors }">
                      <ctk-input-text
                        ref="reference"
                        id="delivery-reference-input"
                        v-model="formData.delivery_reference"
                        :label="$t('shipment.fields.references.delivery') | capitalize"
                        :error="invalid && validated"
                        :hint="errors[0]"
                        :loader="$wait.is('updating references')"
                        :disabled="$wait.is('updating references')"
                        name="delivery-reference"
                        clearable
                        data-test="delivery-reference-input"
                      />
                    </template>
                  </ValidationProvider>
                </div>
              </div>
            </form>
          </ValidationObserver>
        </template>
      </div>
      <div class="tw-hidden tw-justify-end md:tw-flex tw-pl-12 tw-pt-12 tw-pr-0 md:tw-2/12 flex-fixed">
        <picture
          class="tw-flex"
        >
          <source
            srcset="~@/assets/img/illustration_reference_dialog.webp"
            type="image/webp"
          >
          <source
            srcset="~@/assets/img/illustration_reference_dialog.svg 2x"
            type="image/svg"
          >
          <img
            src="~@/assets/img/illustration_reference_dialog.png"
            alt=""
            class="tw-select-none tw-opacity-75 tw-ml-auto tw-mt-auto"
            width="200"
            height="116"
          >
        </picture>
      </div>
    </div>

    <template #footer>
      <div
        class="tw-flex tw-flex-col-reverse 2sm:tw-flex-row tw-justify-between tw-items-center tw-w-full md:tw-px-4"
      >
        <ui-button
          type="button"
          variant="link"
          class="tw-w-full 2sm:tw-w-auto"
          data-test="close"
          @click="$emit('input', false)"
        >
          {{ $t(isConfirmation ? 'shipments.buttons.close' : 'cancel') | capitalize }}
        </ui-button>

        <ui-button
          v-if="!isConfirmation"
          :loading="$wait.is('updating references')"
          :disabled="$wait.is('updating references')"
          variant="primary"
          class="tw-w-full 2sm:tw-w-auto tw-mb-3 2sm:tw-mb-0"
          data-test="validate"
          @click="submitted"
        >
          {{ $t('validate') | capitalize }}
        </ui-button>
      </div>
    </template>
  </shipment-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import { Shipment } from '@/resources'
  import { showToaster } from '@/services/Toaster'

  import CtkInputText from '@/components/CtkInputs/CtkInputText'
  import ShipmentDialog from '@/views/Shippers/components/ShipmentDialog'
  import ShipmentUpdateReferenceDialogCarrier from './_subs/ShipmentUpdateReferenceDialogCarrier'

  /**
   * @module component - ShipmentUpdateReferenceDialog
   */
  export default {
    name: 'ShipmentUpdateReferenceDialog',
    components: {
      ShipmentDialog,
      CtkInputText,
      ShipmentUpdateReferenceDialogCarrier
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        isConfirmation: false,
        formData: {
          reference: null,
          shipper_reference: null,
          pickup_reference: null,
          delivery_reference: null
        }
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      carrier () {
        const { mission } = this.shipment
        return !!mission && mission.driver && mission.driver.carrier
      },
      driver () {
        const { mission } = this.shipment
        return !!mission && mission.driver
      }
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentData'
      ]),
      submitted () {
        const { uuid, pickup, delivery, mission } = this.shipment

        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Confirmed Reference Change', uuid)
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid || this.$wait.is('updating references')) return

            this.$wait.start('updating references')

            const {
              shipper_reference: shipperReference,
              pickup_reference: pickupReference,
              delivery_reference: delvieryReference
            } = this.formData

            Shipment.references({
              cid: this.getCid,
              sid: uuid
            }, {
              shipper_reference: shipperReference || null,
              pickup_reference: pickupReference || null,
              delivery_reference: delvieryReference || null
            })
              .then(() => {
                this.setShipmentData({
                  uuid,
                  data: {
                    shipper_reference: shipperReference,
                    pickup: {
                      ...pickup,
                      reference: pickupReference
                    },
                    delivery: {
                      ...delivery,
                      reference: delvieryReference
                    }
                  }
                })

                showToaster(this, this.$t('shipment.paragraphs.references_updated'), {
                  type: 'success',
                  position: 'bottom-right'
                })

                const isPalletNetwork = this.shipment.price_type === 'pallet_network'
                const hasCarrier = !!mission && !!mission.driver
                if (hasCarrier && !isPalletNetwork) {
                  this.isConfirmation = true
                } else {
                  this.$emit('input', false)
                }
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  const errorMessage = data.error.detail || data.error.title

                  /**
                   * Show the violation message in the input
                   */
                  if (data.error.violations) {
                    data.error.violations.forEach(violation => {
                      this.$refs[`${violation.property_path}-provider`].setErrors([
                        violation.message
                      ])
                    })
                  }

                  showToaster(this, errorMessage, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => this.$wait.end('updating references'))
          })
      }
    },
    watch: {
      value (val) {
        if (val) {
          this.isConfirmation = false

          const {
            reference,
            shipper_reference: shipperReference,
            pickup,
            delivery
          } = this.shipment

          this.formData = {
            reference,
            shipper_reference: shipperReference || null,
            pickup_reference: pickup.reference || null,
            delivery_reference: delivery.reference || null
          }
        }
      }
    }
  }
</script>

<style lang="scss">

  .shipment-update-reference {
    &__content__side {
      @media only screen and (min-width: $breakpoint-tablet) {
        &::after {
          position: absolute;
          content: '';
          width: 1px;
          height: 100%;
          background-color: #F5F5F5;
          right: -1rem;
          top: 0;
        }
      }
    }
  }

</style>
