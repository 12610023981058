<template>
  <div class="shipment-direction-detail-reference">
    <div
      v-text="$t('shipment.labels.reference')"
      data-test="label"
      class="tw-text-gray-700"
    />
    <div
      class="tw-flex tw-flex-col md:tw-flex-row"
    >
      <p
        v-text="reference"
        data-test="value"
        class="tw-break-words tw-whitespace-pre-line tw-mb-1 md:tw-mb-4 md:tw-mr-4"
      />
      <a
        v-if="hasEdit"
        href="#"
        class="fs-13 tw-text-blue-500 tw-italic hover:tw-text-blue-600"
        v-text="$options.filters.capitalize($t('edit'))"
        @click.prevent="edit"
        data-test="edit"
      />
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { EventBus } from '@/services/EventBus'

  /**
   * @module component - ShipmentDirectionDetailReference
   * @param {object} reference
   * @param {boolean} hasEdit
   */
  export default {
    name: 'ShipmentDirectionDetailReference',
    props: {
      reference: {
        type: String,
        required: true
      },
      hasEdit: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    },
    methods: {
      edit () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Initiated Reference Change', this.getCurrentShipment.uuid)
        }

        EventBus.$emit('shipment:open-dialog', 'reference')
      }
    }
  }
</script>
