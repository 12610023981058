<template>
  <div class="shipment-detail">
    <shipment-detail-status class="tw-mb-4" />
    <div class="shipment-detail__navigation-wrapper">
      <affix
        relative-element-selector=".shipment-detail__content"
        :offset="{
          top: computeTop,
          bottom: 0
        }"
        @affixtop="$emit('affix', false)"
        @affix="$emit('affix', true)"
      >
        <shipment-navigation
          :has-back="hasBack"
          :has-billing="hasBilling"
        />
      </affix>
    </div>
    <div class="shipment-detail__content">
      <shipment-proposals
        v-if="getCurrentShipment.state === 'available' && !isCorridor"
        class="tw-mb-4"
      />
      <shipment-carrier-detail
        v-if="getCurrentShipment.mission"
        class="tw-mb-4"
      />
      <shipment-references-detail
        class="tw-mb-4"
      />
      <shipment-price-detail class="tw-mb-4" />
      <shipment-billing-detail
        v-if="hasBilling"
        class="tw-mb-4"
      />
      <shipment-transport-detail class="tw-mb-4" />
      <shipment-load-detail />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'
  import { mapGetters, mapState } from 'vuex'

  import ShipmentCarrierDetail from './_subs/ShipmentCarrierDetail/index.vue'
  import ShipmentDetailStatus from './_subs/ShipmentDetailStatus/index.vue'
  import ShipmentReferencesDetail from './_subs/ShipmentReferencesDetail/index.vue'
  import ShipmentPriceDetail from './_subs/ShipmentPriceDetail/index.vue'
  import ShipmentBillingDetail from './_subs/ShipmentBillingDetail/index.vue'
  import ShipmentTransportDetail from './_subs/ShipmentTransportDetail/index.vue'
  import ShipmentLoadDetail from './_subs/ShipmentLoadDetail/index.vue'
  import ShipmentProposals from './_subs/ShipmentProposals/index.vue'
  import ShipmentNavigation from '@/views/Shippers/Shipment/components/ShipmentNavigation/index.vue'

  /**
   * @module component - ShipmentDetail
   */
  export default defineComponent({
    name: 'ShipmentDetail',
    components: {
      ShipmentCarrierDetail,
      ShipmentDetailStatus,
      ShipmentLoadDetail,
      ShipmentReferencesDetail,
      ShipmentBillingDetail,
      ShipmentTransportDetail,
      ShipmentNavigation,
      ShipmentProposals,
      ShipmentPriceDetail
    },
    props: {
      hasBack: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      ...mapState('ui', {
        bannersHeight: 'bannersHeight'
      }),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      ...mapGetters('auth', [
        'isAccountLocked'
      ]),
      ...mapGetters('ui', [
        'isImpersonateVisible'
      ]),
      /**
       * Returns a computed top according to the impersonate & the locked navbars
       * that are upper the main header.
       * @function computeTop
       * @returns {number}
       */
      computeTop () {
        /** @var {number} - By default, the affix must have the height of the affix container */
        const HEADER_HEIGTH = 55
        const IMPERSONATE_HEIGHT = 50
        const BASE_AFFIX_HEIGHT = 55

        let top = HEADER_HEIGTH + BASE_AFFIX_HEIGHT
        if (this.isImpersonateVisible) {
          top += IMPERSONATE_HEIGHT
        }

        // @ts-ignore
        top += this.bannersHeight

        return top
      },
      /**
       * @function isCorridor
       * @returns {boolean}
       */
      isCorridor () {
        return this.getCurrentShipment.price_type === 'corridor'
      },
      /**
       * Returns true if the billing block should be visible
       * @function hasBilling
       * @returns {boolean}
       */
      hasBilling () {
        const { state, billing } = this.getCurrentShipment
        const plannedStates = ['planned']
        const finishedStates = ['finished', 'proof_of_delivery_available']
        const inProgressStates = ['started', 'transit', 'near_delivery', 'delivered']
        const cancelledStates = ['expired', 'cancelled']

        if (plannedStates.includes(state) || inProgressStates.includes(state) || cancelledStates.includes(state) || finishedStates.includes(state)) {
          return billing && billing.invoice_counts.total > 0
        }

        return false
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-detail {
    .vue-affix {
      z-index: 1;
      width: 100%;
      left: 0;
    }

    &__navigation-wrapper {
      height: 55px;
    }

    &__content {
      section:first-child {
        border-radius: 0 0 4px 4px;
        border-top: none;
      }
    }
  }

</style>
