<template>
  <section
    id="proposals-section"
    class="shipment-proposals p-5 tw-bg-white border-gray tw-rounded"
    :class="{
      'is-loading': $wait.is('fetching shipment proposals')
    }"
  >
    <shipment-proposals-skeleton
      v-if="$wait.is('fetching shipment proposals')"
      data-test="skeleton"
    />
    <template
      v-else
    >
      <h4
        class="mb-5"
      >
        {{ $t('shipment.titles.proposals') }}
      </h4>

      <shipment-proposals-list />
    </template>
  </section>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import ShipmentProposalsList from './_subs/ShipmentProposalsList'
  import ShipmentProposalsSkeleton from './_subs/ShipmentProposalsSkeleton'

  /**
   * @module component - shipmentProposals
   */
  export default {
    name: 'ShipmentProposals',
    components: {
      ShipmentProposalsSkeleton,
      ShipmentProposalsList
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    },
    mounted () {
      this.fetchProposals()
    },
    methods: {
      ...mapActions('shipments', [
        'retrieveAllShipmentProposals'
      ]),
      /**
       * @function fetchProposals
       */
      fetchProposals () {
        this.$wait.start('fetching shipment proposals')
        this.retrieveAllShipmentProposals({
          uuid: this.getCurrentShipment.uuid
        })
          .catch(() => {})
          .finally(() => this.$wait.end('fetching shipment proposals'))
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-proposals {
    position: relative;
    border: 1px solid $divider;

    @media only screen and (max-width: $breakpoint-laptop-s) {
      padding: 32px !important;
    }

    @media only screen and (max-width: $breakpoint-mobile-l) {
      padding: 16px !important;
    }

    .ctk-loading-layer {
      position: absolute;
      height: 100%;
      z-index: 0;
    }

    &.is-loading {
      min-height: 300px;
    }
  }

</style>
