<template>
  <div class="shipment-update-reference-dialog-carrier tw-flex tw-flex-col md:tw-flex-row tw-rounded-sm tw-text-sm tw-px-5 tw-py-6 tw-relative">
    <shipment-carrier-item
      :title="$t('shipment.labels.your_carrier')"
      :name="carrier.name"
      :phone="carrier.phone"
      class="shipment-update-reference-dialog-carrier__side tw-relative tw-mb-4 md:tw-mb-0 md:tw-w-1/2 md:tw-mr-5"
      data-test="carrier"
    />
    <shipment-carrier-item
      :title="$t('shipment.labels.your_driver')"
      :name="`${driver.first_name} ${driver.last_name}`"
      :phone="driver.phone"
      class="md:tw-w-1/2 md:tw-ml-5"
      data-test="driver"
    />
  </div>
</template>

<script>
  import ShipmentCarrierItem from '@/views/Shippers/Shipment/components/ShipmentCarrierItem'

  /**
   * @module component - ShipmentUpdateReferenceDialogCarrier
   * @param {object} carrier
   * @param {object} driver
   */
  export default {
    name: 'ShipmentUpdateReferenceDialogCarrier',
    components: {
      ShipmentCarrierItem
    },
    props: {
      carrier: {
        type: Object,
        required: true
      },
      driver: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-update-reference-dialog-carrier {
    background-color: $light-gray;

    @media only screen and (min-width: $breakpoint-tablet) {
      margin-top: 65px;

      &__side::after,
      &::after {
        position: absolute;
        content: '';
      }

      &__side::after {
        right: -1.25rem;
        height: 100%;
        width: 1px;
        background-color: $divider;
      }

      &::after {
        width: 198px;
        height: 65px;
        top: -49px;
        left: 3.5rem;
        margin: auto;
        background-image: url('~@/assets/img/illustrations/carrier.svg');
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.8;
      }
    }
  }

</style>
