<template>
  <div class="shipment-bookings">
    <h4 class="shipment-bookings__title fs-16">
      {{ $t('shipment.titles.bookings') }}
    </h4>
    <div class="shipment-bookings__list">
      <shipment-bookings-item
        v-for="booking in bookings"
        :key="booking.uuid"
        :booking="booking"
      />
    </div>
  </div>
</template>

<script>
  import ShipmentBookingsItem from './_subs/ShipmentBookingsItem'

  /**
   * @module component - shipment-bookings
   * @param {Array} bookings
   */
  export default {
    name: 'ShipmentBookings',
    components: {
      ShipmentBookingsItem
    },
    props: {
      bookings: {
        type: Array,
        default: null
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-bookings {
    .shipment-bookings-item {
      border-top: 2px solid #F5F5F5;
    }
  }

</style>
