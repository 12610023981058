<template>
  <div class="shipment-proposals-skeleton">
    <div class="shipment-proposals-skeleton__header tw-flex mb-3">
      <ctk-skeleton-block
        background="#2EBCE8"
        width="40px"
        height="40px"
        level="1"
        loading
        class="mr-2"
      />
      <ctk-skeleton-block
        width="210px"
        height="40px"
        level="2"
        loading
      />
    </div>
    <ctk-skeleton-view
      v-for="(_, k) in Array.from(new Array(2))"
      :key="k"
      :depth="k + 1"
      class="shipment-proposals-skeleton__item p-3 mb-3"
    >
      <ctk-skeleton-block
        width="180px"
        height="30px"
        level="2"
        loading
        class="mb-3"
      />
      <div class="tw-flex">
        <div class="tw-flex-1">
          <ctk-skeleton-block
            width="100px"
            height="25px"
            level="2"
            loading
            class="mb-2"
          />
          <ctk-skeleton-block
            width="80px"
            height="20px"
            level="1"
            loading
            class="mb-2"
          />
          <ctk-skeleton-block
            width="100px"
            height="25px"
            level="2"
            loading
            class="mb-2"
          />
          <ctk-skeleton-block
            width="80px"
            height="20px"
            level="1"
            loading
            class="mb-2"
          />
        </div>
        <div class="tw-flex-1">
          <ctk-skeleton-block
            width="100px"
            height="25px"
            level="2"
            loading
            class="mb-2"
          />
          <ctk-skeleton-block
            width="80px"
            height="20px"
            level="1"
            loading
            class="mb-2"
          />
          <ctk-skeleton-block
            width="100px"
            height="25px"
            level="2"
            loading
            class="mb-2"
          />
          <ctk-skeleton-block
            width="180px"
            height="45px"
            level="1"
            loading
            class="mb-2"
          />
        </div>
      </div>
    </ctk-skeleton-view>
  </div>
</template>

<script>
  import CtkSkeletonView from '@/components/CtkSkeletonView'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock'

  /**
   * @module component - ShipmentProposalsSkeleton
   */
  export default {
    name: 'ShipmentProposalsSkeleton',
    components: {
      CtkSkeletonView,
      CtkSkeletonBlock
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-proposals-skeleton {
    &__item {
      background-color: #F9F9F9;
    }
  }

</style>
