<template>
  <shipment-dialog
    :value="value"
    modal-class="shipment-update-phone-dialog"
    id="shipment-update-phone-dialog"
    @input="$emit('input', !value)"
  >
    <div class="shipment-update-phone-dialog__content tw-flex tw-flex-1">
      <div class="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-flex-1">
        <ValidationObserver
          ref="observer"
          slim
        >
          <form
            class="tw-w-full tw-py-8 tw-px-4 md:tw-px-8"
            data-test="form"
            :disabled="$wait.is('updating shipment phone')"
            @submit.prevent="submitted"
          >
            <h3
              class="tw-font-normal tw-mb-5 tw-text-2xl"
              data-test="dialog-title"
              v-text="$t('shipment.labels.contact')"
            />
            <p
              data-test="paragraph"
              class="tw-mb-5"
              v-text="$t('shipment.paragraphs.contact')"
            />
            <ValidationProvider
              ref="phone-provider"
              :name="$t('phone')"
              :rules="`telephone:${country}|required`"
              slim
            >
              <template slot-scope="{ invalid, validated, errors }">
                <ctk-phone-number
                  id="phone"
                  v-model="phone"
                  :country.sync="country"
                  :error="invalid && validated"
                  :hint="errors[0]"
                  :disabled="$wait.is('updating shipment phone')"
                  name="phone"
                  autocomplete="tel-national"
                  required
                />
              </template>
            </ValidationProvider>
          </form>
        </ValidationObserver>
      </div>
      <div class="tw-relative tw-pl-8 tw-pt-8 tw-pr-0 illustration-container tw-flex-1">
        <picture>
          <source
            srcset="~@/assets/img/illustration_reference_dialog.webp"
            type="image/webp"
          >
          <source
            srcset="~@/assets/img/illustration_reference_dialog.svg 2x"
            type="image/svg"
          >
          <img
            src="~@/assets/img/illustration_reference_dialog.png"
            class="tw-max-w-full"
            alt=""
          >
        </picture>
      </div>
    </div>

    <template #footer>
      <div
        class="tw-flex tw-flex-col-reverse md:tw-flex-row md:tw-justify-between md:tw-items-center tw-w-full md:tw-px-4"
      >
        <ui-button
          type="button"
          variant="link"
          class="shipment-update-phone-dialog-dialog__footer__cancel tw-mt-4 md:tw-mt-0"
          @click="$emit('input', false)"
        >
          {{ $t('shipments.buttons.close') }}
        </ui-button>

        <ui-button
          :disabled="$wait.is('updating shipment phone')"
          :loading="$wait.is('updating shipment phone')"
          variant="primary"
          class="shipment-update-phone-dialog-dialog__footer__valid"
          data-test="validate"
          @click="submitted"
        >
          {{ $t('validate') | capitalize }}
        </ui-button>
      </div>
    </template>
  </shipment-dialog>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import { parsePhoneNumberFromString } from 'libphonenumber-js'

  import CtkPhoneNumber from '@/components/CtkPhoneNumber'
  import { Shipment } from '@/resources'
  import { showToaster } from '@/services/Toaster'

  import ShipmentDialog from '@/views/Shippers/components/ShipmentDialog'

  /**
   * @module component - shipmentUpdatePhoneDialog
   */
  export default {
    name: 'ShipmentUpdatePhoneDialog',
    components: {
      ShipmentDialog,
      CtkPhoneNumber
    },
    props: {
      value: {
        type: Boolean,
        required: true
      },
      shipment: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        phone: '',
        country: null
      }
    },
    mounted () {
      this.resetForm()
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ])
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentData'
      ]),
      resetForm () {
        const parsedPhone = parsePhoneNumberFromString(this.shipment.shipper_phone_number)

        if (parsedPhone) {
          this.phone = parsedPhone.nationalNumber
          this.country = parsedPhone.country
        } else {
          this.phone = this.shipment.shipper_phone_number || ''
          this.country = 'FR'
        }
      },
      submitted () {
        if (this.$matomo) {
          this.$matomo.trackEvent('Shipments', 'Confirmed Phone Number Change', this.shipment.uuid)
        }

        this.$refs.observer.validate()
          .then(valid => {
            if (!valid || this.$wait.is('updating shipment phone')) return
            this.$wait.start('updating shipment phone')

            const { uuid } = this.shipment
            const parsedPhone = parsePhoneNumberFromString(this.phone, this.country)
            const phone = parsedPhone
              ? parsedPhone.number
              : this.phone

            Shipment.phone({
              cid: this.getCid,
              sid: uuid
            }, {
              phone
            })
              .then(() => {
                this.$wait.end('updating shipment phone')
                this.$emit('input', false)
                this.setShipmentData({
                  uuid,
                  data: {
                    shipper_phone_number: phone
                  }
                })
                showToaster(
                  this,
                  this.$t('shipment.paragraphs.phone_updated'), {
                    type: 'success',
                    position: 'bottom-right'
                  }
                )
              })
              .catch(err => {
                if (!err.response) return

                const { data } = err.response
                if (data && data.error) {
                  /**
                   * Show the violation message in the input
                   */
                  if (data.error.violations) {
                    const violation = data.error.violations[0]
                    this.$refs['phone-provider'].setErrors([violation.message])
                  }

                  const errorMessage = data.error.detail || data.error.title
                  showToaster(this, errorMessage, {
                    type: 'error',
                    position: 'bottom-right'
                  })
                }
              })
              .finally(() => this.$wait.end('updating shipment phone'))
          })
      }
    },
    watch: {
      value (val) {
        if (!val) return
        this.resetForm()
        this.$nextTick(() => {
          const input = document.querySelector('#phone_phone_number')
          if (input) input.select()
        })
      }
    }
  }
</script>

<style lang="scss">

  .shipment-update-phone-dialog {
    .illustration-container {
      position: relative;

      img {
        opacity: 0.8;
      }
    }

    @media screen and (max-width: $breakpoint-tablet) {
      .illustration-container {
        display: none;
      }
    }
  }

</style>
