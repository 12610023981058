<template>
  <div class="shipment-not-found">
    <div class="shipment-not-found__illustration">
      <img
        src="@/assets/img/illustrations/not-found.svg"
        class="tw-w-full tw-select-none tw-object-cover"
        height="440"
        alt=""
      >
    </div>
    <div class="tw-flex tw-flex-col tw-items-center tw-text-center shipment-not-found__content tw-container tw-mx-auto tw-px-2 tw-pb-4">
      <h1 class="shipment-not-found__content__title tw-text-3xl tw-font-normal tw-mb-4">
        {{ $t('shipment.titles.not_found') }}
      </h1>
      <p class="tw-mb-4 tw-mx-auto">
        {{ $t('shipment.paragraphs.not_found') }}
      </p>
      <ui-button
        :to="{
          name: 'Shipments',
          params: {
            state: 'planned'
          }
        }"
        class="tw-mx-auto"
        variant="primary"
        data-test="view-all"
      >
        {{ $t('shipment.buttons.view_all_shipments') }}
      </ui-button>
    </div>
  </div>
</template>

<script>
  /**
   * @module view - shipmentNotFound
   */
  export default {
    name: 'ShipmentNotFound'
  }
</script>

<style lang="scss" scoped>

  .shipment-not-found {
    &__illustration {
      img {
        @media only screen and (max-width: $breakpoint-mobile-l) {
          max-height: 200px;
        }
      }
    }

    &__content {
      p,
      .btn {
        max-width: 400px;
      }

      .btn {
        min-height: 35px;
        line-height: 35px;
      }
    }
  }

</style>
