<template>
  <div
    :class="{
      'is-pickup': isPickup,
      'is-delivery': !isPickup && !isDelivered,
      'is-delivered': isDelivered
    }"
    class="shipment-in-progress-content fs-16 tw-font-medium"
  >
    <div class="shipment-in-progress-content__wrapper">
      <p
        v-text="$t(isPickup
          ? 'shipment.paragraphs.in_progress_shipment_pickup'
          : isDelivered
            ? 'shipment.paragraphs.in_progress_shipment_delivered'
            : 'shipment.paragraphs.in_progress_shipment_delivery')"
        data-test="status"
      />
      <i18n
        :path="isPickup
          ? (isFlexible
            ? 'shipment.paragraphs.in_progress_pickup_driver_handling_soon.flexible'
            : 'shipment.paragraphs.in_progress_pickup_driver_handling_soon.fixe')
          : isDelivered
            ? 'shipment.paragraphs.in_progress_delivered_carrier'
            : (isFlexible
              ? 'shipment.paragraphs.in_progress_delivery_driver_handling_soon.flexible'
              : 'shipment.paragraphs.in_progress_delivery_driver_handling_soon.fixe')"
        data-test="date"
        tag="p"
        class="mb-0"
      >
        <template #date>
          <span>{{ date.date | moment('L') }}</span>
        </template>
        <template #startTime>
          <span
            data-test="start-time"
          >{{ date.startTime | moment('LT') }}</span>
        </template>
        <template #endTime>
          <span
            data-test="end-time"
          >{{ date.endTime | moment('LT') }}</span>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  /**
   * Textual status content when the shipment is in progress
   * @module component - shipmentInProgressContent
   */
  export default {
    name: 'ShipmentInProgressContent',
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * Returns true if there is a mission
       * @function hasMission
       */
      hasMission () {
        return !!this.getCurrentShipment.mission
      },
      /**
       * Returns true if the pickup is comming
       * @function isPickup
       * @returns {boolean}
       */
      isPickup () {
        const { state } = this.getCurrentShipment.pickup

        const states = ['pending', 'late']
        return states.includes(state)
      },
      /**
       * Returns true if the shipment has been delivered
       * @function isDelivered
       * @returns {boolean}
       */
      isDelivered () {
        const { pickup, delivery } = this.getCurrentShipment

        return pickup.state === 'completed' && delivery.state === 'completed'
      },
      timeslot () {
        const direction = this.hasMission
          ? this.getCurrentShipment.mission[this.isPickup ? 'pickup' : 'delivery']
          : this.getCurrentShipment[this.isPickup ? 'pickup' : 'delivery']

        return direction.time_slot
      },
      /**
       * @function date
       */
      date () {
        const { date, start_time: startTime, end_time: endTime } = this.timeslot

        return {
          date: this.$moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm'),
          startTime: this.$moment(`${date} ${startTime}`, 'YYYY-MM-DD HH:mm'),
          endTime: this.$moment(`${date} ${endTime}`, 'YYYY-MM-DD HH:mm')
        }
      },
      /**
       * Returns true if the dates (either pickup or delivery) is flexible
       * @function isFlexible
       * @returns {boolean}
       */
      isFlexible () {
        return this.timeslot.flexible
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-in-progress-content {
    &::after {
      position: absolute;
      content: '';
      background-size: contain;
      background-repeat: no-repeat;
      background-position: bottom right;
      bottom: 0;
      right: 32px;
      width: 380px;
      max-width: 100%;
      min-height: 150px;
    }

    &.is-pickup::after {
      background-image: url('~@/assets/img/illustrations/tracking-pickup.svg');
    }

    &.is-delivery::after {
      background-image: url('~@/assets/img/illustrations/tracking-delivery.svg');
    }

    &.is-delivered::after {
      background-image: url('~@/assets/img/illustrations/tracking-delivered.svg');
    }

    &__wrapper {
      width: 50%;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      &__wrapper {
        width: 100%;
      }

      &::after {
        display: none;
      }
    }
  }

</style>
