<template>
  <div class="shipment-proposal-accept-dialog-payment-view">
    <shipment-payment-confirmation-view-close-button
      :disabled="$wait.is(`accepting proposal ${proposal.uuid}`)"
      data-test="close-button"
      @close="close"
    />

    <div class="tw-flex tw-flex-col md:tw-flex-row shipment-proposal-accept-dialog-payment-view__body">
      <div
        class="shipment-proposal-accept-dialog-payment-view__aside tw-relative tw-w-full tw-flex tw-flex-col tw-p-4 md:tw-p-8"
      >
        <h2
          class="shipment-proposal-accept-dialog-payment-view__aside__title tw-relative tw-font-medium tw-text-2xl tw-leading-none tw-pr-6 md:tw-pr-0"
          data-test="title"
          v-text="$t('app.titles.pay')"
        />

        <shipment-summary-card
          :shipment="shipment"
          :has-load="true"
          :has-price="false"
          data-test="summary"
        />

        <div
          class="tw-mt-4"
          data-test="reassurances"
        >
          <new-shipment-acceptation-dialog-reassurance
            v-for="(reassurance, k) in reassurances"
            :key="k"
            :icon="reassurance.icon"
            :title="$t(reassurance.title)"
            :content="$t(reassurance.content)"
            :highlight="reassurance.highlight"
            :data-test="`reassurance-${k}`"
            class="tw-mb-6"
          />
        </div>
      </div>
      <div class="shipment-proposal-accept-dialog-payment-view__main">
        <shipment-payment-view
          :stripe="stripe"
          :loader="`accepting proposal ${proposal.uuid}`"
          :amount-label="$t('shipment.labels.pay_amount')"
          :amount="proposal.vat_included_shipper_price"
          :button-label="'app.buttons.pay_amount'"
          :initial-amount="shipment.offer_price.vat_included_price"
          :currency="'EUR'"
          :has-notice="shipment.billing && shipment.billing.payment_plan === 'immediate_cc_authorization'"
          :notice="$t('shipment.paragraphs.proposal_payment_notice')"
          :expired-label="$t('shipment.labels.proposal_expired')"
          :expires-at="expiresAt"
          data-test="payment-view"
          @submitted="submitted"
          @renew="$emit('renew')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ShipmentSummaryCard from '@/views/Shippers/Shipment/_subs/ShipmentSummary/_subs/ShipmentSummaryCard/index.vue'
  import ShipmentPaymentView from '@/views/Shippers/components/ShipmentPaymentView/index.vue'
  import NewShipmentAcceptationDialogReassurance from '@/views/Shippers/NewShipment/_subs/NewShipmentInformations/components/NewShipmentAcceptationDialog/_subs/NewShipmentAcceptationDialogReassurance/index.vue'
  import ShipmentPaymentConfirmationViewCloseButton from '@/views/Shippers/components/ShipmentPaymentConfirmationView/_subs/ShipmentPaymentConfirmationViewCloseButton/index.vue'

  /**
   * @module component - ShipmentProposalsAcceptDialogPaymentView
   * @param {object} [shipment=null] - The new shipment
   * @emits close
   */
  export default {
    name: 'ShipmentProposalsAcceptDialogPaymentView',
    props: {
      stripe: {
        type: Object,
        default: null
      },
      shipment: {
        type: Object,
        default: null
      },
      proposal: {
        type: Object,
        default: null
      }
    },
    components: {
      ShipmentSummaryCard,
      ShipmentPaymentView,
      ShipmentPaymentConfirmationViewCloseButton,
      NewShipmentAcceptationDialogReassurance
    },
    computed: {
      /**
       * @function reassurances
       * @returns {object}
       */
      reassurances () {
        return {
          'cancel-without-fee': {
            icon: require('@/assets/img/icons/quotation/cancel.svg'),
            title: 'app.titles.cancel-without-fee',
            content: 'app.paragraphs.cancel-without-fee'
          }
        }
      },
      /**
       * @function expiresAt
       */
      expiresAt () {
        return this.proposal.expires_at
      }
    },
    methods: {
      /**
       * @function close
       */
      close () {
        this.$emit('close')
      },
      /**
       * Called whenever the form is submitted
       * @function submitted
       */
      submitted (v) {
        this.$emit('submitted', v)
      }
    }
  }
</script>

<style lang="scss">
.shipment-proposal-accept-dialog-payment-view .shipment-summary-card {
  margin-top: 0px;
}
.shipment-proposal-accept-dialog-payment-view .new-shipment-acceptation-dialog-reassurance {
  padding-left: 0px;
  padding-right: 0px;
}
@media only screen and (min-width: $breakpoint-tablet) {
  .shipment-proposal-accept-dialog-payment-view__aside {
    width: 45%;
  }
  .shipment-proposal-accept-dialog-payment-view__main {
    width: 55%;
  }
}
.shipment-proposal-accept-dialog-payment-view__aside {
  background-color: $light-gray;
  border-radius: 0.3rem;
}
.shipment-proposal-accept-dialog-payment-view__aside__title {
  margin-bottom: 3.5rem;
}
.shipment-proposal-accept-dialog-payment-view__aside__title::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: -0.75rem;
  width: 190px;
  height: 1px;
  background-color: $secondary-text;
}
.shipment-proposal-accept-dialog-payment-view__main__checkbox {
  color: $secondary-text;
}
@media only screen and (max-width: $breakpoint-tablet) {
  .shipment-proposal-accept-dialog-payment-view__aside::after {
    display: none;
  }
}
.shipment-proposal-accept-dialog-payment-view .modal-wrapper {
  justify-content: unset;
  align-items: unset;
}
.shipment-proposal-accept-dialog-payment-view .modal-container {
  max-height: initial;
  margin: auto !important;
  width: 100%;
}
</style>
