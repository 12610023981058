<template>
  <div class="shipment-direction-detail tw-flex tw-flex-col lg:tw-flex-row">
    <div class="tw-w-full lg:tw-w-1/2 mr-3 tw-min-w-0">
      <shipment-detail-address
        :address="shipment[direction].address"
        :direction="direction"
        class="tw-mb-5"
      />
      <shipment-detail-contact
        v-if="hasContact"
        :contact="contact"
        :direction="direction"
        :shipment="shipment"
        :has-edit="hasEdition"
        data-test="contact"
      />
    </div>
    <div class="tw-w-full flex-fixed lg:tw-w-1/2 ml-3">
      <ctk-time-slot
        :item="hasMission ? shipment.mission : shipment"
        :direction="direction"
        text-class="text-secondary"
        class="mb-3"
        dark
      />
      <ctk-handling-infos
        :item="hasMission ? shipment.mission : shipment"
        :direction="direction"
        text-class="text-secondary"
        dark
      />
      <shipment-direction-detail-reference
        v-if="hasReference"
        :reference="reference"
        :has-edit="hasEdition"
        data-test="reference"
      />
      <ctk-comment
        v-if="comment"
        :comment="comment"
        :truncate-length="100"
        text-class="text-secondary"
      />
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  import CtkTimeSlot from '@/components/CtkTimeSlot/index.vue'
  import CtkHandlingInfos from '@/components/CtkHandlingInfos/index.vue'
  import CtkComment from '@/components/CtkComment/index.vue'
  import ShipmentDetailAddress from './_subs/ShipmentDetailAddress/index.vue'
  import ShipmentDirectionDetailReference from './_subs/ShipmentDirectionDetailReference/index.vue'
  import ShipmentDetailContact from './_subs/ShipmentDetailContact/index.vue'

  export default defineComponent({
    name: 'ShipmentDirectionDetail',
    components: {
      CtkTimeSlot,
      CtkHandlingInfos,
      CtkComment,
      ShipmentDetailAddress,
      ShipmentDirectionDetailReference,
      ShipmentDetailContact
    },
    props: {
      shipment: {
        type: Object,
        required: true
      },
      direction: {
        type: String,
        required: true
      }
    },
    computed: {
      /**
       * Returns true if there is a mission
       * @function hasMission
       * @returns {boolean}
       */
      hasMission () {
        return !!this.shipment.mission
      },
      /**
       * Returns the contact informations from the address
       * @function contact
       * @returns {{
       *  name: string?,
       *  phone: string?
       * }}
       */
      contact () {
        return this.shipment[this.direction].contact
      },
      /**
       * Returns true if the step has a reference
       * @function hasReference
       * @returns {boolean}
       */
      hasReference () {
        return !!this.reference
      },
      /**
       * Returns true if the contact block should be shown.
       * @function hasContact
       * @returns {boolean}
       */
      hasContact () {
        const isCancelled = !this.hasEdition
        const hasContact = this.contact && (!!this.contact.name || !!this.contact.phone)

        const isCancelledButHasContact = isCancelled && hasContact
        const isNotCancelledButHasNoContact = !isCancelled && !hasContact
        const isNotCancelledButHasContact = !isCancelled && hasContact

        return isCancelledButHasContact || isNotCancelledButHasNoContact || isNotCancelledButHasContact
      },
      /**
       * Returns true if the shipper is allowed to edit the contact & references
       * @function hasEdition
       * @returns {boolean}
       */
      hasEdition () {
        const { actions } = this.shipment

        return actions && !!actions.can_update_reference
      },
      /**
       * Returns the comment of either the shipment or the mission
       * @function reference
       * @returns {string|null}
       */
      reference () {
        return this.shipment[this.direction].reference
      },
      /**
       * Returns the comment of either the shipment or the mission
       * @function comment
       * @returns {string|null}
       */
      comment () {
        return this.hasMission
          ? this.shipment.mission[this.direction].comment
          : this.shipment[this.direction].comment
      }
    }
  })
</script>

<style lang="scss">

  .shipment-direction-detail {
    .ctk-time-slot {
      max-width: 320px;
    }

    .ctk-time-slot.dark .ctk-time-slot__container {
      background-color: $light-gray !important;
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      & > div:nth-child(2) {
        margin-left: 0 !important;
        margin-top: 16px;
      }
    }
  }

</style>
