<template>
  <div class="shipment-proposals-decline-dialog-success">
    <div class="shipment-proposals-decline-dialog-success__content tw-flex-1">
      <p
        data-test="thanks"
      >
        {{ $t('shipments.paragraphs.proposals.thanks') }}
      </p>
      <p
        data-test="declined"
      >
        {{ $t('shipments.paragraphs.proposals.declined') }}
      </p>
    </div>
    <div class="tw-flex-1" />
  </div>
</template>

<script>
  /**
   * @module component - shipmentProposalsDeclineDialogSuccess
   * @param {object} shipment
   * @param {object} proposal
   */
  export default {
    name: 'ShipmentProposalsDeclineDialogSuccess',
    props: {
      shipment: {
        type: Object,
        required: true
      },
      proposal: {
        type: Object,
        required: true
      }
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-proposals-decline-dialog-success {
    min-height: 200px;

    &::after {
      position: absolute;
      content: ' ';
      bottom: 0;
      right: 0;
      min-width: 400px;
      min-height: 230px;
      background-image: url('~@/assets/img/illustrations/proposal-refuse.svg');
      background-size: cover;
      opacity: 0.8;
    }

    &__content {
      width: 50%;
    }

    @media only screen and (max-width: $breakpoint-tablet) {
      &::after {
        display: none;
      }

      &__content {
        width: 100%;
      }
    }
  }

</style>
