var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-in-progress-content fs-16 tw-font-medium",class:{
    'is-pickup': _vm.isPickup,
    'is-delivery': !_vm.isPickup && !_vm.isDelivered,
    'is-delivered': _vm.isDelivered
  }},[_c('div',{staticClass:"shipment-in-progress-content__wrapper"},[_c('p',{domProps:{"textContent":_vm._s(_vm.$t(_vm.isPickup
        ? 'shipment.paragraphs.in_progress_shipment_pickup'
        : _vm.isDelivered
          ? 'shipment.paragraphs.in_progress_shipment_delivered'
          : 'shipment.paragraphs.in_progress_shipment_delivery'))}}),_c('i18n',{staticClass:"mb-0",attrs:{"path":_vm.isPickup
        ? (_vm.isFlexible
          ? 'shipment.paragraphs.in_progress_pickup_driver_handling_soon.flexible'
          : 'shipment.paragraphs.in_progress_pickup_driver_handling_soon.fixe')
        : _vm.isDelivered
          ? 'shipment.paragraphs.in_progress_delivered_carrier'
          : (_vm.isFlexible
            ? 'shipment.paragraphs.in_progress_delivery_driver_handling_soon.flexible'
            : 'shipment.paragraphs.in_progress_delivery_driver_handling_soon.fixe'),"tag":"p"},scopedSlots:_vm._u([{key:"date",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.date.date,'L')))])]},proxy:true},{key:"startTime",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.date.startTime,'LT')))])]},proxy:true},{key:"endTime",fn:function(){return [_c('span',[_vm._v(_vm._s(_vm._f("moment")(_vm.date.endTime,'LT')))])]},proxy:true}])})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }