<template>
  <div
    class="shipment-item-proposals-item-expiration"
    :class="{
      expiring: isExpiring
    }"
  >
    <div class="tw-flex">
      <ui-ctk-icon
        name="expiration"
        data-test="icon"
      />
      <span class="fs-13">
        {{ $t('app.labels.proposals.expires_in', {
          time: $moment(proposal.expires_at).fromNow(true)
        }) }}
      </span>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from '@vue/composition-api'

  /**
   * @module component - shipmentItemProposalsItemExpiration
   * @param {object} proposal
   */
  export default defineComponent({
    name: 'ShipmentItemProposalsItemExpiration',
    props: {
      proposal: {
        type: Object,
        required: true
      }
    },
    computed: {
      /**
       * Returns true if the proposal is expiring
       * @function isExpiring
       * @returns {boolean}
       */
      isExpiring () {
        const diffInHours = this.$moment(this.proposal.expires_at).diff(this.$moment(), 'minutes')
        return diffInHours < 60
      }
    }
  })
</script>

<style lang="scss" scoped>

  .shipment-item-proposals-item-expiration {
    color: $info;
    flex: 0.5;

    &.expiring {
      color: $danger;
    }

    .ctk-font,
    span {
      margin: auto 0;
    }
  }

</style>
