var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"shipment-detail-contact"},[(_vm.hasContactInformations)?[_c('address',{staticClass:"shipment-detail-contact__contact tw-bg-gray-200 tw-rounded tw-px-4 tw-py-3 tw-pb-5",class:[_vm.direction]},[_c('div',{staticClass:"tw-flex tw-justify-between tw-items-center"},[_c('div',{staticClass:"tw-font-medium tw-text-sm tw-text-secondary",domProps:{"textContent":_vm._s(_vm.$t('shipment.labels.contact_informations'))}}),_c('ui-ctk-icon',{staticClass:"tw-text-5xl",class:{
            'tw-text-blue-500': _vm.direction === 'pickup',
            'tw-text-green-500': _vm.direction === 'delivery'
          },attrs:{"name":"contact"}})],1),_c('div',{staticClass:"tw-flex tw-justify-between tw-items-end tw-truncate"},[_c('div',{staticClass:"tw-flex tw-flex-col tw-text-sm tw-truncate tw-pr-4 tw-leading-tight"},[(_vm.contact.name)?_c('div',{staticClass:"tw-font-medium tw-uppercase tw-text-secondary tw-truncate",domProps:{"textContent":_vm._s(_vm.contact.name)}}):_vm._e(),(_vm.contact.phone)?_c('div',{staticClass:"shipment-detail-contact__contact__phone tw-text-secondary-text",domProps:{"textContent":_vm._s(_vm.$options.filters.telephone(_vm.contact.phone))}}):_vm._e()]),_c('div',[(_vm.hasEdit)?_c('a',{staticClass:"tw-italic tw-text-sm",class:{
              'tw-text-blue-500 hover:tw-text-blue-600': _vm.direction === 'pickup',
              'tw-text-green-500 hover:tw-text-green-600': _vm.direction === 'delivery'
            },attrs:{"href":"#"},domProps:{"textContent":_vm._s(_vm.$options.filters.capitalize(_vm.$t('edit')))},on:{"click":function($event){$event.preventDefault();return _vm.edit.apply(null, arguments)}}}):_vm._e()])])])]:[(_vm.hasEdit)?_c('ui-button',{staticClass:"shipment-detail-contact__add",class:{
        '!tw-text-blue-500 !hover:tw-text-blue-600': _vm.direction === 'pickup',
        '!tw-text-green-500 !hover:tw-text-green-600': _vm.direction === 'delivery'
      },attrs:{"title":_vm.$t('shipment.buttons.add_contact'),"type":"button","variant":"link"},on:{"click":_vm.edit},scopedSlots:_vm._u([{key:"left-icon",fn:function(){return [_c('ui-material-icon',{attrs:{"name":"add_circle"}})]},proxy:true}],null,false,3344046670)},[_c('div',{domProps:{"textContent":_vm._s(_vm.$t('shipment.buttons.add_contact'))}})]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }