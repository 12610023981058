<template>
  <section
    id="carrier-section"
    class="shipment-carrier-detail tw-p-4 md:tw-p-8 lg:tw-p-12 tw-bg-white border-gray tw-rounded"
  >
    <h4
      class="tw-mb-6"
      v-text="$t('shipment.titles.carrier_detail')"
    />
    <div class="shipment-carrier-detail__content tw-flex tw-items-start tw-flex-col md:tw-flex-row">
      <div class="shipment-carrier-detail__carrier tw-w-full md:tw-w-1/2 tw-mr-6">
        <div
          class="carrier-name tw-font-medium tw-mb-2"
          v-text="carrier.name"
        />
        <ctk-address
          :address="carrier.address"
          class="tw-text-gray-700 tw-mb-2"
        />
        <p
          v-if="carrier.vat_number"
          data-test="vat_number"
          class="tw-text-gray-700"
          v-text="`${$t('shipment.labels.vat_number')} ${carrier.vat_number}`"
        />
      </div>
      <div
        :class="{
          'shipment-carrier-detail__driver--pallet-network': getCurrentShipment.price_type === 'pallet_network'
        }"
        class="shipment-carrier-detail__driver tw-relative tw-mt-4 md:tw-mt-0 tw-w-full md:tw-w-1/2 md:tw-ml-6 tw-px-5 tw-py-6 xl:tw-px-6 tw-rounded"
      >
        <template
          v-if="getCurrentShipment.price_type === 'pallet_network'"
        >
          <img
            src="@/assets/img/logo_bg_white.svg"
            height="27"
            width="140"
            alt=""
            class="tw-mb-3"
            data-test="logo"
          >
          <div
            class="tw-font-medium"
            v-text="$t('shipment.labels.driver_pallet_network_question')"
            data-test="label"
          />
          <div
            v-text="palletNetworkPhone"
            data-test="pallet-network-phone"
          />
        </template>
        <template
          v-else
        >
          <div
            class="shipment-carrier-detail__driver__label tw-font-medium"
            v-text="$t('shipment.labels.your_driver')"
            data-test="driver-label"
          />
          <div
            class="driver-name"
            v-text="driver.fullName"
            data-test="name"
          />
          <div
            v-if="phone"
            class="driver-phone"
            v-text="$options.filters.telephone(phone)"
            data-test="driver-phone"
          />
        </template>
      </div>
    </div>

    <div class="shipment-carrier-detail__bookings tw-mt-6">
      <div
        v-if="$wait.is('loading shipment bookings')"
        data-test="skeleton"
      >
        <ctk-skeleton-block
          width="320px"
          height="25px"
          level="1"
          loading
          class="tw-mb-4"
        />
        <ctk-skeleton-view
          depth="1"
          class="tw-flex tw-justify-between tw-mb-2"
        >
          <ctk-skeleton-block
            width="200px"
            height="25px"
            level="2"
            loading
          />
          <ctk-skeleton-block
            width="80px"
            height="25px"
            level="2"
            loading
          />
        </ctk-skeleton-view>
        <ctk-skeleton-view
          depth="2"
          class="tw-flex tw-justify-between tw-mb-2"
        >
          <ctk-skeleton-block
            width="180px"
            height="25px"
            level="2"
            loading
          />
          <ctk-skeleton-block
            width="100px"
            height="25px"
            level="2"
            loading
          />
        </ctk-skeleton-view>
        <ctk-skeleton-view
          depth="3"
          class="tw-flex tw-justify-between tw-mb-2"
        >
          <ctk-skeleton-block
            width="140px"
            height="25px"
            level="2"
            loading
          />
          <ctk-skeleton-block
            width="70px"
            height="25px"
            level="2"
            loading
          />
        </ctk-skeleton-view>
      </div>
      <shipment-bookings
        v-else-if="bookings && bookings.length > 0"
        :bookings="bookings"
      />
    </div>
  </section>
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'

  import chronoinfos from '@/../config/chronoinfos'

  import CtkAddress from '@/components/CtkAddress'
  import ShipmentBookings from './_subs/ShipmentBookings'
  import CtkSkeletonView from '@/components/CtkSkeletonView'
  import CtkSkeletonBlock from '@/components/CtkSkeletonView/_subs/CtkSkeletonBlock'
  import Driver from '@/models/Driver'

  /**
   * @module component - shipmentCarrierDetail
   */
  export default {
    name: 'ShipmentCarrierDetail',
    components: {
      CtkAddress,
      ShipmentBookings,
      CtkSkeletonView,
      CtkSkeletonBlock
    },
    data () {
      return {
        palletNetworkPhone: chronoinfos.phones['pallet-network'].text
      }
    },
    computed: {
      ...mapGetters('auth', [
        'getCid'
      ]),
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * @function carrier
       * @returns {object}
       */
      carrier () {
        return this.getCurrentShipment.mission.driver.carrier
      },
      /**
       * @function driver
       * @returns {Driver}
       */
      driver () {
        return new Driver(this.getCurrentShipment.mission.driver)
      },
      /**
       * Returns the driver phone driver, fallback to the carrier phone number
       * if not available.
       * @function phone
       * @returns {string}
       */
      phone () {
        return this.driver.phone || this.carrier.phone
      },
      /**
       * Returns the list of bookings without the current mission
       * @function bookings
       * @returns {Array<object>}
       */
      bookings () {
        const { mission, bookings } = this.getCurrentShipment
        return bookings
          ? bookings.filter(({ uuid }) => uuid !== mission.uuid)
          : []
      }
    },
    methods: {
      ...mapActions('shipments', [
        'setShipmentData'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-carrier-detail {
    border: 1px solid $divider;

    &__driver {
      background-color: $light-gray;

      &:not(.shipment-carrier-detail__driver--pallet-network)::after {
        position: absolute;
        content: '';
        width: 160px;
        height: 65px;
        top: -49px;
        left: 0;
        right: 0;
        margin: auto;
        background-image: url('~@/assets/img/illustrations/carrier.svg');
        background-position: center;
        background-repeat: no-repeat;
        opacity: 0.8;
      }
    }

    &__bookings {
      .ui-loader {
        margin-top: 32px;
      }
    }

    @media only screen and (max-width: $breakpoint-laptop-s) {
      &__driver::after {
        display: none;
      }
    }
  }

</style>
