<template>
  <shipment-payment-error-view
    class="shipment-proposals-accept-dialog-payment-error-view"
    @retry="$emit('retry')"
    @close="$emit('close')"
  />
</template>

<script>
  import ShipmentPaymentErrorView from '@/views/Shippers/components/ShipmentPaymentErrorView/index.vue'
  /**
   * @module component - ShipmentProposalsAcceptDialogPaymentErrorView
   * @emits close
   * @emits retry
   */
  export default {
    name: 'ShipmentProposalsAcceptDialogPaymentErrorView',
    components: {
      ShipmentPaymentErrorView
    }
  }
</script>
