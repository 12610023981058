<template>
  <div class="shipment-summary-tracking">
    <shipment-summary-tracking-item
      :title="$t(plannedTitle)"
      :value="getCurrentShipment.created_at | moment('LLL')"
      :muted="isCancelled"
      :dash-checked="hasCarrier"
      :dash-active="!hasCarrier"
      :dash-muted="isCancelled"
      checked
      data-test="planned"
    />

    <!-- Carrier selection -->
    <shipment-summary-tracking-item
      v-if="(!isCancelled && !hasCarrier) || hasCarrier"
      :title="$t(hasCarrier
        ? isPalletNetwork
          ? 'shipment.titles.tracking.carrier_selected_pallet-network'
          : 'shipment.titles.tracking.carrier_selected'
        : 'shipment.titles.tracking.carrier_selection')"
      :value="hasCarrier
        ? $moment(getCurrentShipment.mission.created_at).format('LLL')
        : null"
      :checked="hasCarrier"
      :dash-checked="isPickupDone"
      :active="!hasCarrier"
      :dash-active="hasCarrier && !isPickupDone"
      :muted="isCancelled"
      :dash-muted="isCancelled"
      data-test="carrier-selection"
    />

    <!-- Pickup -->
    <shipment-summary-tracking-item
      v-if="!isCancelled"
      :title="$t(isPickupDone
        ? 'shipment.titles.tracking.pickup_done'
        : 'shipment.titles.tracking.pickup')"
      :value="null"
      :checked="isPickupDone"
      :dash-checked="isDeliveryDone"
      :active="hasCarrier && !isPickupDone"
      :dash-active="isPickupDone && !isDeliveryDone"
      :has-tooltip="true"
      :tooltip="$t('shipment.paragraphs.pickup-delivery-dates')"
      data-test="pickup"
    />

    <!-- Delivery -->
    <shipment-summary-tracking-item
      v-if="!isCancelled"
      :title="$t(isDeliveryDone
        ? 'shipment.titles.tracking.delivery_done'
        : 'shipment.titles.tracking.delivery')"
      :value="null"
      :checked="isDeliveryDone"
      :dash-checked="isPODDone"
      :active="isPickupDone && !isDeliveryDone"
      :dash-active="(isPickupDone && isDeliveryDone) && !isPODDone"
      :has-tooltip="true"
      :tooltip="$t('shipment.paragraphs.pickup-delivery-dates')"
      data-test="delivery"
    />

    <!-- Proof of delivery -->
    <shipment-summary-tracking-item
      v-if="!isCancelled"
      :title="$t(isPODDone
        ? 'shipment.titles.tracking.cmr_available'
        : 'shipment.titles.tracking.waiting_cmr')"
      :value="hasPOD
        ? $moment(podDate).format('LLL')
        : null"
      :checked="isPODDone"
      :active="(isPickupDone && isDeliveryDone) && !isPODDone"
      data-test="pod"
    />

    <!-- Cancelled -->
    <shipment-summary-tracking-item
      v-if="isCancelled"
      :title="$t(cancelledTitle)"
      :value="(isExpired
        ? $moment(getCurrentShipment.expired_at)
        : $moment(getCurrentShipment.cancellation.date)).format('LLL')"
      :dash-active="!isRefunded"
      :dash-checked="isRefunded"
      :muted="isRefunded"
      cancelled
      checked
      data-test="cancelled"
    />

    <!-- Refund -->
    <shipment-summary-tracking-item
      v-if="hasRefund"
      :title="$t(refundTitle)"
      :value="isRefunded
        ? $moment(getCurrentShipment.billing.refunded_at).format('LLL')
        : null"
      :checked="isRefunded"
      :active="!isRefunded"
      :has-tooltip="!isRefunded"
      :tooltip="!isRefunded ? $t('shipment.paragraphs.refund_delay') : null"
      data-test="refunded"
    />
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import ShipmentSummaryTrackingItem from './_subs/ShipmentSummaryTrackingItem'

  /**
   * @module component - ShipmentSummaryTracking
   */
  export default {
    name: 'ShipmentSummaryTracking',
    components: {
      ShipmentSummaryTrackingItem
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ]),
      /**
       * @function hasCarrier
       * @returns {boolean}
       */
      hasCarrier () {
        const { mission } = this.getCurrentShipment
        return !!mission
      },
      /**
       * @function isPalletNetwork
       * @returns {boolean}
       */
      isPalletNetwork () {
        return this.getCurrentShipment.price_type === 'pallet_network'
      },
      /**
       * @function isPickupDone
       * @returns {boolean}
       */
      isPickupDone () {
        const { pickup } = this.getCurrentShipment
        return pickup.state === 'completed'
      },
      /**
       * @function isDeliveryDone
       * @returns {boolean}
       */
      isDeliveryDone () {
        const { delivery } = this.getCurrentShipment
        return delivery.state === 'completed'
      },
      /**
       * Returns true if the shipment has a proof of delivery available
       * @function isPODDone
       * @returns {boolean}
       */
      isPODDone () {
        const { state } = this.getCurrentShipment
        const finishedStates = ['finished', 'proof_of_delivery_available']
        return finishedStates.includes(state)
      },
      /**
       * Returns true if the shipment is expired
       * @function isExpired
       * @returns {boolean}
       */
      isExpired () {
        return this.getCurrentShipment.state === 'expired'
      },
      /**
       * Returns true if the shipment is either cancelled or expired
       * @function isCancelled
       * @returns {boolean}
       */
      isCancelled () {
        const cancellationStates = ['cancelled', 'expired']
        return cancellationStates.includes(this.getCurrentShipment.state)
      },
      /**
       * Returns true if the shipment is fully or partially refunded
       * @function isRefunded
       * @returns {boolean}
       */
      isRefunded () {
        const { billing } = this.getCurrentShipment
        return billing && billing.refunded
      },
      /**
       * Returns true if there is a POD file
       * @function hasPOD
       * @returns {boolean}
       */
      hasPOD () {
        const { proof_of_delivery: POD } = this.getCurrentShipment
        return POD && POD.state === 'available' && POD.files.length > 0
      },
      isImmediateCC () {
        const { billing } = this.getCurrentShipment
        return billing && billing.payment_plan === 'immediate_cc'
      },
      isImmediateCCAuth () {
        const { billing, bookings } = this.getCurrentShipment
        return billing && billing.payment_plan === 'immediate_cc_authorization' && bookings && bookings.length > 0
      },
      /**
       * Returns true if the refund section should be shown
       * @function hasRefund
       * @returns {boolean}
       */
      hasRefund () {
        const { state } = this.getCurrentShipment

        return state === 'cancelled' && (this.isImmediateCC || this.isImmediateCCAuth || this.isRefunded)
      },
      /**
       * @function podDate
       * @returns {string}
       */
      podDate () {
        const { proof_of_delivery: POD } = this.getCurrentShipment
        return POD.files[0].created_at
      },
      /**
       * Returns the planned title according to the shipment type and company payment method
       * @function plannedTitle
       * @returns {string} title
       */
      plannedTitle () {
        if (this.isPalletNetwork) {
          return this.isImmediateCC
            ? 'shipment.titles.tracking.planned_pallet-network_paid'
            : 'shipment.titles.tracking.planned_pallet-network'
        }

        return 'shipment.titles.tracking.planned'
      },
      /**
       * Returns the cancelled title according to the cancellation & state
       * @function cancelledTitle
       * @returns {string|null} title
       */
      cancelledTitle () {
        if (this.isExpired) return 'shipments.labels.expired'

        switch (this.getCurrentShipment.cancellation.type) {
        case 'cancelled_by_shipper':
          return 'shipments.labels.cancelled_by_shipper'
        case 'cancelled_by_chronotruck':
          return 'shipments.labels.cancelled_by_chronotruck'
        case 'payment_expired':
          return 'shipments.labels.payment_expired'
        default:
          return null
        }
      },
      /**
       * Returns the refund title according to the refund state
       * @function refundTitle
       * @returns {string|null} title
       */
      refundTitle () {
        if (this.isRefunded) return 'shipments.labels.refunded'
        else if (this.isImmediateCC || this.isImmediateCCAuth) return 'shipments.labels.pending_refund'
        return null
      }
    }
  }
</script>
