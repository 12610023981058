<template>
  <div class="shipment-rate-success">
    <h3
      v-text="$t('shipment.titles.rate_carrier')"
      class="shipment-rate-success__title fs-18 tw-font-medium"
    />
    <div class="shipment-rate-success__content tw-flex">
      <p
        v-text="$t('shipment.paragraphs.rate_success')"
        data-test="rate-success"
        class="tw-flex-1 mr-4"
      />
      <div class="tw-flex-1 tw-flex">
        <div class="shipment-rate-success__container tw-flex p-2 px-3 tw-bg-white tw-rounded tw-items-center">
          <label
            v-text="$t('shipment.labels.your_rate')"
            for="rate"
            class="tw-font-medium m-0 mr-3"
          />
          <ctk-input-rate
            id="rate"
            :value="getCurrentShipment.mission.rating.score"
            read-only
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  import CtkInputRate from '@/components/CtkInputs/CtkInputRate'

  /**
   * @module component - shipmentRateSuccess
   */
  export default {
    name: 'ShipmentRateSuccess',
    components: {
      CtkInputRate
    },
    computed: {
      ...mapGetters('shipments', [
        'getCurrentShipment'
      ])
    }
  }
</script>

<style lang="scss" scoped>

  .shipment-rate-success {
    &__container {
      flex: 0.6;

      label {
        white-space: nowrap;
      }
    }

    @media
      only screen and (max-width: $breakpoint-laptop-s) and (min-width: $breakpoint-tablet),
      only screen and (max-width: $breakpoint-mobile-l) {
      &__container {
        flex: 1;
      }

      &__content {
        flex-direction: column;
      }
    }
  }

</style>
